import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./Review.css"
import LatexRender from '../LatexRender'; // Import the LatexRender component

function ViewModel({ question, index }) {
    // console.log(question);
    const {
        preQuestionText,
        question_image_url,
        postQuestionText,
        option1Text,
        option2Text,
        option3Text,
        option4Text,
        option1_image_url,
        option2_image_url,
        option3_image_url,
        option4_image_url,
        preExplanationText,
        postExplanationText,
        explanation_image_url,
        correctOption
    } = question;
    const correctOptionText = question.correctOption;

    useEffect(() => {
        // Initialize MathJax rendering when component mounts
        if (typeof window !== 'undefined' && typeof window.MathJax !== 'undefined') {
            window.MathJax.typesetPromise();
        }
    }, [question]);

    const getImageUrl = (url) => {
        const imageUrl = process.env.REACT_APP_IMAGE_URL;
        if (!imageUrl) {
            console.error('REACT_APP_IMAGE_URL is not defined');
            return '';
        }
        const imgUrl = `${imageUrl}${url}`;
        return imgUrl;
    };

    return (
        <>

                
                        {/* <div className="disableCopy" onContextMenu={blockContextMenu}> */}
                        <div>
                            <div >
                                <div>
                                    <div>
                                        Q{index + 1}. {preQuestionText}
                                        {question_image_url && <img src={getImageUrl(question_image_url)} alt="Explanation" className="img-fluid mb-2" style={{ height: '20%', width: '20%' }} />}
                                        {postQuestionText}
                                    </div>
                                </div>
                                <div>
                                    <span><b>Options :</b></span>
                                    <div>
                                        <div>
                                            {option1Text &&
                                                <div className={correctOptionText === 'A' ? "rightAnswer":"wrongAnswer"} id='option1' style={{ display: 'flex' }}>
                                                    <div >(A)</div>
                                                    {option1Text}
                                                </div>
                                            }
                                            {option2Text &&
                                                <div className={correctOptionText === 'B' ? "rightAnswer":"wrongAnswer"} id='option2' style={{ display: 'flex' }}>
                                                    <div >(B)</div>
                                                    {option2Text}
                                                </div>
                                            }
                                            {option3Text &&
                                                <div className={correctOptionText === 'C' ? "rightAnswer":"wrongAnswer"} id='option3' style={{ display: 'flex' }}>
                                                    <div >(C)</div>
                                                    {option3Text}
                                                </div>
                                            }
                                            {option4Text &&
                                                <div className={correctOptionText === 'D' ? "rightAnswer":"wrongAnswer"} id='option4' style={{ display: 'flex' }}>
                                                    <div >(D)</div>
                                                    {option4Text}
                                                </div>
                                            }
                                            {option1_image_url &&
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ backgroundColor: correctOptionText === 'A' ? 'green' : 'transparent', marginRight: '5px' }}>(A)</div>
                                                    <img src={getImageUrl(option1_image_url)} alt="Option A" className="img-fluid" style={{ height: '20%', width: '20%' }} />
                                                </div>
                                            }
                                            {option2_image_url &&
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ backgroundColor: correctOptionText === 'C' ? 'green' : 'transparent', marginRight: '5px' }}>(C)</div>
                                                    <img src={getImageUrl(option2_image_url)} alt="Option B" className="img-fluid" style={{ height: '20%', width: '20%' }} />
                                                </div>
                                            }
                                            {option3_image_url &&
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ backgroundColor: correctOptionText === 'B' ? 'green' : 'transparent', marginRight: '5px' }}>(B)</div>
                                                    <img src={getImageUrl(option3_image_url)} alt="Option C" className="img-fluid" style={{ height: '20%', width: '20%' }} />
                                                </div>
                                            }
                                            {option4_image_url &&
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ backgroundColor: correctOptionText === 'D' ? 'green' : 'transparent', marginRight: '5px' }}>(D)</div>
                                                    <img src={getImageUrl(option4_image_url)} alt="Option D" className="img-fluid" style={{ height: '20%', width: '20%' }} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <p className="mt-3"><b>Answer: {correctOption}</b></p>
                                {/* {showAnswer && <p className="mt-3"><b>Answer: {correctOption}</b></p>} */}
                            </div>
                        </div>
                   
                        <div className='explaination-text'>
                            <div>
                                {preExplanationText && preExplanationText.includes("\\begin") ? (
                                    <LatexRender content={preExplanationText} />
                                ) : (
                                    preExplanationText && preExplanationText.split('!').map((line, i) => (
                                        <p key={i}>{line}</p>
                                    ))
                                )}
                                {postExplanationText && postExplanationText.includes("\\begin") ? (
                                    <LatexRender content={postExplanationText} />
                                ) : (
                                    postExplanationText && postExplanationText.split('!').map((line, i) => (
                                        <p key={i}>{line}</p>
                                    ))
                                )}
                            </div>
                            <div >
                                {explanation_image_url && <img src={getImageUrl(explanation_image_url.trim())} alt="Explanation" />}
                            </div>
                        </div>




        </>
    );
}

export default ViewModel;
