import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';


const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;

const TestModal = ({ index, test, onClick }) => {

  const [testSubjectValues, setTestSubjectValues] = useState([]);
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!test) return; // Ensure that id is provided before making the request
      try {
        const response = await axios.get(`${baseUrl}/test_templete_questions/${test.id}`);
        setTestSubjectValues(response.data.results);
        // console.log(response.data.results); // Access the response data
      } catch (error) {
        console.error('Error fetching Templete:', error);
      }
    };
    if (test)
      fetchData();
  }, [test]);;

  const totalQuestions = useMemo(() => [
    { subject_id: 0, total_questions: testSubjectValues.reduce((acc, q) => acc + q.total_questions, 0) },
    ...testSubjectValues.map(q => ({ subject_id: q.subject_id, total_questions: q.total_questions }))
  ], [testSubjectValues]);

  useEffect(() => {
    const subject = [{ subject_id: 0, subject_name: 'All' }, ...new Set(testSubjectValues.map(q => ({ subject_id: q.subject_id, subject_name: q.subject_name })))];
    setSubjects(subject)

  }, [testSubjectValues]);

  const getPerfectDateTime = (datetime) => {
    const testDateTime = new Date(datetime); // Convert to UTC format
    return testDateTime.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
  };
  const handleClick = () => {
    onClick(test.id);
  };

  return (
    <div>
      {test.status === 'unpublished' &&
        <div className="test-modal" onClick={handleClick}>
          <Paper elevation={3} className="my-5 p-5" style={{ maxWidth: '640px', boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)' }}>
            <div>
              <Typography variant="body1">
                {index + 1}.{test.report_name}
              </Typography>
            </div>
            <div>
              <Typography variant="body2">
                {getPerfectDateTime(test.test_date)} {test.session_name}
              </Typography>
            </div>
            <div>
              <Typography variant="body2">
                {subjects
                  .filter(subject => subject.subject_id !== 0)
                  .map((subject, index, filteredSubjects) => {
                    const totalQuestion = totalQuestions.find(q => q.subject_id === subject.subject_id);
                    return (
                      <span key={index}>
                        {subject.subject_name} : {totalQuestion ? totalQuestion.total_questions : 0}
                        {index < filteredSubjects.length - 1 && ' | '}
                      </span>
                    );
                  })}
              </Typography>
            </div>
            <div>
              <Typography variant="body2">
                Total Questions: {totalQuestions[0].total_questions} | Duration: {test.time_in_min} min. | Marks: {totalQuestions[0].total_questions * test.correct_answer_marks}
              </Typography>
            </div>
            <div>
              <Typography variant="body2">
                Staff Created: "Lecturer Name" at {getPerfectDateTime(test.created_date)}
              </Typography>
            </div>
          </Paper>
        </div>
      }
      {test.status === 'published' &&
        <div className="test-modal" onClick={handleClick}>
          <Paper elevation={3} className="my-5 p-5" style={{ maxWidth: '640px', boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)' }}>
            <div>
              <Typography variant="body1">
                {index + 1}.{test.report_name}
              </Typography>
            </div>
            <div>
              <Typography variant="body2">
                {getPerfectDateTime(test.test_date)} {test.session_name}
              </Typography>
            </div>
            <div>
              <Typography variant="body2">
                Total Questions: {test.total_questions} | Duration: {test.time_in_min} min. | Marks: {test.total_questions * test.correct_answer_marks}
              </Typography>
            </div>
            <div>
              <Typography variant="body2">
                Staff Created: "Lecturer Name" at {getPerfectDateTime(test.created_date)}
              </Typography>
            </div>
          </Paper>
        </div>
      }


    </div >
  )
}

export default TestModal

