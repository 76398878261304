import React, { useState } from 'react';
import axios from 'axios';
import Home from '../Home/home';

const AddYearForm = () => {
  const [yearName, setYearName] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Fetch environment variables
      const protocol = process.env.REACT_APP_API_PROTOCOL;
      const host = process.env.REACT_APP_API_HOST;
      const port = process.env.REACT_APP_API_PORT;

      // Construct the base URL
      const baseUrl = `${protocol}://${host}:${port}`;
      console.log(baseUrl)
      // Send a POST request to the API endpoint with the base URL
      await axios.post(`${baseUrl}/add-year`, { yearName });
      
      setYearName('');
      alert('Year added successfully!');
    } catch (error) {
      console.error('Error adding year:', error);
      alert('Error adding year. Please try again.');
    }
  };

  return (
    <>
    <Home/>
    <div className="container mt-5">
      <h2>Add Year</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="yearName" className="form-label">Year Name:</label>
          <input
            type="text"
            className="form-control"
            id="yearName"
            value={yearName}
            onChange={(e) => setYearName(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Add Year</button>
      </form>
    </div>
    </>
  );
};

export default AddYearForm;
