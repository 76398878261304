// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LatexRender from '../../LatexRender'; // Import the LatexRender component
// import './Component.css';
// import { Form } from 'react-bootstrap';

function ViewModel({ question, index }) {
    // console.log(question);
    const {
        preQuestionText,
        question_image_url,
        postQuestionText,
        option1Text,
        option2Text,
        option3Text,
        option4Text,
        option1_image_url,
        option2_image_url,
        option3_image_url,
        option4_image_url,
        preExplanationText,
        postExplanationText,
        explanation_image_url,
        correctOption
    } = question;

    const getImageUrl = (url) => {
        const imageUrl = process.env.REACT_APP_IMAGE_URL;
        // console.log('REACT_APP_IMAGE_URL:', imageUrl); // Verify the environment variable
        if (!imageUrl) {
            console.error('REACT_APP_IMAGE_URL is not defined');
            return '';
        }
        const imgUrl = `${imageUrl}${url}`;
        // console.log(imgUrl);
        return imgUrl;
    };

    const correctOptionText = question.correctOption;

    // const [solutionVisibility, setSolutionVisibility] = useState({});
    // const toggleSolution = (id) => {
    //     setSolutionVisibility(prevState => ({
    //         ...prevState,
    //         [id]: !prevState[id]
    //     }));
    // };

    useEffect(() => {
        // Initialize MathJax rendering when component mounts
        if (typeof window !== 'undefined' && typeof window.MathJax !== 'undefined') {
            window.MathJax.typesetPromise();
        }
    }, []);

    return (
        <>
            <Container fluid="md">
                <Row>
                    <Col>

                        {/* <div className="disableCopy" onContextMenu={blockContextMenu}> */}
                        <div>
                            <div className="container">
                                {question_image_url ? (
                                    <div className="row">
                                        <div className="col-md-6">
                                            {/* Q{index+1}. <LatexRender content={preQuestionText} /> */}
                                            Q{index + 1}. {preQuestionText}
                                        </div>
                                        <div className="col-md-6">
                                            {question_image_url.split(',').map((url, i) => (
                                                <React.Fragment key={i}>
                                                    <img src={getImageUrl(url.trim())} alt="Question" className="img-fluid mb-2" style={{ height: '20%', width: '20%' }}/>
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <>

                                        {/* Q{index+1}. <LatexRender content={preQuestionText} /> */}
                                        Q{index + 1}. {preQuestionText}
                                    </>
                                )}
                                {postQuestionText.map((line, i) => (
                                    <p key={i} className="math-equation">{line}</p>
                                ))}
                                <div className="mt-3">
                                    <span><b>Options :</b></span>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {option1Text &&
                                                <div className="math-equation" id='option1' style={{ display: 'flex' }}>
                                                    <div style={{ backgroundColor: correctOptionText === 'A' ? 'green' : 'transparent', marginRight: '5px' }}>(A)</div>
                                                    {option1Text}
                                                </div>
                                            }
                                            {option2Text &&
                                                <div className="math-equation" id='option2' style={{ display: 'flex' }}>
                                                    <div style={{ backgroundColor: correctOptionText === 'B' ? 'green' : 'transparent', marginRight: '5px' }}>(B)</div>
                                                    {option2Text}
                                                </div>
                                            }
                                            {option3Text &&
                                                <div className="math-equation" id='option3' style={{ display: 'flex' }}>
                                                    <div style={{ backgroundColor: correctOptionText === 'C' ? 'green' : 'transparent', marginRight: '5px' }}>(C)</div>
                                                    {option3Text}
                                                </div>
                                            }
                                            {option4Text &&
                                                <div className="math-equation" id='option4' style={{ display: 'flex' }}>
                                                    <div style={{ backgroundColor: correctOptionText === 'D' ? 'green' : 'transparent', marginRight: '5px' }}>(D)</div>
                                                    {option4Text}
                                                </div>
                                            }
                                            {option1_image_url &&
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ backgroundColor: correctOptionText === 'A' ? 'green' : 'transparent', marginRight: '5px' }}>(A)</div>
                                                    <img src={getImageUrl(option1_image_url)} alt="Option A" className="img-fluid" style={{ height: '20%', width: '20%' }}/>
                                                </div>
                                            }
                                            {option2_image_url &&
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ backgroundColor: correctOptionText === 'C' ? 'green' : 'transparent', marginRight: '5px' }}>(C)</div>
                                                    <img src={getImageUrl(option2_image_url)} alt="Option B" className="img-fluid" style={{ height: '20%', width: '20%' }}/>
                                                </div>
                                            }
                                            {option3_image_url &&
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ backgroundColor: correctOptionText === 'B' ? 'green' : 'transparent', marginRight: '5px' }}>(B)</div>
                                                    <img src={getImageUrl(option3_image_url)} alt="Option C" className="img-fluid" style={{ height: '20%', width: '20%' }}/>
                                                </div>
                                            }
                                            {option4_image_url &&
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ backgroundColor: correctOptionText === 'D' ? 'green' : 'transparent', marginRight: '5px' }}>(D)</div>
                                                    <img src={getImageUrl(option4_image_url)} alt="Option D" className="img-fluid" style={{ height: '20%', width: '20%' }}/>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                                <p className="mt-3"><b>Answer: {correctOption}</b></p>
                                {/* {showAnswer && <p className="mt-3"><b>Answer: {correctOption}</b></p>} */}
                            </div>
                        </div>
                        {/* <div className="mt-3">
                            <Form.Check
                                type="switch"
                                id={`show-solution-switch-${question.questionId}`}
                                label={solutionVisibility[question.questionId] ? 'Hide Solution' : 'Show Solution'}
                                checked={!!solutionVisibility[question.questionId]}
                                onChange={() => toggleSolution(question.questionId)}
                                className="link-style"
                            />
                        </div> */}
                    </Col>
                </Row>
                <Row style={{ backgroundColor: 'lightblue' }}>
                    <Col>
                        {/* {solutionVisibility[question.questionId] && ( */}
                            <>
                                
                                {explanation_image_url ? (
                                    // <div className="two-column-container disableCopy" onContextMenu={blockContextMenu}>
                                    <div>
                                        <div className="col-md-6">
                                            {preExplanationText && preExplanationText.includes("\\begin") ? (
                                                <LatexRender content={preExplanationText} />
                                            ) : (
                                                preExplanationText && preExplanationText.split('!').map((line, i) => (
                                                    <p key={i}>{line}</p>
                                                ))
                                            )}
                                            {postExplanationText && postExplanationText.includes("\\begin") ? (
                                                <LatexRender content={postExplanationText} />
                                            ) : (
                                                postExplanationText && postExplanationText.split('!').map((line, i) => (
                                                    <p key={i}>{line}</p>
                                                ))
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            {explanation_image_url.split(',').map((url, i) => (
                                                <React.Fragment key={i}>
                                                    <img src={getImageUrl(url.trim())} alt="Explanation" className="img-fluid mb-2" style={{ height: '20%', width: '20%' }}/>
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {preExplanationText && preExplanationText.includes("\\begin") ? (
                                            // <div style={{display:'none'}}>
                                            <LatexRender content={preExplanationText} />
                                            // </div>
                                        ) : (
                                            preExplanationText && preExplanationText.split('!').map((line, i) => (
                                                <p key={i}>{line}</p>
                                            ))
                                        )}
                                        {postExplanationText && postExplanationText.includes("\\begin") ? (
                                            <LatexRender content={postExplanationText} />
                                        ) : (
                                            postExplanationText && postExplanationText.split('!').map((line, i) => (
                                                <p key={i}>{line}</p>
                                            ))
                                        )}
                                    </>

                                )}
                            </>
                        {/* )} */}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ViewModel;
