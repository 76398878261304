import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import UploadQuestions from "../UploadQuestions";
import ViewQuestions from "./ViewQuestions";
import { useNavigate } from "react-router-dom";
import SideNavBar from "../SideNavBar";
import HomeIcon from "@mui/icons-material/Home";
import Popover from '@mui/material/Popover';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import SecurityIcon from '@mui/icons-material/Security';
import "./App.css";
import Home from "./Home";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

// const Settings = () => <div>Adjust your Settings here</div>;

const Content = ({ currentPage }) => {
  let content;

  switch (currentPage) {
    case "home":
      content = <Home />;
      break;
    case "upload":
      content = <UploadQuestions />;
      break;
    case "view":
      content = <ViewQuestions />;
      break;
    default:
      content = <Home />;
  }

  return <div className="content">{content}</div>;
};

const App = () => {
const navigate = useNavigate();
const [anchorEl, setAnchorEl] = React.useState(null);

const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};

const handleClose1 = () => {
  setAnchorEl(null);
};

const open = Boolean(anchorEl);
const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    // Check if access token is present in local storage
    const accessToken = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");
    const userType = localStorage.getItem("userType");
    console.log("me", userType);
    console.log("User ID in user dashboard", userId);

    if (!userId || !accessToken) {
      // Redirect to login page if userId or accessToken is missing
      // navigate("/");
      console.log("not login");
      // window.location.href = "/"

    } else {
      if (userType === "user") {
        // Redirect to UserDashboard.js if userType is 'user'
        navigate("/UserDashboard");
      } else {
        // Redirect to the home page for other user types
        navigate("/home");
      }
    }
  }, [navigate]);

  const [currentPage, setCurrentPage] = useState("home");
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const handleMenuClick = (page) => {
    setCurrentPage(page);
    setIsSidebarVisible(false); // Hide sidebar after menu selection on mobile
  };

  const handleToggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  return <div>
    <Content currentPage={currentPage} />
  </div>
    // <div className="">
    //   <Header onToggleSidebar={handleToggleSidebar} />
    //   {/* <div className="leftApp">
    //   <SideNavBar/>
    //   </div> */}

    //   {/* <div className="rightApp"> */}
    //     <Sidebar onMenuClick={handleMenuClick} isVisible={isSidebarVisible} />
    //     <Content currentPage={currentPage} />
    //   {/* </div> */}
    // </div>


    // <div className="AppContainer">
      {/* <div className="leftApp">
      <SideNavBar/>
      </div> */}

      {/* <div className="rightApp">
        <div className="appHeader">
        <div className='appHeaderTopSection'>
              <div className="homeNavBar">
                <div className="homeNavItems">
                  <span className="homeIconn">
                    {" "}
                    <HomeIcon />{" "}
                  </span>
                  / Dashboard
                </div>
                <div className="homeNavHeaderr">Dashboard</div>
              </div>
              <div className='homeNavbarTopSectionRight'>
                <div onClick={handleClick} className="profile"><AccountCircleIcon fontSize='large' /> Profile</div>

                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose1}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <div className="profilePopOver">
                    <div className="profileOptions"> <SettingsIcon/> Settings</div>
                    <div className="profileOptions"> <SecurityIcon/> Privacy</div>
                  </div>

                </Popover>
              </div>
            </div>
          <p className="headerText">
            Hello Exam Tech Users !
          </p>
          <p className="headerHelpText">
          We are on a mission to help students, users and employees.
          </p>
        </div>

      </div> */}
      <Content currentPage={currentPage} />

    // </div>
    

};

export default App;



