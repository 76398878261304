import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import AdminHeader from '../AdminHeader';
import ViewModel from './ViewModel';
import { ToastContainer, toast, Slide } from 'react-toastify';
const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;

const ViewReportedQuestions = () => {
    const { reportId } = useParams();

    const [report, setReport] = useState({});
    const [question, setQuestions] = useState({});
    const [status, setStatus] = useState('');

    useEffect(() => {
        axios.get(`${baseUrl}/getreportdetails/${reportId}`)
            .then(response => {
                setReport(response.data);
            })
            .catch(error => console.error(error));
    }, [reportId]);

    useEffect(() => {
        const question_id = report.question_id;

        if (!question_id) {
            // console.error('question_id is undefined or null');
            return; // or you can set an error state or display an error message
        }
        const fetchQuestions = async () => {
            try {
                const response = await axios.get(`${baseUrl}/getreportquestion`, { params: { question_id } });
                setQuestions(response.data);
            } catch (err) {
                console.error('ERROR', err);
            }
        };
        fetchQuestions();
    }, [report]);

    useEffect(() => {
        if (report.length> 0) {
            return;
        }
        const updateReportStatus = async () => {
            try {
                const response = await axios.post(`${baseUrl}/updatereportstatus/${report.id}`, { status });
                if (response.status === 201) {
                    toast.success('Report status updated successfully');
                }
            } catch (err) {
                console.error('ERROR', err);
            }
        };
        updateReportStatus();
    }, [status, report]);

    useEffect(() => {
        setStatus(report.status);
    }, [report]);

    const handleChange = (event) => {
        setStatus(event.target.value);
    };

    return (
        <div className="d-flex flex-column align-items-center">
            <AdminHeader />
            <Paper elevation={3} className="p-2" style={{ width: '1080px', boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)' }}>
                <div>
                    <Typography variant="body1">
                        Status: {status && status.charAt(0).toUpperCase() + status.slice(1)}
                    </Typography>
                    <select value={status} onChange={handleChange}>
                        <option value="pending">Pending</option>
                        <option value="processing">Processing</option>
                        <option value="solved">Solved</option>
                    </select>
                </div>
            </Paper>
            <Paper elevation={3} className="p-2" style={{ width: '1080px', boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)' }}>
                <div>
                    <Typography variant="body1">
                        <ViewModel report={report} question={question} />
                    </Typography>
                </div>
            </Paper>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </div>
    )
}

export default ViewReportedQuestions
