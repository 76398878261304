import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LatexRender from '../../LatexRender'; // Import the LatexRender component

function ViewModel({ question }) {
    const {
        id,
        pre_question_text,
        question_image_url,
        post_question_text,
        option1_text,
        option2_text,
        option3_text,
        option4_text,
        option1_image_url,
        option2_image_url,
        option3_image_url,
        option4_image_url,
        pre_explanation_text,
        post_explanation_text,
        explanation_image_url,
        correctOption
    } = question;

    const getImageUrl = (url) => {
        const imageUrl = process.env.REACT_APP_IMAGE_URL;
        // console.log('REACT_APP_IMAGE_URL:', imageUrl); // Verify the environment variable
        if (!imageUrl) {
            console.error('REACT_APP_IMAGE_URL is not defined');
            return '';
        }
        const imgUrl = `${imageUrl}${url}`;
        // console.log(imgUrl);
        return imgUrl;
    };

    const correctOptionText = question.correct_option;

    useEffect(() => {
        // Initialize MathJax rendering when component mounts
        if (typeof window !== 'undefined' && typeof window.MathJax !== 'undefined') {
            window.MathJax.typesetPromise();
        }
    }, [question]);

    return (
        <>
            <Container fluid="md">
                <Row>
                    <Col>
                        <div className="container">
                            <Row>
                                <Col md={12}>
                                    Q {id}. {pre_question_text}
                                    {question_image_url && question_image_url.split(',').map((url, i) => (
                                        <React.Fragment key={i}>
                                            <img
                                                src={getImageUrl(url.trim())}
                                                alt="Question"
                                                className="img-fluid mb-2"
                                                style={{ height: '20%', width: '20%' }}
                                            />
                                            <br />
                                        </React.Fragment>
                                    ))}
                                    {post_question_text && post_question_text.map((line, i) => (
                                        <p key={i} className="math-equation">{line}</p>
                                    ))}
                                    <div>
                                        <button>edit</button>
                                    </div>
                                </Col>
                            </Row>
                            <div className="mt-3">
                                <span><b>Options :</b></span>
                                <Row>
                                    <Col md={12}>
                                        {option1_text && (
                                            <div className="math-equation" id='option1' style={{ display: 'flex' }}>
                                                <div style={{ backgroundColor: correctOptionText === 'A' ? 'green' : 'transparent', marginRight: '5px' }}>(A)</div>
                                                <div>{option1_text}</div>
                                            </div>
                                        )}
                                        {option2_text && (
                                            <div className="math-equation" id='option2' style={{ display: 'flex' }}>
                                                <div style={{ backgroundColor: correctOptionText === 'B' ? 'green' : 'transparent', marginRight: '5px' }}>(B)</div>
                                                <div>{option2_text}</div>
                                            </div>
                                        )}
                                        {option3_text && (
                                            <div className="math-equation" id='option3' style={{ display: 'flex' }}>
                                                <div style={{ backgroundColor: correctOptionText === 'C' ? 'green' : 'transparent', marginRight: '5px' }}>(C)</div>
                                                <div>{option3_text}</div>
                                            </div>
                                        )}
                                        {option4_text && (
                                            <div className="math-equation" id='option4' style={{ display: 'flex' }}>
                                                <div style={{ backgroundColor: correctOptionText === 'D' ? 'green' : 'transparent', marginRight: '5px' }}>(D)</div>
                                                <div>{option4_text}</div>
                                            </div>
                                        )}
                                        {option1_image_url && (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ backgroundColor: correctOptionText === 'A' ? 'green' : 'transparent', marginRight: '5px' }}>(A)</div>
                                                <img
                                                    src={getImageUrl(option1_image_url)}
                                                    alt="Option A"
                                                    className="img-fluid"
                                                    style={{ height: '20%', width: '20%' }}
                                                />
                                            </div>
                                        )}
                                        {option2_image_url && (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ backgroundColor: correctOptionText === 'B' ? 'green' : 'transparent', marginRight: '5px' }}>(B)</div>
                                                <img
                                                    src={getImageUrl(option2_image_url)}
                                                    alt="Option B"
                                                    className="img-fluid"
                                                    style={{ height: '20%', width: '20%' }}
                                                />
                                            </div>
                                        )}
                                        {option3_image_url && (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ backgroundColor: correctOptionText === 'C' ? 'green' : 'transparent', marginRight: '5px' }}>(C)</div>
                                                <img
                                                    src={getImageUrl(option3_image_url)}
                                                    alt="Option C"
                                                    className="img-fluid"
                                                    style={{ height: '20%', width: '20%' }}
                                                />
                                            </div>
                                        )}
                                        {option4_image_url && (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ backgroundColor: correctOptionText === 'D' ? 'green' : 'transparent', marginRight: '5px' }}>(D)</div>
                                                <img
                                                    src={getImageUrl(option4_image_url)}
                                                    alt="Option D"
                                                    className="img-fluid"
                                                    style={{ height: '20%', width: '20%' }}
                                                />
                                            </div>
                                        )}
                                        <p className="mt-3"><b>Answer: {correctOption}</b></p>
                                    </Col>
                                    <div>
                                        <button>edit</button>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row style={{ backgroundColor: 'lightblue' }}>
                    <Col>
                        {pre_explanation_text && pre_explanation_text.includes("\\begin") ? (
                            <LatexRender content={pre_explanation_text} />
                        ) : (
                            pre_explanation_text && pre_explanation_text.split('!').map((line, i) => (
                                <p key={i}>{line}</p>
                            ))
                        )}
                        {post_explanation_text && post_explanation_text.includes("\\begin") ? (
                            <LatexRender content={post_explanation_text} />
                        ) : (
                            post_explanation_text && post_explanation_text.split('!').map((line, i) => (
                                <p key={i}>{line}</p>
                            ))
                        )}
                        {explanation_image_url && (
                            <div className="col-md-6">
                                <img
                                    src={getImageUrl(explanation_image_url)}
                                    alt="Explanation"
                                    className="img-fluid mb-2"
                                    style={{ height: '20%', width: '20%' }}
                                />
                            </div>
                        )}
                        <div>
                            <button>edit</button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ViewModel;
