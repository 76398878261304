import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Home from '../Home/home';

const AddSubjectForm = () => {
  const [subjectName, setSubjectName] = useState('');
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');

  useEffect(() => {
    // Fetch years from backend API
    const fetchYears = async () => {
      try {
        const protocol = process.env.REACT_APP_API_PROTOCOL;
        const host = process.env.REACT_APP_API_HOST;
        const port = process.env.REACT_APP_API_PORT;

        // Construct the base URL
        const baseUrl = `${protocol}://${host}:${port}`;

        const response = await axios.get(`${baseUrl}/years`);
        setYears(response.data);
      } catch (error) {
        console.error('Error fetching years:', error);
      }
    };

    fetchYears();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const protocol = process.env.REACT_APP_API_PROTOCOL;
      const host = process.env.REACT_APP_API_HOST;
      const port = process.env.REACT_APP_API_PORT;

      // Construct the base URL
      const baseUrl = `${protocol}://${host}:${port}`;

      // Send data to backend API
      await axios.post(`${baseUrl}/add-subject`, { subjectName, yearId: selectedYear });
      setSubjectName('');
      setSelectedYear('');
      alert('Subject added successfully!');
    } catch (error) {
      console.error('Error adding subject:', error);
      alert('Error adding subject. Please try again.');
    }
  };

  return (
    <>
    <Home/>
    <div className="container mt-5">
      <h2>Add Subject</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="yearSelect" className="form-label">Year:</label>
          <select
            id="yearSelect"
            className="form-select"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            required
          >
            <option value="">Select Year</option>
            {years.map((year) => (
              <option key={year.year_id} value={year.year_id}>
                {year.year_name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="subjectName" className="form-label">Subject Name:</label>
          <input
            type="text"
            id="subjectName"
            className="form-control"
            value={subjectName}
            onChange={(e) => setSubjectName(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Add Subject</button>
      </form>
    </div>
    </>
  );
};

export default AddSubjectForm;
