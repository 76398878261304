import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Home from '../Home/home';

const AddTopicForm = () => {
  const [topicName, setTopicName] = useState('');
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [chapters, setChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState('');

  useEffect(() => {
    // Fetch years from the backend API
    const fetchYears = async () => {
      try {
        const protocol = process.env.REACT_APP_API_PROTOCOL;
        const host = process.env.REACT_APP_API_HOST;
        const port = process.env.REACT_APP_API_PORT;
        const baseUrl = `${protocol}://${host}:${port}`;
        
        const response = await axios.get(`${baseUrl}/years`);
        setYears(response.data);
      } catch (error) {
        console.error('Error fetching years:', error);
      }
    };

    fetchYears();
  }, []);

  useEffect(() => {
    // Fetch subjects based on the selected year
    const fetchSubjects = async () => {
      if (selectedYear) {
        try {
          const protocol = process.env.REACT_APP_API_PROTOCOL;
          const host = process.env.REACT_APP_API_HOST;
          const port = process.env.REACT_APP_API_PORT;
          const baseUrl = `${protocol}://${host}:${port}`;

          const response = await axios.get(`${baseUrl}/subjects?yearId=${selectedYear}`);
          setSubjects(response.data);
        } catch (error) {
          console.error('Error fetching subjects:', error);
        }
      }
    };

    fetchSubjects();
  }, [selectedYear]);

  useEffect(() => {
    // Fetch chapters based on the selected subject
    const fetchChapters = async () => {
      if (selectedSubject) {
        try {
          const protocol = process.env.REACT_APP_API_PROTOCOL;
          const host = process.env.REACT_APP_API_HOST;
          const port = process.env.REACT_APP_API_PORT;
          const baseUrl = `${protocol}://${host}:${port}`;

          const response = await axios.get(`${baseUrl}/chapters?subjectId=${selectedSubject}`);
          setChapters(response.data);
        } catch (error) {
          console.error('Error fetching chapters:', error);
        }
      }
    };

    fetchChapters();
  }, [selectedSubject]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const protocol = process.env.REACT_APP_API_PROTOCOL;
      const host = process.env.REACT_APP_API_HOST;
      const port = process.env.REACT_APP_API_PORT;
      const baseUrl = `${protocol}://${host}:${port}`;

      await axios.post(`${baseUrl}/add-topic`, { topicName, chapterId: selectedChapter });
      setTopicName('');
      alert('Topic added successfully!');
    } catch (error) {
      console.error('Error adding topic:', error);
      alert('Error adding topic. Please try again.');
    }
  };

  return (
    <>
    <Home/>
    <div className="container mt-5">
      <h2>Add Topic</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="yearSelect" className="form-label">Year:</label>
          <select
            id="yearSelect"
            className="form-select"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            required
          >
            <option value="">Select Year</option>
            {years.map((year) => (
              <option key={year.year_id} value={year.year_id}>
                {year.year_name}
              </option>
            ))}
          </select>
        </div>
        {selectedYear && (
          <div className="mb-3">
            <label htmlFor="subjectSelect" className="form-label">Subject:</label>
            <select
              id="subjectSelect"
              className="form-select"
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
              required
            >
              <option value="">Select Subject</option>
              {subjects.map((subject) => (
                <option key={subject.subject_id} value={subject.subject_id}>
                  {subject.subject_name}
                </option>
              ))}
            </select>
          </div>
        )}
        {selectedSubject && (
          <div className="mb-3">
            <label htmlFor="chapterSelect" className="form-label">Chapter:</label>
            <select
              id="chapterSelect"
              className="form-select"
              value={selectedChapter}
              onChange={(e) => setSelectedChapter(e.target.value)}
              required
            >
              <option value="">Select Chapter</option>
              {chapters.map((chapter) => (
                <option key={chapter.chapter_id} value={chapter.chapter_id}>
                  {chapter.chapter_name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="mb-3">
          <label htmlFor="topicName" className="form-label">Topic Name:</label>
          <input
            type="text"
            id="topicName"
            className="form-control"
            value={topicName}
            onChange={(e) => setTopicName(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Add Topic</button>
      </form>
    </div>
    </>
  );
};

export default AddTopicForm;
