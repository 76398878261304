import React, { useState, useEffect } from "react";
import axios from "axios";

const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;


const ViewUserQuestionCount = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [questionCounts, setQuestionCounts] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Fetch user details
        const userResponse = await axios.get(`${baseUrl}/getuserdetails`);
        const usersData = userResponse.data.users;
        setUsers(usersData);

        // Fetch question counts for each user
        const promises = usersData.map((user) =>
          axios.post(`${baseUrl}/question-counts`, { user_id: user.id })
        );

        const responses = await Promise.all(promises);
        const counts = {};
        responses.forEach((response, index) => {
          counts[usersData[index].id] = response.data;
        });
        setQuestionCounts(counts);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h1>User Details and Question Counts</h1>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Last Login</th>
            <th>Total Questions</th>
            <th>Today's Questions</th>
            <th>Yesterday's Questions</th>
            <th>Last 7 Days</th>
            <th>This Month</th>
            <th>Last 30 Days</th>
            <th>Previous Month</th>
            <th>Pending</th>
            <th>Reviewed</th>
            <th>Incorrect</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.number}</td>
              <td>
                {user.last_login
                  ? new Date(user.last_login).toLocaleString()
                  : "N/A"}
              </td>
              <td>{questionCounts[user.id]?.total_question_count ?? "N/A"}</td>
              <td>{questionCounts[user.id]?.today_question_count ?? "N/A"}</td>
              <td>
                {questionCounts[user.id]?.yesterday_question_count ?? "N/A"}
              </td>
              <td>
                {questionCounts[user.id]?.last_7_days_question_count ?? "N/A"}
              </td>
              <td>
                {questionCounts[user.id]?.this_month_question_count ?? "N/A"}
              </td>
              <td>
                {questionCounts[user.id]?.last_30_days_question_count ?? "N/A"}
              </td>
              <td>
                {questionCounts[user.id]?.previous_month_question_count ??
                  "N/A"}
              </td>
              <td>
                {questionCounts[user.id]?.pending_question_count ?? "N/A"}
              </td>
              <td>
                {questionCounts[user.id]?.reviewed_question_count ?? "N/A"}
              </td>
              <td>
                {questionCounts[user.id]?.incorrect_question_count ?? "N/A"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewUserQuestionCount;
