import React, { useEffect, useState } from 'react'
import axios from 'axios';
import ReviewHearder from './ReviewHearder'
import { useParams } from 'react-router-dom';
import ReviewQuestionSelectionPage from './ReviewQuestionSelectionPage.';
import ViewModel from './ViewModel';
import "./Review.css"

import ReportBtn from '../Buttons/ReportBtn';

import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './Review.css';

import { ToastContainer, toast, Slide } from 'react-toastify';

const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;

const ReviewUserQuestions = () => {
    const { user_id } = useParams();

    const [questions, setQuestions] = useState([]);

    const [uploaderId, setUploaderId] = useState(0);
    const [users, setUser] = useState([]);

    const [selectedYearId, setSelectedYearId] = useState('');
    const [selectedSubjectId, setSelectedSubjectId] = useState('');
    const [selectedChapterId, setSelectedChapterId] = useState('');
    const [selectedTopicId, setSelectedTopicId] = useState('');

    const [delayedIndex, setDelayedIndex] = useState(0);

    const [hasError, setHasError] = useState(false);
    const [hasErrorBtn, setHasErrorBtn] = useState(false);

    const [triggerToReload, setTriggerToRelod] = useState(false);

    const toggleError = () => {
        setHasErrorBtn(prevState => !prevState);
    };

    const formatQuestions = (data) => {
        return data.map(item => ({
            questionId: item.id,

            preQuestionText: item.pre_question_text,
            postQuestionText: item.post_question_text.split('!'),

            option1Text: item.option1_text,
            option2Text: item.option2_text,
            option3Text: item.option3_text,
            option4Text: item.option4_text,

            correctOption: item.correct_option,

            preExplanationText: item.pre_explanation_text,
            postExplanationText: item.post_explanation_text,

            question_image_url: item.question_image_url,
            option1_image_url: item.option1_image_url,
            option2_image_url: item.option2_image_url,
            option3_image_url: item.option3_image_url,
            option4_image_url: item.option4_image_url,
            explanation_image_url: item.explanation_image_url,

            conceptualError: item.conceptual_error,
            alignmentError: item.alignment_error,
            deletedSyllabus: item.deleted_syllabus,
            difficultyLevel: item.difficulty_level,

        }));
    };

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                // Create params object conditionally
                const params = {};
                if (selectedYearId) params.year_id = selectedYearId;
                if (selectedSubjectId) params.subject_id = selectedSubjectId;
                if (selectedChapterId) params.chapter_id = selectedChapterId;
                if (selectedTopicId) params.topic_id = selectedTopicId;
                if (uploaderId) params.uploaderId = uploaderId;
                const response = await axios.get(`${baseUrl}/userquestionstoreview`, { params });

                const formattedQuestions = formatQuestions(response.data);
                setQuestions(formattedQuestions);
            } catch (err) {
                console.error('ERROR', err);
            }
        };
        fetchQuestions();
    }, [selectedYearId, selectedSubjectId, selectedChapterId, selectedTopicId, uploaderId, triggerToReload]);

    useEffect(() => {
        const fetchuploader = async () => {
            try {
                const response = await axios.get(`${baseUrl}/getuploaduser`);
                setUser(response.data);
            } catch (error) {
                console.error('Error fetching lecturer:', error);
            }
        };
        fetchuploader(); // Call fetchLecturer only if user_id is truthy
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (delayedIndex < questions.length) {
                setDelayedIndex(delayedIndex + 1);
            }
        }, 200); // Delay of 1 second (1000 milliseconds)
        return () => clearTimeout(timer); // Clear the timer when component unmounts or index changes
    }, [delayedIndex, questions]);

    const [conceptualErrors, setConceptualErrors] = useState([]);
    const [alignmentErrors, setAlignmentErrors] = useState([]);
    const [deletedSyllabuses, setDeletedSyllabuses] = useState([]);
    const [difficultyLevels, setDifficultyLevels] = useState([]);

    useEffect(() => {
        setConceptualErrors(questions.map(question => question.conceptualError === 1));
        setAlignmentErrors(questions.map(question => question.alignmentError === 1));
        setDeletedSyllabuses(questions.map(question => question.deletedSyllabus === 1));
        setDifficultyLevels(questions.map(question => question.difficultyLevel));
    }, [questions]);

    useEffect(() => {
        const hasAnyError = conceptualErrors.includes(true) || alignmentErrors.includes(true) || deletedSyllabuses.includes(true);
        setHasError(hasAnyError);
        setHasErrorBtn(hasAnyError);
    }, [conceptualErrors, alignmentErrors, deletedSyllabuses]);


    const handleCheckboxChange = (index, setFunction, stateArray) => {
        const updatedStateArray = [...stateArray];
        updatedStateArray[index] = !updatedStateArray[index];
        setFunction(updatedStateArray);
    };

    useEffect(() => {
        const updateQuestionsInDatabase = async () => {
            try {
                const updatedQuestions = questions.map((question, index) => ({
                    ...question,
                    conceptualError: conceptualErrors[index] ? 1 : 0,
                    alignmentError: alignmentErrors[index] ? 1 : 0,
                    deletedSyllabus: deletedSyllabuses[index] ? 1 : 0,
                    difficultyLevel: difficultyLevels[index],
                }));

                // Example of how you might send updatedQuestions to your backend
                // eslint-disable-next-line
                const response = await axios.post(`${baseUrl}/updatereviewquestions`, { updatedQuestions });
                // console.log('Questions updated successfully:', response.data);
            } catch (error) {
                console.error('Error updating questions:', error);
                // Handle error as needed, e.g., show error message to user
                toast.error('Failed to update questions. Please try again later.');
            }
        };
        // console.log(difficultyLevels);
        updateQuestionsInDatabase();
    }, [conceptualErrors, alignmentErrors, deletedSyllabuses, difficultyLevels, questions]); // Include all dependencies here

    const handleSkipQuestion = async (question_id) => {
        try {
            const response = await axios.post(`${baseUrl}/updatereviewskipquestion`, { question_id, user_id });
            if (response.status === 201) {
                toast.success('Question skipped successfully');
                setTriggerToRelod(prev => !prev);
            } else {
                toast.error('Error skipping question');
            }
        } catch (error) {
            toast.error('Error skipping question');
        }
    };

    const handleReviewedQuestion = async (question_id) => {
        try {
            const response = await axios.post(`${baseUrl}/updatequestionreviewed`, { question_id, user_id });
            if (response.status === 201) {
                toast.success('Question Reviewing successfully');
                setTriggerToRelod(prev => !prev);
            } else {
                toast.error('Error Reviewing question');
            }
        } catch (error) {
            toast.error('Error Reviewing question');
        }
    };

    const handleErrorButton = async (question_id) => {
        if (hasError) {
            try {
                const response = await axios.post(`${baseUrl}/updatequestionerror`, { question_id, user_id });
                if (response.status === 201) {
                    toast.success('Question Reviewing successfully');
                    setTriggerToRelod(prev => !prev);
                } else {
                    toast.error('Error Reviewing question');
                }
            } catch (error) {
                toast.error('Error Reviewing question');
            }
        } else {
            toast.warn('un check errors found');
        }
    };

    const handleDifficultyChange = (index, value) => {
        const updatedDifficultyLevels = [...difficultyLevels];
        updatedDifficultyLevels[index] = value;
        setDifficultyLevels(value);
    };

    const [displayedQuestions, setDisplayedQuestions] = useState(15);
    const handleShowMore = () => {
        setDisplayedQuestions(displayedQuestions + 15);
    };

    return (
        <div>
            <div>
                <ReviewHearder />
                <div>
                    <div className='subHeading'>Select Uploader</div>
                    <select className='questionselect' id="userSelect" value={uploaderId || ''} onChange={(e) => setUploaderId(e.target.value)}>
                        <option value="">Select a user</option>
                        {users.map(user => (
                            <option key={user.id} value={user.id}>
                                {user.name}
                            </option>
                        ))}
                    </select>
                </div>
                    <ReviewQuestionSelectionPage
                        setSelectedYearId={setSelectedYearId}
                        setSelectedSubjectId={setSelectedSubjectId}
                        setSelectedChapterId={setSelectedChapterId}
                        setSelectedTopicId={setSelectedTopicId}
                    />
                {questions.slice(0, displayedQuestions).map((question, index) => (
                    delayedIndex >= index && (
                        <div key={question.questionId}>
                                <div>
                                                    <div>
                                                        <div>
                                                            <div >
                                                                <ReportBtn question={question} user_id={user_id} />
                                                                <div className="mt" >
                                                                    <div className="">
                                                                        <div>
                                                                            <span className='subHeading'>Difficulty Level</span>
                                                                            <div className="deficultyLevel">
                                                                                <div className="deficultOptions">
                                                                                    <input
                                                                                        type="radio"
                                                                                        name={`difficulty-${index}`}
                                                                                        value="low"
                                                                                        checked={difficultyLevels[index] === 'l'}
                                                                                        onChange={() => handleDifficultyChange(index, 'low')}
                                                                                    />
                                                                                    Low
                                                                                </div>
                                                                                <div className="deficultOptions">
                                                                                    <input
                                                                                        type="radio"
                                                                                        name={`difficulty-${index}`}
                                                                                        value="medium"
                                                                                        checked={difficultyLevels[index] === 'm'}
                                                                                        onChange={() => handleDifficultyChange(index, 'medium')}
                                                                                    />
                                                                                    Medium
                                                                                </div>
                                                                                <div className="deficultOptions">
                                                                                    <input
                                                                                        type="radio"
                                                                                        name={`difficulty-${index}`}
                                                                                        value="hard"
                                                                                        checked={difficultyLevels[index] === 'h'}
                                                                                        onChange={() => handleDifficultyChange(index, 'hard')}
                                                                                    />
                                                                                    Hard
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="button-group mb">
                                                                        <button className={(hasError || hasErrorBtn) ? 'no-error' : 'has-error'} onClick={toggleError}>
                                                                            {hasError ? 'No Error' : 'Has Error'}
                                                                        </button>
                                                                        <button className="skip" onClick={() => handleSkipQuestion(question.questionId)}>SKIP</button>
                                                                        <button className="reviewed" onClick={() => handleReviewedQuestion(question.questionId)}>Reviewed</button>
                                                                    </div>
                                                                </div>
                                                                <div className='questionErrorTypes mbt' style={{ display: !hasErrorBtn ? 'none' : 'flex' }}>

                                                                    <div className="errorType">
                                                                    <span style={{ fontSize: '9px' }}>Conceptual Error:</span>
                                                                    <input type="checkbox"
                                                                    className='errorCheckbox'
                                                                        checked={conceptualErrors[index]}
                                                                        onChange={() => handleCheckboxChange(index, setConceptualErrors, conceptualErrors)}
                                                                    />
                                                                    </div>

                                                                  <div className="errorType">
                                                                  <span style={{ fontSize: '9px' }}>Alignment Error:</span>
                                                                    <input type="checkbox"
                                                                    className='errorCheckbox'
                                                                        checked={alignmentErrors[index]}
                                                                        onChange={() => handleCheckboxChange(index, setAlignmentErrors, alignmentErrors)}

                                                                    />
                                                                  </div>

                                                                   <div className="errorType">
                                                                   <span style={{ fontSize: '9px' }}>Deleted Syllabus:</span>
                                                                    <input type="checkbox"
                                                                    className='errorCheckbox'
                                                                        checked={deletedSyllabuses[index]}
                                                                        onChange={() => handleCheckboxChange(index, setDeletedSyllabuses, deletedSyllabuses)}
                                                                    />
                                                                   </div>
                                                                    <button className='saveChangesButton' onClick={() => handleErrorButton(question.questionId)}>Save Changes</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            <ViewModel question={question} index={index} />
                                </div>
                        </div>
                    )
                ))}
                {questions.length > 15 && (
                    <div className="d-flex justify-content-center">
                        <button onClick={handleShowMore} className="btn btn-primary mt-3">Show More</button>
                    </div>
                )}
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </div>
    )
}

export default ReviewUserQuestions
