import React, { useState, useEffect } from "react";
import axios from "axios";

const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;

const SuperUserDashboard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    fetchData();
    fetchNotifications();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/getuserquestioncount`);
      const enrichedData = response.data.map((item) => ({
        ...item,
        amount: formatAmount(item.amount), // Format amount if present
      }));
      setData(enrichedData);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${baseUrl}/fetchNotifications`);
      setNotifications(response.data);
    } catch (error) {
      setError("Error fetching notifications");
      console.error("Error fetching notifications:", error);
    }
  };

  const formatAmount = (amount) => {
    if (amount || amount === 0) {
      return Number(amount).toFixed(2);
    }
    return ""; // Return empty string if amount is undefined or null
  };

  const formatDate = (datetime) => {
    const date = new Date(datetime);
    return date.toLocaleDateString(); // Formats the date according to the local format (e.g., MM/DD/YYYY in the US)
  };

  const handlePaidClick = async (items) => {
    console.log(items);

    try {
      const response = await axios.post(`${baseUrl}/addPayment`, {
        items: [items],
      });
      alert(response.data.message);
      // Refetch data after payment is added
      fetchData();
    } catch (error) {
      console.error("Error adding payment:", error);
      alert("Error adding payment");
    }
  };

  const handleBulkPaidClick = async (userId, items) => {
    console.log(items);
    try {
      const response = await axios.post(`${baseUrl}/addPayment`, {
        items,
      });
      alert(response.data.message);
      // Refetch data after payment is added
      fetchData();
    } catch (error) {
      console.error("Error adding payment:", error);
      alert("Error adding payment");
    }
  };

  const handleNotificationCheck = async (id) => {
    try {
      const response = await axios.post(`${baseUrl}/updateNotificationStatus`, {
        id,
        status: "checked",
      });
      if (response.status === 200) {
        alert("Notification status updated successfully.");
        fetchNotifications();
      } else {
        alert("Failed to update notification status.");
      }
    } catch (error) {
      console.error("Error updating notification status:", error);
      alert("Error updating notification status.");
    }
  };

const userSummaries = data.reduce((acc, item) => {
  if (!acc[item.user_id]) {
    acc[item.user_id] = {
      name: item.name,
      paid: 0,
      unpaid: 0,
      unpaidItems: [],
      totalQuestions: 0, // Add totalQuestions property
    };
  }
  if (item.has_payment) {
    acc[item.user_id].paid += parseFloat(item.amount);
  } else {
    acc[item.user_id].unpaid += parseFloat(item.amount);
    acc[item.user_id].unpaidItems.push(item);
  }
  // Accumulate total questions for each user
  acc[item.user_id].totalQuestions += item.question_count;

  return acc;
}, {});


  if (loading) {
    return <div className="container">Loading...</div>;
  }

  if (error) {
    return <div className="container">Error: {error}</div>;
  }

  return (
    <div className="container">
      <h2 className="mt-4 mb-4">User Summary</h2>
      <table className="table table-bordered">
        <thead className="thead-dark">
          <tr>
            <th>User ID</th>
            <th>User Name</th>
            <th>Total Questions</th>
            <th>Total Paid Amount (Rs)</th>
            <th>Total Unpaid Amount (Rs)</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(userSummaries).map(([userId, summary]) => (
            <tr key={userId}>
              <td>{userId}</td>
              <td>{summary.name}</td>
              <td>{summary.totalQuestions}</td>
              <td>{summary.paid.toFixed(2)}</td>
              <td>{summary.unpaid.toFixed(2)}</td>
              <td>
                {summary.unpaid > 0 && (
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      handleBulkPaidClick(userId, summary.unpaidItems)
                    }
                  >
                    Pay Now
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h1 className="mt-4 mb-4">Super User Dashboard</h1>
      <table className="table table-bordered">
        <thead className="thead-dark">
          <tr>
            <th>User ID</th>
            <th>User Name</th>
            <th>Date</th>
            <th>Question Upload Count</th>
            <th>Amount (Rs)</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.user_id}</td>
              <td>{item.name}</td>
              <td>{formatDate(item.question_date)}</td>
              <td>{item.question_count}</td>
              <td>{item.amount}</td>
              <td>
                {item.has_payment ? (
                  <span className="text-success">Paid</span>
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={() => handlePaidClick(item)}
                  >
                    Pay Now
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2 className="mt-4 mb-4">Notifications</h2>
      <table className="table table-bordered">
        <thead className="thead-dark">
          <tr>
            <th>ID</th>
            <th>User ID</th>
            <th>User Name</th>
            <th>Message</th>
            <th>Status</th>
            <th>Created At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {notifications.map((notification, index) => (
            <tr key={index}>
              <td>{notification.id}</td>
              <td>{notification.userid}</td>
              <td>{notification.name}</td>
              <td>{notification.message}</td>
              <td>{notification.status}</td>
              <td>{formatDate(notification.created_at)}</td>
              <td>
                {notification.status !== "checked" && (
                  <button
                    className="btn btn-primary"
                    onClick={() => handleNotificationCheck(notification.id)}
                  >
                    Check
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SuperUserDashboard;
