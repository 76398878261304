import React, { useState } from 'react'
import '../CreateTest.css'
import Home from '../Home/home'

import TestPrintPreview from './Published/TestPrintPreview'
import Settings from './TestEditor/Settings'

// import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const TestPublished = () => {
  const { test_id } = useParams();
  // const location = useLocation();
  // const id = location.state;

  const [showPrintPreview, setShowPrintPreview] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const handleShowPrint = () => {
    setShowPrintPreview(true)
    setShowSettings(false);
  }

  const handleShowSettings = () => {
    setShowPrintPreview(false);
    setShowSettings(true);
  }

  // console.log(test_id);

  return (
    <div className="app-container">

      <div className="hide-on-print"><Home /></div>
      <div className="center-content">
        <div className="navbar hide-on-print">
          <button className="link-style" onClick={handleShowPrint}>Print Paper</button>
          <button className="link-style" onClick={handleShowSettings}>Change Test Settings</button>
        </div>

        <div className="content">
          {showPrintPreview && <TestPrintPreview test_id={test_id} />}
          {showSettings && <Settings id={test_id} />}
        </div>
      </div>
    </div>
  )
}

export default TestPublished
