import React, { useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useNavigate } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import ViewUserQuestionCount from './ViewUserQuestionCount';

const ReviewHeader = () => {
    const [user_id, setUserId] = useState(0);
    const navigate = useNavigate();
    const [navigatePages, setNavigatePages] = useState([]);
    const [navigates, setNavigates] = useState('');
    const [routeTo, setRouteTo] = useState('');

    useEffect(() => {
        // Check if access token is present in local storage
        const accessToken = localStorage.getItem("accessToken");
        const userId = localStorage.getItem("userId");
        // console.log("REVIEWER USER_ID", userId);
        setUserId(userId);

        if (!userId || !accessToken) {
            // Redirect to login page if userId or accessToken is missing
            navigate("/login");
        }
    }, [navigate]);

    useEffect(() => {
        setNavigatePages(['users','Review User Questions','Review Questions','Review Topic Related Questions', 'Review Dificulty Questions','Review Exam Related Questions']);
    }, []);

    useEffect(() => {
        if (navigates === 'Review User Questions') {
            setRouteTo("Review User Questions")
        }
        if (navigates === 'Review Questions') {
            setRouteTo("Review Questions")
        }
        if (navigates === 'Review Topic Related Questions') {
            setRouteTo("Review Topic Related Questions")
        }
        if (navigates === 'Review Dificulty Questions') {
            setRouteTo("Review Dificulty Questions")
        }
        if (navigates === 'Review Exam Related Questions') {
            setRouteTo("Review Exam Related Questions")
        }
        if (navigates === "users") {
          setRouteTo("users");
        }
    }, [navigates, navigate, user_id]);

    const handleLogout = () => {
        localStorage.removeItem("userId");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("userType");

        navigate("/login");
    };

    return (
      <div className="d-flex flex-column align-items-center">
        {routeTo === "Review User Questions" && (
          <Navigate to={`/reviewuserquestions/${user_id}`} state={user_id} />
        )}
        {routeTo === "Review Questions" && (
          <Navigate to={`/reviewquestions/${user_id}`} state={user_id} />
        )}
        {routeTo === "Review Topic Related Questions" && (
          <Navigate
            to={`/reviewtopicrelatedquestions/${user_id}`}
            state={user_id}
          />
        )}
        {routeTo === "Review Dificulty Questions" && (
          <Navigate
            to={`/reviewdificultyquestions/${user_id}`}
            state={user_id}
          />
        )}
        {routeTo === "Review Exam Related Questions" && (
          <Navigate
            to={`/reviewexamrelatedquestions/${user_id}`}
            state={user_id}
          />
        )}
        {routeTo === "users" && (
          <ViewUserQuestionCount/>
        )}
        {/* <button onClick={handleLogout}>Logout</button>
        <Tabs
          defaultActiveKey=""
          id="fill-tab-example"
          className="mb-3"
          onSelect={(k) => setNavigates(k)}
          fill
        >
          {navigatePages.map((page, index) => (
            <Tab key={index} eventKey={page} title={page} />
          ))}
        </Tabs> */}
      </div>
    );
};

export default ReviewHeader;
