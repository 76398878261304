import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './header';

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if access token is present in local storage
    const accessToken = localStorage.getItem('accessToken');

    const userId = localStorage.getItem('userId');
    // console.log('userid in homeuser', userId);

    if (!userId) {
      // User ID not found, redirect to login page
      navigate('/login');
    }

    if (!accessToken) {
      // Access token not found, redirect to login page
      navigate('/login');
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userType");

    navigate("/login");
  };

  return (
    <div>
      <h2 align="center">ExamTech</h2>
      <button onClick={handleLogout}>Logout</button>
      <Header />

      {/* Render the content of the home page */}
    </div>
  );
};

export default Home;
