import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useParams} from 'react-router-dom';

import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import UnpublishedHome from './UnpublishedHome';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ToastContainer, toast, Slide } from 'react-toastify';

import ViewModel from '../ViewModel';
import ReportBtn from '../../../Buttons/ReportBtn'

const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;
const endpoint = '/api/test/';

const AddTestQuestions = () => {
  const { user_id, test_id } = useParams(); // Destructure the params
  const [testId, setTestId] = useState([]);
  const [printQueue, setPrintQueue] = useState([]);
  const [subjectPrintQueue, setSubjectPrintQueue] = useState([]);
  const [testSubjectValues, setTestSubjectValues] = useState([]);
  // Initialize state for textarea values

  const [questions, setQuestions] = useState([]);
  // const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [filter, setFilter] = useState(0);
  const [delayedIndex, setDelayedIndex] = useState(0);

  const [subjects, setSubjects] = useState([]);

  const [displayedQuestions, setDisplayedQuestions] = useState(15);
  // eslint-disable-next-line
  // const [showMore, setShowMore] = useState(true);

  const [chapters, setChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState(0);

  const [topics, setTopics] = useState([]);
  const [selectedTopicId, setSelectedTopicID] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      if (!test_id) return; // Ensure that id is provided before making the request
      try {
        const response = await axios.get(`${baseUrl}${endpoint}test_templete/${test_id}`);
        setTestId(response.data[0]);
        // console.log(response.data); // Access the response data
      } catch (error) {
        console.error('Error fetching Templete:', error);
      }
    };

    fetchData();
  }, [test_id]); // id is included in the dependency array

  useEffect(() => {
    const fetchData = async () => {
      if (!testId) return; // Ensure that id is provided before making the request
      try {
        const response = await axios.get(`${baseUrl}/test_templete_questions/${testId.id}`);
        setTestSubjectValues(response.data.results);
        // console.log(response.data.results); // Access the response data
      } catch (error) {
        console.error('Error fetching Templete:', error);
      }
    };
    if (testId)
      fetchData();
  }, [testId]);

  useEffect(() => {
    const fetchQueue = async () => {
      try {
        const response = await axios.get(`${baseUrl}/fetchqueue/${testId.id}/${filter}`);
        setSubjectPrintQueue(response.data)
        const queueIds = response.data.map(item => item.question_id);
        setPrintQueue(queueIds);
      } catch (error) {
        console.error('Error fetching queue:', error);
      }
    }
    if (testId)
      fetchQueue();
  }, [testId, filter]);

  useEffect(() => {
    const fetchChapters = async () => {
      if (filter) {
        try {
          const response = await axios.get(`${baseUrl}/chapters?subjectId=${filter}`);
          setChapters(response.data);
        } catch (error) {
          console.error('Error fetching chapters:', error);
        }
      }
    };
    // console.log("CHAPTERS ", chapters);
    if (filter)
      fetchChapters();
  }, [filter]);

  useEffect(() => {
    const fetchTopics = async () => {
      if (selectedChapter) {
        try {
          const response = await axios.get(`${baseUrl}/topics?chapterId=${selectedChapter}`);
          setTopics(response.data);
        } catch (error) {
          console.error('Error fetching topics:', error);
        }
      }
    };
    // console.log("TOPICS: ", topics);
    fetchTopics();
  }, [selectedChapter]);

  const formatQuestions = (data) => {
    return data.map(item => ({
      questionId: item.id,

      preQuestionText: item.pre_question_text,
      postQuestionText: item.post_question_text.split('!'),

      question_image_url: item.question_image_url,

      option1Text: item.option1_text,
      option2Text: item.option2_text,
      option3Text: item.option3_text,
      option4Text: item.option4_text,

      correctOption: item.correct_option,

      option1_image_url: item.option1_image_url,
      option2_image_url: item.option2_image_url,
      option3_image_url: item.option3_image_url,
      option4_image_url: item.option4_image_url,

      preExplanationText: item.pre_explanation_text,
      postExplanationText: item.post_explanation_text,

      explanation_image_url: item.explanation_image_url,

      selectedYearId: item.selectedYearId,
      selectedSubjectId: item.selectedSubjectId,
      selectedChapterId: item.selectedChapterId,
      selectedTopicId: item.selectedTopicId,
      conceptual_error: item.conceptual_error,
      alignment_error: item.alignment_error,
      deleted_syllabus: item.deleted_syllabus,
      difficulty_level: item.difficulty_level,
      selectedExamId: item.selectedExamId,
      updatedDate: item.updatedDate,
      userId: item.userId

    }));
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        // Create params object conditionally
        const params = {};
        if (testId.year_id) params.year_id = testId.year_id;
        if (filter) params.subject_id = filter;
        if (selectedChapter) params.chapter_id = selectedChapter;
        if (selectedTopicId) params.topic_id = selectedTopicId;

        const response = await axios.get(`${baseUrl}/testsubjectquestions`, { params });

        const formattedQuestions = formatQuestions(response.data);
        setQuestions(formattedQuestions);
      } catch (err) {
        console.error('ERROR', err);
      }
    };
    fetchQuestions();
  }, [testId.year_id, filter, selectedChapter, selectedTopicId]);

  useEffect(() => {
    const subject = [{ subject_id: 0, subject_name: 'All' }, ...new Set(testSubjectValues.map(q => ({ subject_id: q.subject_id, subject_name: q.subject_name })))];
    setSubjects(subject)
  }, [testSubjectValues]);

  const totalQuestions = useMemo(() => [
    { subject_id: 0, total_questions: testSubjectValues.reduce((acc, q) => acc + q.total_questions, 0) },
    ...testSubjectValues.map(q => ({ subject_id: q.subject_id, total_questions: q.total_questions }))
  ], [testSubjectValues]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (delayedIndex < questions.length) {
        setDelayedIndex(delayedIndex + 1);
      }
    }, 200); // Delay of 1 second (1000 milliseconds)
    return () => clearTimeout(timer); // Clear the timer when component unmounts or index changes
  }, [delayedIndex, questions]);

  const addToQueueDb = async (q, sId, id) => {
    try {
      const response = await axios.post(`${baseUrl}/addtoqueue`, {
        user_id: user_id,
        test_id: testId.id,
        subject_id: sId,
        question_id: id
      });
      if (response.status === 201) {
        setPrintQueue([...printQueue, id]);
        setSubjectPrintQueue([...subjectPrintQueue, q]);
        toast.success('Question added to queue');
      }
    } catch (error) {
      toast.error('Error Adding');
      console.error("ERROR", error);
    }
  }

  const removeFromQueue = async (q, sId, id) => {
    try {
      const response = await axios.post(`${baseUrl}/removefromqueue`, {
        user_id: user_id,
        test_id: testId.id,
        subject_id: sId,
        question_id: id
      });
      if (response.status === 201) {
        setPrintQueue(printQueue.filter(item => item !== id));
        setSubjectPrintQueue(subjectPrintQueue.filter(item => item.qId !== id));
        toast.warn('Question removed from queue');
        // console.log("RESOPONSE", response);
      }
    } catch (error) {
      toast.error('Error Adding');
      console.error("ERROR", error);
    }
  }

  const addToPrintQueue = (sId, id) => {
    const q = { subject_id: sId, qId: id };

    if (!printQueue.includes(id)) {
      // Add id to print queue array
      addToQueueDb(q, sId, id);
    } else {
      // Remove id from print queue array
      removeFromQueue(q, sId, id);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line
    if (printQueue.length > totalQuestions.find(t => t.subject_id == filter)?.total_questions) {
      // eslint-disable-next-line
      toast.error('Question limit exceeded by ' + (printQueue.length - totalQuestions.find(t => t.subject_id == filter)?.total_questions) + ' Question');
    }
    // eslint-disable-next-line
    const filteredTotalQuestions = totalQuestions.find(subject => subject.subject_id == filter);
    // const filteredSubject = testSubjectValues.find(subject => subject.subject_id == filter);
    // const subjectName = filteredSubject ? filteredSubject.subject_name : null;

    if (!filteredTotalQuestions) {
      // toast.error('No questions found for selected subject');
      return;
    }

    const subjectItemCount = subjectPrintQueue.filter(item => item.subject_id === filter).length;

    if (subjectItemCount > filteredTotalQuestions.total_questions) {
      toast.error('Question limit exceeded by ' + (subjectItemCount - filteredTotalQuestions.total_questions) + ' questions');
    }

  }, [printQueue, totalQuestions, subjectPrintQueue, filter, testSubjectValues]);


  // useEffect(() => {
  //   if (printQueue.length > totalQuestions[0].total_questions) {
  //     toast.error('Question limit exceeded by ' + (printQueue.length - totalQuestions) + ' Question');
  //   }
  // }, [printQueue, totalQuestions]);


  const handleShowMore = () => {
    setDisplayedQuestions(displayedQuestions + 15);
  };

  return (
    <div>
      <UnpublishedHome />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ flex: "0 0 auto", marginRight: "10px", maxWidth: "300px", flexWrap: "wrap" }}>
          <h2>
            ADD Questions to <b>{testId.session_name}</b>
          </h2>
          {/* {testId.total_questions} */}
          <>
            <Tabs
              // defaultActiveKey={subjects[1]?.subject_id}
              id="fill-tab-example"
              className="mb-3"
              onSelect={(k) => setFilter(k)}
              fill
            >
              {subjects.map((subject, index) => {
                if (subject.subject_id !== 0) {
                  const totalQuestionsForSubject = totalQuestions.find(
                    (totalQuestion) => totalQuestion.subject_id === subject.subject_id
                  );
                  return (
                    <Tab
                      key={index}
                      eventKey={subject.subject_id}
                      title={
                        <>
                          {subject.subject_name}
                          {
                          // eslint-disable-next-line
                          subject.subject_id == filter && (<> : {printQueue.length} / {totalQuestionsForSubject ? totalQuestionsForSubject.total_questions : 'N/A'}</>)}
                        </>
                      }
                    />
                  );
                }
                return null; // Return null when subject_id is 'All'
              })}

            </Tabs>
          </>
          {chapters.length > 0 && (
            <>
              CHAPTERS:
              <Tabs
                defaultActiveKey={chapters[0]?.chapter_id} // Set the default active key to the first topic_id
                id="fill-tab-example"
                className="mb-3"
                onSelect={(k) => setSelectedChapter(k)}
                fill
              >
                {chapters.map((chapter, index) => {
                  return (
                    <Tab
                      key={index}
                      eventKey={chapter.chapter_id}
                      title={chapter.chapter_name}
                    />
                  );
                })}
              </Tabs>
            </>
          )}

          {topics.length > 0 && (
            <>
              TOPICS:
              <Tabs
                defaultActiveKey={topics[0]?.topic_id} // Set the default active key to the first topic_id
                id="fill-tab-example"
                className="mb-3"
                onSelect={(k) => setSelectedTopicID(k)}
                fill
              >
                {topics.map((topic, index) => (
                  <Tab
                    key={index}
                    eventKey={topic.topic_id}
                    title={topic.topic_name}
                  />
                ))}
              </Tabs>
            </>
          )}

        </div>
        {/* <div style={{ flex: "1", marginRight: "10px", maxWidth: "900px", flexWrap: "wrap" }}> */}
        <>
          <div className="d-flex flex-column align-items-center">
            {questions.slice(0, displayedQuestions).map((question, index) => (
              delayedIndex >= index && (
                <div key={question.questionId} className="w-100 d-flex justify-content-center my-2">
                  <Paper elevation={3} className="p-2" style={{ width: '1080px', boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)' }}>
                    <div>
                      <Typography variant="body1">
                        <Container fluid="md">
                          <Row style={{ backgroundColor: 'lightyellow' }}>
                            <Col>
                              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <div>
                                <ReportBtn question={question} user_id={user_id} />

                                </div>

                                <div style={{ marginLeft: 'auto' }}>
                                  {filter !== 0 && printQueue.length}
                                  {/* / */}
                                  {/* {filter && (<>{totalQuestions.find(t => t.subject_id === filter)?.total_questions}</>)} */}

                                  <button
                                    onClick={() => addToPrintQueue(filter, question.questionId)}
                                    style={{
                                      padding: '5px 10px',
                                      fontSize: '14px',
                                      borderRadius: '5px',
                                      border: '1px solid black',
                                      backgroundColor: printQueue.includes(question.questionId) ? 'red' : 'green',
                                      color: 'white',
                                      marginLeft: '10px'
                                    }}
                                  >
                                    {printQueue.includes(question.questionId) ? 'Remove' : 'Add'}
                                  </button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <ViewModel question={question} index={index} />
                        </Container>
                      </Typography>
                    </div>
                  </Paper>
                </div>
              )
            ))}
            {questions.length > 15 && (
              <div className="d-flex justify-content-center">
                <button onClick={handleShowMore} className="btn btn-primary mt-3">Show More</button>
              </div>
            )}
          </div>
        </>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Slide}
      />
    </div>
  )
}

export default AddTestQuestions
