import { createSlice } from '@reduxjs/toolkit'

export const overLookSlice = createSlice({
  name: 'overLookSlice',
  initialState: {
    selectedNav : "dashboard",
    userData : ''
  },
  reducers: {
    selectNav: (state, action) => {
      state.selectedNav = action.payload
    },
    setUser:(state, action) =>{
      state.userData = action.payload
    }
    
  },
})

export const { selectNav, setUser } = overLookSlice.actions

export default overLookSlice.reducer