import React from 'react'
import ReviewHearder from './ReviewHearder'

const ReviewerHome = () => {
  return (
    <div>
      <ReviewHearder />
    </div>
  )
}

export default ReviewerHome
