import React, { useEffect, useState } from "react";
import "./App.css";
import HomeIcon from "@mui/icons-material/Home";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { useNavigate } from "react-router-dom";
import QuestionCounts from "./QuestionCount"; // Assuming this is where you define QuestionCounts component
import TopQuestionUploader from "./TopQuestionUploader";
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { Pie } from 'react-chartjs-2';
import axios from "axios";

const Home = () => {
  const [counts, setCounts] = useState({});
  const data = {
    labels: ['Today', 'Yesterday', 'This Week', 'This Month','Previous Month'],
    datasets: [
      {
        label: 'Data',
        backgroundColor: [
          '#1a73e8',
          '#cc00cc',
          '#ef7e49',
          '#FF4191',
          '#C65BCF',
         
        ],
        // borderColor: 'rgba(0,0,0,1)',
        data: [counts.today_question_count, counts.yesterday_question_count, counts.last_7_days_question_count,counts.this_month_question_count,counts.previous_month_question_count],
      },
    ],
  };

  const pieData = {
    labels: ['Reviewed', 'Rejected', 'Reveiw Pending'],
    datasets: [
      {
        label: 'Data',
        backgroundColor: [
          '#66bb6a',
          '#ff4d4d',
          '#e6e600',
        ],
        borderWidth: 0,
        data: [ 300, 20, 42],
      },
    ],
  };

  const pieOptions = {
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const total = dataset.data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
          const currentValue = dataset.data[tooltipItem.index];
          const percentage = ((currentValue / total) * 100).toFixed(2);
          return `${data.labels[tooltipItem.index]}: ${currentValue} (${percentage}%)`;
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
        },
      },
    },
    legend: {
      position: 'bottom',
      labels: {
        boxWidth: 5,
        font: {
          size: 5,
        },
      },
    }

  };
  
  
  const earningStatus = {
    labels: ['Today', 'Yesterday', 'This Week', 'This Month','Previous Month'],
    datasets: [
      {
        label: 'Data',
        backgroundColor: [
          '#1a73e8',
          '#cc00cc',
          '#ef7e49',
          '#FF4191',
          '#C65BCF',
         
        ],
        // borderColor: 'rgba(0,0,0,1)',
        data: [300, 140, 700,4000, 4500],
      },
    ],
  };

  const subjectWiseData = {
    labels: ['Physics', 'Chemistry', 'Biology','Maths'],
    datasets: [
      {
        label: 'Data',
        backgroundColor: [
          '#1a73e8',
          '#cc00cc',
          '#ef7e49',
          '#FF4191',
        ],
        // borderColor: 'rgba(0,0,0,1)',
        data: [400, 320, 560,100],
      },
    ],
  };

  const subjectWiseDataSecondPU = {
    labels: ['Physics', 'Chemistry', 'Biology','Maths'],
    datasets: [
      {
        label: 'Data',
        backgroundColor: [
          '#1a73e8',
          '#cc00cc',
          '#ef7e49',
          '#FF4191',
        ],
        // borderColor: 'rgba(0,0,0,1)',
        data: [500, 320, 60,400],
      },
    ],
  };


  const statusData = {
    labels: ['pending', 'rejected', 'reviewed'],
    datasets: [
      {
        label: 'Data',
        backgroundColor: [
         
          '#e6e600',
          '#66bb6a',
          '#ff471a'
        ],
        // borderColor: 'rgba(0,0,0,1)',
        data: [300, 520, 690],
      },
    ],
  };


  
  const options = {
    scales: {
      y: {
        type: 'linear',
        beginAtZero: true
      },
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true
      },
    },

    barThickness: 40,
    // borderRadious: 10


  };

  console.log("counts123",counts)
  const navigate = useNavigate();
  const [userId, setUserId] = useState(""); // State to hold userId
  // eslint-disable-next-line
  const [userType, setUserType] = useState(""); // State to hold userType

  useEffect(() => {
    // Check if access token is present in local storage
    const accessToken = localStorage.getItem("accessToken");
    const storedUserId = localStorage.getItem("userId");
    const storedUserType = localStorage.getItem("userType");
    console.log("User Type:", storedUserType);
    console.log("User ID in user home dashboard:", storedUserId);

    if (!storedUserId || !accessToken) {
      // Redirect to login page if userId or accessToken is missing
      navigate("/");
      console.log("User not logged in");
    } else {
      setUserId(storedUserId);
      setUserType(storedUserType);

      if (storedUserType !== "user") {
        navigate("/");
      }
    }
  }, [navigate]);
  const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;

  useEffect(() => {
    if (userId) {
      // Only fetch question counts if userId is present
      // Function to fetch question counts
      const fetchQuestionCounts = async () => {
        try {
          console.log("userId", userId);
          const response = await axios.post(`${baseUrl}/question-counts`, {
            user_id: userId,
          });
          console.log(response.data);
          setCounts(response.data);
        } catch (error) {
          console.error("Error fetching question counts:", error);
          // Handle error
        }
      };

      fetchQuestionCounts();
    }
  }, [userId, baseUrl]);


  return (
    <div className="homeContainer">
      {/* <div className="homeNavBar">
        <div className="homeNavItems">
          <span className="homeIcon">
            {" "}
            <HomeIcon />{" "}
          </span>
          / Home
        </div>
        <div className="homeNavHeader">Home</div>
      </div> */}
      <div className="homeContents">
        <div className="homeCards">
          <div className="leftcard">
            <div className="leftcardHeading">Total Earning</div>
            <div className="leftcardnumber">
              <CurrencyRupeeIcon /> 0
            </div>
          </div>
          <div className="rightCard">
            <span className="rightcardicon">
              <CurrencyRupeeIcon />
            </span>
          </div>
        </div>

        <div className="homeCards">
          <div className="leftcard">
            <div className="leftcardHeading">Total Uploads</div>
            <div className="leftcardnumber">
              <CloudUploadIcon /> {counts.total_question_count}
            </div>
          </div>
          <div className="rightCard">
            <span className="rightcardicon">
              <CloudUploadIcon />
            </span>
          </div>
        </div>

        <div className="homeCards">
          <div className="leftcard">
            <div className="leftcardHeading">Today's Uploads</div>
            <div className="leftcardnumber">
              <DriveFolderUploadIcon /> {counts.today_question_count}
            </div>
          </div>
          <div className="rightCard">
            <span className="rightcardicon">
              <DriveFolderUploadIcon />
            </span>
          </div>
        </div>

        <div className="homeCards">
          <div className="leftcard">
            <div className="leftcardHeading">Today's Earnings</div>
            <div className="leftcardnumber">
              <CurrencyRupeeIcon /> 0
            </div>
          </div>
          <div className="rightCard">
            <span className="rightcardicon">
              <CurrencyRupeeIcon />
            </span>
          </div>
        </div>
      </div>
      <h5 className="chartHeading">1.Questions Status</h5>
      <div className="chart-container">
        <div className="cardAdditional mr chart-card">
          <h6 className="chartHeading">Upload Over Time</h6>
          <Bar data={data} options={options} />
          {/* <Line data={lineData} /> */}
        </div>
        <div className="cardAdditional chart-card">
          <h6 className="chartHeading">Upload Status</h6>
          {/* <Bar data={statusData} options={options} /> */}
          <div className="pieHolder">
            <div className="pieContainer">
              <Pie data={pieData} options={pieOptions} />
            </div>
          </div>
          {/* <Bar data={barData} /> */}
        </div>
      </div>

      <h5 className="chartHeading">2.Subject Wise Upload </h5>
      <div className="chart-container">
        <div className="cardAdditional mr chart-card">
          <h6 className="chartHeading">1st Year</h6>
          <Bar data={subjectWiseData} options={options} />
        </div>
        <div className="cardAdditional mr chart-card">
          <h6 className="chartHeading">2nd Year</h6>
          <Bar data={subjectWiseDataSecondPU} options={options} />
        </div>
      </div>

      <h5 className="chartHeading">3.Earning Status</h5>
      <div className="chart-container">
        <div className="cardAdditional mr chart-card">
          <h6 className="chartHeading">Earning Over Time</h6>
          <Bar data={earningStatus} options={options} />
        </div>
        <div className=" chart-card">
          {/* <h5 className="chartHeading">Subject Wise Upload (2nd Year)</h5>
      <Bar data={subjectWiseDataSecondPU} options={options} /> */}
        </div>
      </div>

      <div>
        <p>Today's Question Count: {counts.today_question_count}</p>
        <p>Yesterday's Question Count: {counts.yesterday_question_count}</p>
        <p>Last 7 Days' Question Count: {counts.last_7_days_question_count}</p>
        <p>This Month's Question Count: {counts.this_month_question_count}</p>
        <p>
          Last 30 Days' Question Count: {counts.last_30_days_question_count}
        </p>
        <p>
          Previous Month's Question Count:{" "}
          {counts.previous_month_question_count}
        </p>
        <p>Pending Question Count: {counts.pending_question_count}</p>
        <p>Reviewed Question Count: {counts.reviewed_question_count}</p>
        <p>Incorrect Question Count: {counts.incorrect_question_count}</p>
        <p>Total Question Count: {counts.total_question_count}</p>
      </div>
    </div>
  );
};

export default Home;
