import React, { useEffect } from 'react'

function PageNotFound() {
  useEffect(()=>{
    window.location.href = "/"
  },[])
  return (
    <div></div>
  )
}

export default PageNotFound