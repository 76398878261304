import React, { useEffect } from 'react';
import LatexRender from '../../LatexRender';
import './TestPrintPreview.css'

const PrintPreviewAnwerSolutionModal = ({
  indexChange,
  questions,
  fontSize,
  correctOptions,
  selectedOption,
  printQueue,
  questionSpace }) => {

  useEffect(() => {
    // Initialize MathJax rendering when component mounts
    if (typeof window !== 'undefined' && typeof window.MathJax !== 'undefined') {
      window.MathJax.typesetPromise();
    }
  }, []);

  const columnsPerRow = 10;
  return (
    <>
      {
        (selectedOption === 'onlyanswer' || selectedOption === 'questionoptionWithanswer' || selectedOption === 'onlyanswerandexplanation') && (
          <div>
            <div><b>Answers of :{correctOptions.length} Questions</b></div>
            {correctOptions.length > 0 ? (
              <table id="correct-options-table" className="table">
                <tbody>
                  {correctOptions.reduce((rows, option, index) => {
                    if (index % columnsPerRow === 0) {
                      rows.push([]);
                    }
                    rows[rows.length - 1].push(option);
                    return rows;
                  }, []).map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((option) => (
                        <td key={option.index} style={{ border: '1px solid black', padding: '5px' }}>
                          {option.index}.  <b>{option.correctOption}</b>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No questions in the print queue</p>
            )}
          </div>
        )
      }

      {(selectedOption === 'onlyanswerandexplanation' || selectedOption === 'onlysolution') && (
        <>

          {printQueue.length > 0 ? (
            printQueue.map((questionId, index) => {
              const question = questions.find((q) => q.questionId === questionId);
              if (!question) return null; // Skip rendering if question not found
              return (
                <div key={question.questionId} style={{ marginBottom: `${questionSpace * 2}px`, marginTop: `${questionSpace * 2}px` }}>
                  <div><b>Solution of Question {(indexChange + index - 1) + 1}:</b></div>
                  {question.explanation_image_url ? (
                    <div className="two-column-container">
                      <div className="left-column">
                        {question.preExplanationText && question.preExplanationText.includes("\\begin") ? (<>
                          <LatexRender content={question.preExplanationText} />
                        </>) : (<>
                          {question.preExplanationText && question.preExplanationText.split('!').map((line, i) => (
                            <p key={i}>{line}</p>
                          ))}
                        </>)}
                        {question.postExplanationText && question.postExplanationText.includes("\\begin") ? (<>
                          <LatexRender content={question.postExplanationText} />
                        </>) : (<>
                          {question.postExplanationText && question.postExplanationText.split('!').map((line, i) => (
                            <p key={i}>{line}</p>
                          ))}
                        </>)}
                      </div>
                      <div className="right-column">
                        {question.explanation_image_url && (
                          <div className="image-container">
                            {question.explanation_image_url.split(',').map((url) => {
                              return <><img src={url.trim()} style={{ width: `${fontSize * 10}px`, height: `${fontSize * 6}px` }} alt="Question" height="100" /><br /></>
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      {question.preExplanationText && question.preExplanationText.includes("\\begin") ? (<>
                        <LatexRender content={question.preExplanationText} />
                      </>) : (<>
                        {question.preExplanationText && question.preExplanationText.split('!').map((line, i) => (
                          <p key={i}>{line}</p>
                        ))}
                      </>)}
                      {question.postExplanationText && question.postExplanationText.includes("\\begin") ? (<>
                        <LatexRender content={question.postExplanationText} />
                      </>) : (<>
                        {question.postExplanationText && question.postExplanationText.split('!').map((line, i) => (
                          <p key={i}>{line}</p>
                        ))}
                      </>)}
                    </>

                  )}

                </div>
              );
            })
          ) : (
            <p>No questions in the print queue</p>
          )}

          <hr />

        </>
      )}


    </>
  )
}

export default PrintPreviewAnwerSolutionModal
