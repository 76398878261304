import React, { useEffect, useState } from 'react'
import axios from 'axios';
import ReviewHearder from './ReviewHearder'
import { useParams } from 'react-router-dom';
import ReviewQuestionSelectionPage from './ReviewQuestionSelectionPage.';
import ViewModel from './ViewModel';

import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ReportBtn from '../Buttons/ReportBtn';

import './Review.css';

import { ToastContainer, toast, Slide } from 'react-toastify';

const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;

const ReviewTopicRelatedQues = () => {
  const { user_id } = useParams();

  const [questions, setQuestions] = useState([]);

  const [selectedYearId, setSelectedYearId] = useState('');
  const [selectedSubjectId, setSelectedSubjectId] = useState('');
  const [selectedChapterId, setSelectedChapterId] = useState('');
  const [selectedTopicId, setSelectedTopicId] = useState('');
// eslint-disable-next-line
  const [triggerToReload, setTriggerToRelod] = useState(false);

  const [topics, setTopics] = useState([]);
  const [topic_id, setTopicId] = useState([]);

  useEffect(() => {
    const fetchTopics = async () => {
      if (selectedChapterId) {
        try {
          const response = await axios.get(`${baseUrl}/topics?chapterId=${selectedChapterId}`);
          setTopics(response.data);
        } catch (error) {
          console.error('Error fetching topics:', error);
        }
      }
    };

    fetchTopics();
  }, [selectedChapterId]);

  const formatQuestions = (data) => {
      return data.map(item => ({
          questionId: item.id,

          preQuestionText: item.pre_question_text,
          postQuestionText: item.post_question_text.split('!'),

          option1Text: item.option1_text,
          option2Text: item.option2_text,
          option3Text: item.option3_text,
          option4Text: item.option4_text,

          correctOption: item.correct_option,

          preExplanationText: item.pre_explanation_text,
          postExplanationText: item.post_explanation_text,

          question_image_url: item.question_image_url,
          option1_image_url: item.option1_image_url,
          option2_image_url: item.option2_image_url,
          option3_image_url: item.option3_image_url,
          option4_image_url: item.option4_image_url,
          explanation_image_url: item.explanation_image_url,

          conceptualError: item.conceptual_error,
          alignmentError: item.alignment_error,
          deletedSyllabus: item.deleted_syllabus,
          difficultyLevel: item.difficulty_level,

      }));
  };

  useEffect(() => {
      const fetchQuestions = async () => {
          try {
              // Create params object conditionally
              const params = {};
              if (selectedYearId) params.year_id = selectedYearId;
              if (selectedSubjectId) params.subject_id = selectedSubjectId;
              if (selectedChapterId) params.chapter_id = selectedChapterId;
              if (selectedTopicId) params.topic_id = selectedTopicId;

              const response = await axios.get(`${baseUrl}/questionstopicrelatedreview`, { params });

              const formattedQuestions = formatQuestions(response.data);
              setQuestions(formattedQuestions);
          } catch (err) {
              console.error('ERROR', err);
          }
      };
      fetchQuestions();
  }, [selectedYearId, selectedSubjectId, selectedChapterId, selectedTopicId, topic_id, triggerToReload]);

  useEffect(() => {
      setTopicId(questions.map(question => question.selectedExamId));
  }, [questions]);

  useEffect(() => {
      const updateQuestionsInDatabase = async () => {
          try {
              const updatedQuestions = questions.map((question, index) => ({
                  ...question,
                  selectedTopicId : topic_id[index]
              }));
              // Example of how you might send updatedQuestions to your backend
              // eslint-disable-next-line
              const response = await axios.post(`${baseUrl}/updatetopicquestions`, { updatedQuestions });
              if (response.status === 201) {
                  toast.success('Questions updated successfully');
                  // setTriggerToRelod(prev => !prev);
              }
          } catch (error) {
              toast.error('Failed to update questions. Please try again later.');
          }
      };
      // console.log(exam_id[0]);
      if (topic_id?.[0] !== null && topic_id?.[0] !== undefined && topic_id?.[0] !=='') {
          updateQuestionsInDatabase();
        }
  }, [topic_id, questions]); // Include all dependencies here

  const handleTopic = (index, value) => {
      const updatedExamIds = [...topic_id];
      updatedExamIds[index] = value;
      setTopicId(updatedExamIds);
  }
  return (
    <div>
    <div>
        <ReviewHearder />
            <ReviewQuestionSelectionPage
                setSelectedYearId={setSelectedYearId}
                setSelectedSubjectId={setSelectedSubjectId}
                setSelectedChapterId={setSelectedChapterId}
                setSelectedTopicId={setSelectedTopicId}
            />
        {questions.map((question, index) => (
            <div key={question.questionId}>
                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                <ReportBtn question={question} user_id={user_id} />
                                                    <div className='mb'>
                                                        <div className='subHeading'>Select Topic:</div>
                                                        <select id="userSelect" value={topic_id?.[0] || ''} onChange={(e) => handleTopic(index, e.target.value)}>
                                                            <option value=''>Select a Topic</option>
                                                            {topics.map(user => (
                                                                <option key={user.topic_id} value={user.topic_id}>
                                                                    {user.topic_name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <span >If you Don't know the Exam Select<b> None </b> in Option</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                <ViewModel question={question} index={index} />
                    </div>
            </div>
        ))}
    </div>
    <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Slide}
    />
</div>
  )
}

export default ReviewTopicRelatedQues
