import React, { useState } from 'react'
import '../CreateTest.css'
import Home from '../Home/home'
import AddQuestions from './TestEditor/AddQuestions'
import Review from './TestEditor/Review'
import Settings from './TestEditor/Settings'

import { useLocation } from 'react-router-dom';

const TestEditor = () => {
  const location = useLocation();
  const id = location.state;

  //id is a column value of test table, retrive data by using id, 


  const [showAddQuestions, setAddQuestions] = useState(true);
  const [showReview, setReview] = useState(false);
  const [showSettings, setSettings] = useState(false);

  const handleShowAddQuestions = () => {
    setAddQuestions(true);
    setReview(false);
    setSettings(false);
  }

  const handleShowReview = () => {
    setAddQuestions(false);
    setReview(true);
    setSettings(false);
  }

  const handleShowSettings = () => {
    setAddQuestions(false);
    setReview(false);
    setSettings(true);
  }

  return (
    <div className="app-container">
      <Home />
      <div className="center-content">
        <div className="navbar">
          <button className="link-style" onClick={handleShowAddQuestions}>Select Questions</button>
          <button className="link-style" onClick={handleShowReview}>Review Questions</button>
          <button className="link-style" onClick={handleShowSettings}>Test Settings</button>
        </div>
        <div className="content">
          {showAddQuestions && <AddQuestions id={id}/>}
          {showReview && <Review id={id}/>}
          {showSettings && <Settings id={id}/>}
        </div>
      </div>
    </div>
  )
}

export default TestEditor
