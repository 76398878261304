import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import CollegeHeader from './CollegeHeader';
import { ToastContainer, toast, Slide } from 'react-toastify';
const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;

const Addlecturer = () => {
    const { user_id } = useParams(); // Destructure the params

    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [status, setStatus] = useState('active');
    const [email, setEmail] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [lecturers, setLecturer] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);
    const [indexId, setIndexId] = useState(0);

    const resetFeilds = () => {
        setName('');
        setPassword('');
        setStatus('active');
        setEmail('');
        setMobileNumber('');
        setEditingIndex(null);
        setShowForm(false);
    };

    useEffect(() => {
        const fetchLecturer = async () => {
            try {
                const response = await axios.get(`${baseUrl}/getcollegelecturer/${user_id}`);
                setLecturer(response.data);
            } catch (error) {
                console.error('Error fetching lecturer:', error);
            }
        };

        if (user_id) {
            fetchLecturer(); // Call fetchLecturer only if user_id is truthy
        }
    }, [user_id, showForm]);

    // console.log(lecturers);

    const handleAddUser = async () => {
        try {
            const response = await axios.post(`${baseUrl}/registercollegelecturer`, { name, password, status, email, mobileNumber, user_id });
            console.log(response);
            if (response.status === 201) {
                // console.log(response.data.message);
                toast.success(response.data.message);
            } else if (response.status === 202) {
                // console.log(response.data.message);
                toast.warn(response.data.message);
            } else {
                // console.log(response.data.message);
                toast.error("Error registering college lecturer");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleUpdateUser = async () => {
        try {
            // console.log(indexId);
            const response = await axios.post(`${baseUrl}/updatecollegelecturer`, { name, password, status, email, mobileNumber, indexId });
            if (response.status === 201) {
                // console.log(response.data.message);
                toast.success(response.data.message);
                resetFeilds();
            } else if (response.status === 202) {
                // console.log(response.data.message);
                toast.warn(response.data.message);
            } else {
                // console.log(response.data.message);
                toast.error("Error updating college lecturer");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleEditUser = (index) => {
        const user = lecturers[index];
        setName(user.name);
        setPassword(user.password);
        setStatus(user.status);
        setEmail(user.email);
        setMobileNumber(user.number);
        setEditingIndex(index);
        setIndexId(user.id);
        setShowForm(!showForm);
    };

    const handleToggleForm = () => {
        setShowForm(!showForm);
    };

    console.log(lecturers);

    return (
        <div>
            <CollegeHeader />
            <div>
                <button onClick={handleToggleForm}>
                    {showForm ? 'Hide Form' : 'Add User'}
                </button>

                <div style={{ display: showForm ? 'block' : 'none' }}>
                    <h2>{editingIndex !== null ? 'Edit User' : 'Add User'}</h2>
                    <div>
                        <label>
                            Name:
                            <input type="text" value={name} onChange={e => setName(e.target.value)} />
                        </label>
                    </div>
                    <div>
                        <label>
                            Password:
                            <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                        </label>
                    </div>
                    <div>
                        <label>
                            Status:
                            <select value={status} onChange={e => setStatus(e.target.value)}>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </select>
                        </label>
                    </div>
                    <div>
                        <label>
                            Email:
                            <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
                        </label>
                    </div>
                    <div>
                        <label>
                            Mobile Number:
                            <input type="tel" value={mobileNumber} onChange={e => setMobileNumber(e.target.value)} />
                        </label>
                    </div>
                    {editingIndex !== null ? (
                        <button onClick={() => handleUpdateUser()}>Save Changes</button>
                    ) : (
                        <button onClick={() => handleAddUser()}>Add User</button>
                    )}
                </div>

                <h2>Added Users</h2>
                <table>
                    <tr>
                        <th>Sl.No</th>
                        <th>Name</th>
                        <th>Password</th>
                        <th>Email</th>
                        <th>Mobile Number</th>
                        <th>Status</th>
                    </tr>
                    {lecturers.length > 0 && lecturers.map((user, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{user.name} </td>
                            <td>{user.password} </td>
                            <td>{user.email}</td>
                            <td>{user.number} </td>
                            <td>{user.status}</td>
                            <td><button onClick={() => handleEditUser(index)}>Edit</button></td>
                        </tr>
                    ))}
                </table>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </div>
    )
}

export default Addlecturer
