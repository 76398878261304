import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import ViewModel from '../../ViewModel';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from 'react-bootstrap/Container';
import { Navigate } from 'react-router-dom';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { ToastContainer, toast, Slide } from 'react-toastify';

const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;
const Review = ({ id }) => {
  const [testId, setTestId] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [printQueue, setPrintQueue] = useState([]);

  const [queueLength, setQueueLength] = useState(0);
  const [redirect, setRedirect] = useState(false);


  const [filter, setFilter] = useState(0);

  const [subjects, setSubjects] = useState([]);
  const [testSubjectValues, setTestSubjectValues] = useState([]);

  const [questionCounts, setQuestionCounts] = useState([]);

  // Define the endpoint
  // const endpoint = '/api/test/';

  // const [testId, setTestId] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (!id) return; // Ensure that id is provided before making the request

  //     try {
  //       const response = await axios.get(`${baseUrl}${endpoint}test_templete/${id}`);
  //       setTestId(response.data[0]);
  //       // console.log(response.data); // Access the response data
  //     } catch (error) {
  //       console.error('Error fetching questions:', error);
  //     }
  //   };

  //   fetchData();
  // }, [id]); // id is included in the dependency array

  // console.log(testId);

  useEffect(() => {
    const subject = [{ subject_id: 0, subject_name: 'All' }, ...new Set(testSubjectValues.map(q => ({ subject_id: q.subject_id, subject_name: q.subject_name })))];
    setSubjects(subject)

  }, [testSubjectValues]);

  const totalQuestions = useMemo(() => [
    { subject_id: 0, total_questions: testSubjectValues.reduce((acc, q) => acc + q.total_questions, 0) },
    ...testSubjectValues.map(q => ({ subject_id: q.subject_id, total_questions: q.total_questions }))
  ], [testSubjectValues]);




  const formatQuestions = (data) => {
    return data.map(item => ({
      questionId: item.id,

      preQuestionText: item.pre_question_text,
      postQuestionText: item.post_question_text.split('!'),

      question_image_url: item.question_image_url,

      option1Text: item.option1_text,
      option2Text: item.option2_text,
      option3Text: item.option3_text,
      option4Text: item.option4_text,

      correctOption: item.correct_option,

      option1_image_url: item.option1_image_url,
      option2_image_url: item.option2_image_url,
      option3_image_url: item.option3_image_url,
      option4_image_url: item.option4_image_url,

      preExplanationText: item.pre_explanation_text,
      postExplanationText: item.post_explanation_text,

      explanation_image_url: item.explanation_image_url,
      selectedYearId: item.selectedYearId,
      selectedSubjectId: item.selectedSubjectId,
      selectedChapterId: item.selectedChapterId,
      selectedTopicId: item.selectedTopicId,
      conceptual_error: item.conceptual_error,
      alignment_error: item.alignment_error,
      deleted_syllabus: item.deleted_syllabus,
      difficulty_level: item.difficulty_level,
      selectedExamId: item.selectedExamId,
      updatedDate: item.updatedDate,
      userId: item.userId

    }));
  };


  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(`${baseUrl}/reviewquestion/${testId.year_id}/${filter}`);
        const formattedQuestions = formatQuestions(response.data);
        // console.log("formattedQuestions ", formattedQuestions);
        setQuestions(formattedQuestions);
      } catch (error) {
        console.error("ERROR fetching Questions for review", error);
      }
    };

    fetchQuestions();
  }, [testId, filter]);


  useEffect(() => {
    const fetchData = async () => {
      if (!id) return; // Ensure that id is provided before making the request

      try {
        const response = await axios.get(`${baseUrl}/api/test/test_templete/${id}`);
        setTestId(response.data[0]);
        // console.log(response.data); // Access the response data
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };

    fetchData();
  }, [id]); // id is included in the dependency array

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return; // Ensure that id is provided before making the request
      try {
        const response = await axios.get(`${baseUrl}/test_templete_questions/${id}`);
        setTestSubjectValues(response.data.results);
        // console.log("test questions ", response.data.results); // Access the response data
      } catch (error) {
        console.error('Error fetching Templete:', error);
      }
    };
    fetchData();
  }, [id]);


  useEffect(() => {
    const fetchQueue = async () => {
      if (!filter || !id) {
        return;
      }
      try {
        const response = await axios.get(`${baseUrl}/fetchqueuebysubject/${filter}/${id}`);
        // Extracting only question_id values
        const queueIds = response.data.map(item => item.question_id);
        setPrintQueue(queueIds);
      } catch (error) {
        console.error('Error fetching queue:', error);
      }
    };

    fetchQueue();
  }, [id, filter]);

  useEffect(() => {
    const fetchQueue = async () => {
      if (!id) {
        return;
      }
      try {
        const response = await axios.get(`${baseUrl}/fetchqueuelength/${id}`);
        setQueueLength(response.data[0].count);
        // console.log(response.data[0].count);
      } catch (error) {
        console.error('Error fetching queue:', error);
      }
    };
    fetchQueue();
  }, [id]);

  useEffect(() => {
    const fetchQuestionCounts = async () => {
      if (!testId || !testSubjectValues || testSubjectValues.length === 0) {
        return;
      }
      try {
        const subjectIds = testSubjectValues.map(q => q.subject_id);
        // console.log(subjectIds);
        const response = await axios.get(`${baseUrl}/queuesubjectcounts`, {
          params: {
            id: testId.id, // Assuming id is defined elsewhere in your code
            subject_ids: subjectIds.join(',') // subjectIds is an array of subject IDs
          }
        });

        // const counts = response.data.reduce((acc, curr) => {
        //   acc[curr.subject_id] = curr.question_count;
        //   return acc;
        // }, {});

        // console.log(response.data);
        setQuestionCounts(response.data);
      } catch (error) {
        console.error('Error fetching question counts:', error);
      }
    };
    fetchQuestionCounts();
  }, [testId, testSubjectValues]);



  const handleSubmit = async (id) => {
    try {
      const response = await axios.post(`${baseUrl}/submittest/${id}`);
      // Check if response code is 200
      if (response.status === 200) {
        // Display success alert
        toast.success(response.data.message);
        setRedirect(true);
      } else {
        // Display error alert
        toast.error('Error submitting test from server');
      }
    } catch (error) {
      console.error('Error submitting test:', error);
    }
  }

  const questionsToDisplay = questions.filter(question => printQueue.includes(question.questionId));

  function compareQuestionsAndCounts() {
    const comparisonResults = [];
  
    testSubjectValues.forEach(testSubject => {
      const questionCountEntry = questionCounts.find(qc => qc.subject_id == testSubject.subject_id);
  
      if (!questionCountEntry) {
        // console.warn(`No question count found for subject ID ${testSubject.subject_id}`);
        comparisonResults.push({
          subject_id: testSubject.subject_id,
          areEqual: false  // Consider how to handle missing data
        });
        return;  // Skip to next iteration
      }
  
      const questionCount = questionCountEntry.question_count;
  
      // console.log(`Subject ID: ${testSubject.subject_id}, Total Questions: ${testSubject.total_questions}, Question Count: ${questionCount}`);
  
      const areEqual = testSubject.total_questions === questionCount;
  
      comparisonResults.push({
        subject_id: testSubject.subject_id,
        areEqual: areEqual
      });
    });
  
    // console.log("Comparison results:", comparisonResults); // Log comparison results
  
    return comparisonResults.every(result => result.areEqual === true);
  }
  
  const results = compareQuestionsAndCounts();
  // console.log('results',results);

  return (
    <div>
      {/* {redirect && <Navigate to="/testpublished" state={id} />} */}
      {redirect && <Navigate to="/test" />}
      <div className="d-flex flex-column align-items-center">
        {totalQuestions.length > 0 && (<>{filter && (<>TOTAL QUESTIONS : {totalQuestions[0].total_questions}</>)}</>)}
        <Tabs
          defaultActiveKey={subjects[1]?.subject_id}
          id="fill-tab-example"
          className="mb-3"
          onSelect={(k) => setFilter(k)}
          fill
        >
          {subjects.map((subject, index) => {
            if (subject.subject_id !== 0) {
              return (
                <Tab
                  key={index}
                  eventKey={subject.subject_id}
                  title={
                    <>
                      {subject.subject_name} {subject.subject_id == filter && (<> : {printQueue.length} / {totalQuestions.length > 0 && totalQuestions.find(t => t.subject_id == filter)?.total_questions}</>)}
                    </>
                  }
                />
              );
            }
            return null; // Return null when subject_id is 'All'
          })}

        </Tabs>
        {(queueLength == (totalQuestions.length > 0 && totalQuestions[0].total_questions) && results) && (<>
          <div>
            <button
              onClick={() => handleSubmit(id)}
            >Publish
            </button>
          </div>
        </>)}

        {printQueue.length > 0 ? (
          <>
            {questionsToDisplay.map((question, index) => (
              <div key={question.questionId} className="w-100 d-flex justify-content-center my-2">
                <Paper elevation={3} className="p-2" style={{ width: '1080px', boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)' }}>
                  <div>
                    <Typography variant="body1">
                      <Container fluid="md">
                        <ViewModel question={question} index={index} />
                      </Container>
                    </Typography>
                  </div>
                </Paper>
              </div>
            ))}
          </>
        ) : (
          <>No Questions to Review</>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Slide}
      />
    </div>
  )
}

export default Review
