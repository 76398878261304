import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useNavigate, Navigate } from "react-router-dom";

const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;


const LecturerHome = () => {
    const [userId, setUserId] = useState(0);
    const [user_id, setCollege_id] = useState(0);
    const navigate = useNavigate();
    const [navigatePages, setNavigatePages] = useState([]);
    const [navigates, setNavigates] = useState('');
    const [routeTo, setRouteTo] = useState('');

    useEffect(() => {
        // Check if access token is present in local storage
        const accessToken = localStorage.getItem("accessToken");
        const userId = localStorage.getItem("userId");
        // console.log("REVIEWER USER_ID", userId);
        setUserId(userId);

        if (!userId || !accessToken) {
            // Redirect to login page if userId or accessToken is missing
            navigate("/login");
        }
    }, [navigate]);

    useEffect(() => {
        const fetchLecturer = async () => {
            try {
                const response = await axios.get(`${baseUrl}/getcollegeid/${userId}`);
                setCollege_id(response.data);
            } catch (error) {
                console.error('Error fetching lecturer:', error);
            }
        };

        if (userId) {
            fetchLecturer(); // Call fetchLecturer only if user_id is truthy
        }
    }, [userId]);

    useEffect(() => {
        setNavigatePages(['View Unpublished Test']);
    }, []);

    useEffect(() => {
        if (navigates === 'View Unpublished Test') {
            setRouteTo("View Unpublished Test")
        }
    }, [navigates, navigate, user_id]);

    const handleLogout = () => {
        localStorage.removeItem("userId");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("userType");

        navigate("/login");
    };

    return (
        <div className="d-flex flex-column align-items-center">
            {(routeTo === "View Unpublished Test" && user_id !== 0) && (
                <Navigate to={`/lecturerunpublishedtest/${user_id}`} state={user_id} />
            )}

            <button onClick={handleLogout}>Logout</button>
            <Tabs
                defaultActiveKey=''
                id="fill-tab-example"
                className="mb-3"
                onSelect={(k) => setNavigates(k)}
                fill
            >
                {navigatePages.map((page, index) => (
                    <Tab
                        key={index}
                        eventKey={page}
                        title={page}
                    />
                ))}
            </Tabs>
        </div>
    )
}

export default LecturerHome
