import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <nav className="nav justify-content-center mb-4">
      <Link className="nav-link" to="/home">Home</Link>
      <Link className="nav-link" to="/upload">Upload Question</Link>
      <Link className="nav-link" to="/test">Create Test</Link>
      <Link className="nav-link" to="/view">View Uploaded Question</Link>
      <Link className="nav-link" to="/subject">Subject data</Link>
    </nav>
  );
}

export default Header;
