import React, { useState } from 'react';
import axios from 'axios';
import Home from '../Home/home';

const AddExamForm = () => {
  const [examName, setExamName] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Fetch environment variables
      const protocol = process.env.REACT_APP_API_PROTOCOL;
      const host = process.env.REACT_APP_API_HOST;
      const port = process.env.REACT_APP_API_PORT;

      // Construct the base URL
      const baseUrl = `${protocol}://${host}:${port}`;

      // Send a POST request to the API endpoint with the base URL
      await axios.post(`${baseUrl}/add-exam`, { examName });

      setExamName('');
      alert('Exam added successfully!');
    } catch (error) {
      console.error('Error adding exam:', error);
      alert('Error adding exam. Please try again.');
    }
  };

  return (
    <>
    <Home/>
    <div className="container mt-5">
      <h2>Add Exam</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="examName" className="form-label">Exam Name:</label>
          <input
            type="text"
            className="form-control"
            id="examName"
            value={examName}
            onChange={(e) => setExamName(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Add Exam</button>
      </form>
    </div>
    </>
  );
};

export default AddExamForm;
