import React, { useState, useEffect } from "react";
import axios from "axios";
import Home from "../Home/home";

const AddSubtopicForm = () => {
  const [subtopicName, setSubtopicName] = useState("");
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [chapters, setChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState("");
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState("");

  useEffect(() => {
    // Fetch years from the backend API
    const fetchYears = async () => {
      try {
        const protocol = process.env.REACT_APP_API_PROTOCOL;
        const host = process.env.REACT_APP_API_HOST;
        const port = process.env.REACT_APP_API_PORT;
        const baseUrl = `${protocol}://${host}:${port}`;

        const response = await axios.get(`${baseUrl}/years`);
        setYears(response.data);
      } catch (error) {
        console.error("Error fetching years:", error);
      }
    };

    fetchYears();
  }, []);

  useEffect(() => {
    // Fetch subjects based on the selected year
    const fetchSubjects = async () => {
      if (selectedYear) {
        try {
          const protocol = process.env.REACT_APP_API_PROTOCOL;
          const host = process.env.REACT_APP_API_HOST;
          const port = process.env.REACT_APP_API_PORT;
          const baseUrl = `${protocol}://${host}:${port}`;

          const response = await axios.get(
            `${baseUrl}/subjects?yearId=${selectedYear}`
          );
          setSubjects(response.data);
        } catch (error) {
          console.error("Error fetching subjects:", error);
        }
      }
    };

    fetchSubjects();
  }, [selectedYear]);

  useEffect(() => {
    // Fetch chapters based on the selected subject
    const fetchChapters = async () => {
      if (selectedSubject) {
        try {
          const protocol = process.env.REACT_APP_API_PROTOCOL;
          const host = process.env.REACT_APP_API_HOST;
          const port = process.env.REACT_APP_API_PORT;
          const baseUrl = `${protocol}://${host}:${port}`;

          const response = await axios.get(
            `${baseUrl}/chapters?subjectId=${selectedSubject}`
          );
          setChapters(response.data);
        } catch (error) {
          console.error("Error fetching chapters:", error);
        }
      }
    };

    fetchChapters();
  }, [selectedSubject]);

  useEffect(() => {
    // Fetch topics based on the selected chapter
    const fetchTopics = async () => {
      if (selectedChapter) {
        try {
          const protocol = process.env.REACT_APP_API_PROTOCOL;
          const host = process.env.REACT_APP_API_HOST;
          const port = process.env.REACT_APP_API_PORT;
          const baseUrl = `${protocol}://${host}:${port}`;

          const response = await axios.get(
            `${baseUrl}/topics?chapterId=${selectedChapter}`
          );
          setTopics(response.data);
        } catch (error) {
          console.error("Error fetching topics:", error);
        }
      }
    };

    fetchTopics();
  }, [selectedChapter]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const protocol = process.env.REACT_APP_API_PROTOCOL;
      const host = process.env.REACT_APP_API_HOST;
      const port = process.env.REACT_APP_API_PORT;
      const baseUrl = `${protocol}://${host}:${port}`;

      await axios.post(`${baseUrl}/add-subtopic`, {
        subtopicName,
        topicId: selectedTopic,
      });
      setSubtopicName("");
      alert("Subtopic added successfully!");
    } catch (error) {
      console.error("Error adding subtopic:", error);
      alert("Error adding subtopic. Please try again.");
    }
  };

  return (
    <>
      <Home />
      <div className="container mt-5">
        <h2>Add Subtopic</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="yearSelect" className="form-label">
              Year:
            </label>
            <select
              id="yearSelect"
              className="form-select"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              required
            >
              <option value="">Select Year</option>
              {years.map((year) => (
                <option key={year.year_id} value={year.year_id}>
                  {year.year_name}
                </option>
              ))}
            </select>
          </div>
          {selectedYear && (
            <div className="mb-3">
              <label htmlFor="subjectSelect" className="form-label">
                Subject:
              </label>
              <select
                id="subjectSelect"
                className="form-select"
                value={selectedSubject}
                onChange={(e) => setSelectedSubject(e.target.value)}
                required
              >
                <option value="">Select Subject</option>
                {subjects.map((subject) => (
                  <option key={subject.subject_id} value={subject.subject_id}>
                    {subject.subject_name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {selectedSubject && (
            <div className="mb-3">
              <label htmlFor="chapterSelect" className="form-label">
                Chapter:
              </label>
              <select
                id="chapterSelect"
                className="form-select"
                value={selectedChapter}
                onChange={(e) => setSelectedChapter(e.target.value)}
                required
              >
                <option value="">Select Chapter</option>
                {chapters.map((chapter) => (
                  <option key={chapter.chapter_id} value={chapter.chapter_id}>
                    {chapter.chapter_name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {selectedChapter && (
            <div className="mb-3">
              <label htmlFor="topicSelect" className="form-label">
                Topic:
              </label>
              <select
                id="topicSelect"
                className="form-select"
                value={selectedTopic}
                onChange={(e) => setSelectedTopic(e.target.value)}
                required
              >
                <option value="">Select Topic</option>
                {topics.map((topic) => (
                  <option key={topic.topic_id} value={topic.topic_id}>
                    {topic.topic_name}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="mb-3">
            <label htmlFor="subtopicName" className="form-label">
              Subtopic Name:
            </label>
            <input
              type="text"
              id="subtopicName"
              className="form-control"
              value={subtopicName}
              onChange={(e) => setSubtopicName(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Add Subtopic
          </button>
        </form>
      </div>
    </>
  );
};

export default AddSubtopicForm;
