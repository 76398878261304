import React, { useState, useEffect } from 'react';
import TestModal from './TestModal';
import { Navigate } from 'react-router-dom';
import { useNavigate } from "react-router-dom";


const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;


const PublishedTest = () => {
  const navigate = useNavigate();

  const [user_id, setUserId] = useState(0);

  useEffect(() => {
    // Check if access token is present in local storage
    const accessToken = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");
    // console.log("User ID in user view dashboard", userId);
    setUserId(userId);

    if (!userId || !accessToken) {
      // Redirect to login page if userId or accessToken is missing
      navigate("/login");
    }
  }, [navigate]);

  
  const [tests, setTests] = useState([]);

  useEffect(() => {
    const url = `${baseUrl}/test/published?user_id=${user_id}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setTests(data);
      })
      .catch((error) => {
        console.error("Error fetching Published tests:", error);
      });
  }, [user_id, tests]); // useEffect will re-run whenever userId changes
  
  const [redirect, setRedirect] = useState(false);
  const [testId, setTestId] = useState(0);

  const handleTestClick = (id) => {
    setTestId(id);
    setRedirect(true);
    // Handle navigation or other actions based on testId
  };

  // console.log(tests);


  return (
    <div>
    {redirect
      ? (
        <Navigate to={`/testpublished/${testId}`} state={{ test_id: testId }} />
      ) : (
        <>
          {
            tests.length > 0 ? (
              <>
                {tests.map((test, index) => (
                  test.status === 'published' && (
                    <TestModal key={index} index={index} test={test} onClick={()=> handleTestClick(test.id)} />
                  )
                ))}

              </>
            ) : (
              <p>No published tests found.</p>
            )
          }
        </>
      )}


  </div>
  )
}

export default PublishedTest
