import React, { useState, useEffect } from 'react';
import LatexRender from '../../../LatexRender';
import './TestPrintPreview.css'
import PrintPreviewAnwerSolutionModal from './PrintPreviewAnwerSolutionModal';
import { FaCog } from 'react-icons/fa';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';

const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;


const PrintPreviewQuestionModal = ({
    indexChange,
    displayColumns,
    questionSpace,
    printQueue,
    questions,
    optionPrefix,
    prefixes,
    fontSize,
    displayLines,
    selectedOption,
    showAnswer }) => {

    const [correctOptions, setCorrectOptions] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(null); // Track the selected index for the dropdown
    // const [imageAlignment, setImageAlignment] = useState('Right'); // Initial image alignment state

    useEffect(() => {
        const updatedCorrectOptions = printQueue.map((questionId, index) => {
            const question = questions.find((q) => q.questionId === questionId);
            if (!question) return null; // Skip if question not found

            let correctOption = question.correctOption;

            switch (optionPrefix) {
                case "a":
                    switchPrefix("a", "b", "c", "d");
                    break;
                case "A":
                    switchPrefix("A", "B", "C", "D");
                    break;
                case "1":
                    switchPrefix("1", "2", "3", "4");
                    break;
                case "i":
                    switchPrefix("i", "ii", "iii", "iv");
                    break;
                default:
                    break;
            }

            function switchPrefix(a, b, c, d) {
                switch (correctOption) {
                    case 'A':
                    case 'a':
                    case '1':
                    case 'i':
                        correctOption = prefixes[0].replace(/[().]/g, '');
                        break;
                    case 'B':
                    case 'b':
                    case '2':
                    case 'ii':
                        correctOption = prefixes[1].replace(/[().]/g, '');
                        break;
                    case 'C':
                    case 'c':
                    case '3':
                    case 'iii':
                        correctOption = prefixes[1].replace(/[().]/g, '');
                        break;
                    case 'D':
                    case 'd':
                    case '4':
                    case 'iv':
                        correctOption = prefixes[1].replace(/[().]/g, '');
                        break;
                    default:
                        break;
                }
            }

            return {
                index: indexChange + index,
                correctOption,
                displayLines: 4, // Default display lines
                imageScale: 1, // Default image scale
                // imageAlignment: 'end', // Default image alignment
            };
        }).filter(Boolean); // Filter out null values (questions not found)

        setCorrectOptions(updatedCorrectOptions);
    }, [printQueue, questions, indexChange, optionPrefix, prefixes, displayLines]); // Include prefixes in the dependency array


    const handleDisplayLinesChange = (e, index) => {
        const newDisplayLines = parseInt(e.target.value, 10);

        // Update the display lines for the corresponding question in correctOptions
        const updatedCorrectOptions = correctOptions.map((option, i) => {
            if (i === index) {
                return { ...option, displayLines: newDisplayLines };
            }
            return option;
        });
        setCorrectOptions(updatedCorrectOptions);
    };

    const handleImageScaleChange = (index, increment) => {
        const updatedCorrectOptions = correctOptions.map((option, i) => {
            if (i === index) {
                const newScale = option.imageScale + increment;
                return { ...option, imageScale: newScale > 0 ? newScale : 1 };
            }
            return option;
        });
        setCorrectOptions(updatedCorrectOptions);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setSelectedIndex(index);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    useEffect(() => {
        // Initialize MathJax rendering when component mounts
        if (typeof window !== 'undefined' && typeof window.MathJax !== 'undefined') {
            window.MathJax.typesetPromise();
        }
    }, [selectedOption, displayColumns, correctOptions]);

    const blockContextMenu = (event) => {
        event.preventDefault(); // Prevent default context menu behavior
    }

    return (
        <div style={{ columnCount: displayColumns }}>
            {(selectedOption === 'question' || selectedOption === 'questionoptionWithanswer') && (
                <>
                    {printQueue.length > 0 ? (
                        printQueue.map((questionId, index) => {
                            const question = questions.find((q) => q.questionId === questionId);
                            if (!question) return null; // Skip rendering if question not found
                            const correctOption = correctOptions[index]; // Fetch the correct option
                            const questionDisplayLines = correctOption ? correctOption.displayLines : 1; // Get display lines or default to 1
                            const imageScale = correctOption ? correctOption.imageScale : 1; // Get image scale or default to 1
                            // const imageAlignment = correctOption ? correctOption.imageAlignment : 'right'; // Get image alignment

                            return (
                                <div key={question.questionId} style={{ marginBottom: `${questionSpace * 2}px`, marginTop: `${questionSpace * 2}px` }}>
                                    <div className="two-column-container">
                                        <div className="left-column disableCopy" onContextMenu={blockContextMenu}>
                                            <b>{(indexChange + index - 1) + 1}</b>. {question.preQuestionText}

                                            {question.question_image_url && (
                                                question.question_image_url.split(',').map((url) => {
                                                    return <><img src={url.trim()} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Question" height="100" /><br /></>
                                                })
                                            )}

                                            {question.postQuestionText.map((line, i) => (
                                                <p key={i}>{line}</p>
                                            ))}
                                        </div>

                                        <div className="icon-wrapper hide-on-print">

                                            <FaCog aria-describedby={id} type="button" onClick={(e) => handleClick(e, index)} style={{ cursor: 'pointer', fontSize: '1.5rem' }} className="hide-on-print" />
                                            <Popover
                                                id={id}
                                                open={open && selectedIndex === index}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <Typography sx={{ p: 2 }}> <div>
                                                    <h4>Options Per Line</h4>
                                                    <button
                                                        onClick={() => handleDisplayLinesChange({ target: { value: 1 } }, index)}
                                                        className={questionDisplayLines === 1 ? 'active' : ''}
                                                    >
                                                        1
                                                    </button>
                                                    <button
                                                        onClick={() => handleDisplayLinesChange({ target: { value: 2 } }, index)}
                                                        className={questionDisplayLines === 2 ? 'active' : ''}
                                                    >
                                                        2
                                                    </button>
                                                    <button
                                                        onClick={() => handleDisplayLinesChange({ target: { value: 4 } }, index)}
                                                        className={questionDisplayLines === 4 ? 'active' : ''}
                                                    >
                                                        4
                                                    </button>
                                                </div>

                                                    <div>
                                                        <h4>Set Imagesize</h4>
                                                        <button onClick={() => handleImageScaleChange(index, -0.1)}>-10</button>
                                                        <button onClick={() => handleImageScaleChange(index, 0.1)}>+10</button>
                                                    </div>
                                                </Typography>
                                            </Popover>

                                        </div>


                                    </div>

                                    <div className="two-column-container">
                                        <div className="left-column disableCopy" onContextMenu={blockContextMenu}>
                                            {/* Render options for printing */}
                                            {/* Render options with gaps */}
                                            {questionDisplayLines === 1 && (
                                                <div>
                                                    {question.option1Text && <>{prefixes[0]} {question.option1Text}</>}
                                                    {question.option2Text && <><br />{prefixes[1]} {question.option2Text}</>}
                                                    {question.option3Text && <><br />{prefixes[2]} {question.option3Text}</>}
                                                    {question.option4Text && <><br />{prefixes[3]} {question.option4Text}</>}
                                                    {question.option1_image_url && <>{prefixes[0]} <img src={question.option1_image_url} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Option A" /><br /></>}
                                                    {question.option2_image_url && <>{prefixes[1]} <img src={question.option2_image_url} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Option B" /><br /></>}
                                                    {question.option3_image_url && <>{prefixes[2]} <img src={question.option3_image_url} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Option C" /><br /></>}
                                                    {question.option4_image_url && <>{prefixes[3]} <img src={question.option4_image_url} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Option D" /><br /></>}
                                                </div>

                                            )}
                                            {questionDisplayLines === 2 && (
                                                <div>
                                                    {question.option1Text && <>{prefixes[0]} {question.option1Text}</>}
                                                    {question.option2Text && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{prefixes[1]} {question.option2Text}<br /></>}
                                                    {question.option3Text && <>{prefixes[2]} {question.option3Text}</>}
                                                    {question.option4Text && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{prefixes[3]} {question.option4Text}<br /></>}
                                                    {question.option1_image_url && <>{prefixes[0]} <img src={question.option1_image_url} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Option A" /></>}
                                                    {question.option2_image_url && <>&nbsp;&nbsp;{prefixes[1]} <img src={question.option2_image_url} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Option B" /><br /></>}
                                                    {question.option3_image_url && <>{prefixes[2]} <img src={question.option3_image_url} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Option C" /></>}
                                                    {question.option4_image_url && <>&nbsp;&nbsp;{prefixes[3]} <img src={question.option4_image_url} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Option D" /><br /></>}
                                                </div>
                                            )}
                                            {questionDisplayLines === 4 && (
                                                <div>
                                                    {question.option1Text && <>{prefixes[0]} {question.option1Text}</>}
                                                    {question.option2Text && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{prefixes[1]} {question.option2Text}</>}
                                                    {question.option3Text && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{prefixes[2]} {question.option3Text}</>}
                                                    {question.option4Text && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{prefixes[3]} {question.option4Text}</>}
                                                    {question.option1_image_url && <>{prefixes[0]} <img src={question.option1_image_url} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Option A" /></>}
                                                    {question.option2_image_url && <>&nbsp;&nbsp;{prefixes[1]} <img src={question.option2_image_url} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Option B" /></>}
                                                    {question.option3_image_url && <>&nbsp;&nbsp;{prefixes[2]} <img src={question.option3_image_url} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Option C" /></>}
                                                    {question.option4_image_url && <>&nbsp;&nbsp;{prefixes[3]} <img src={question.option4_image_url} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Option D" /></>}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {(showAnswer && selectedOption === 'questionoptionWithanswer') && (
                                        <b>Answer: {correctOption.correctOption}</b>
                                    )}
                                </div>
                            );
                        })
                    ) : (
                        <p>No questions in the print queue</p>
                    )}
                    {/* Render based on selected option */}

                </>

            )}

            {(selectedOption === 'onlyanswerandexplanation' || selectedOption === 'onlyanswer' || selectedOption === 'onlysolution') && (
                <>
                    <PrintPreviewAnwerSolutionModal indexChange={indexChange} questions={questions} fontSize={fontSize} correctOptions={correctOptions} selectedOption={selectedOption} printQueue={printQueue} questionSpace={questionSpace} />
                </>
            )}

            {selectedOption === 'onlyquestion' && (
                <>
                    {printQueue.length > 0 ? (
                        printQueue.map((questionId, index) => {
                            const question = questions.find((q) => q.questionId === questionId);
                            if (!question) return null; // Skip rendering if question not found
                            return (
                                <div key={question.questionId} style={{ marginBottom: `${questionSpace * 2}px`, marginTop: `${questionSpace * 2}px` }}>
                                    <div className="two-column-container">
                                        <div className="left-column disableCopy" onContextMenu={blockContextMenu}>
                                            <b>{(indexChange + index - 1) + 1}</b>. {question.preQuestionText}
                                            {question.question_image_url && (
                                                question.question_image_url.split(',').map((url) => {
                                                    return <><img src={url.trim()} style={{ width: `${fontSize * 10}px`, height: `${fontSize * 6}px` }} alt="Question" height="100" /><br /></>
                                                })
                                            )}
                                            {question.postQuestionText.map((line, i) => (
                                                <p key={i}>{line}</p>
                                            ))}
                                        </div>
                                    </div>

                                </div>
                            );
                        })
                    ) : (
                        <p>No questions in the print queue</p>
                    )}
                </>
            )}

            {selectedOption === 'questionoptionsanswerexplanation' && (
                <>
                    <div className="two-column-container">
                        <div className="left-column">
                            {printQueue.length > 0 ? (
                                printQueue.map((questionId, index) => {
                                    const question = questions.find((q) => q.questionId === questionId);
                                    if (!question) return null; // Skip rendering if question not found
                                    const correctOption = correctOptions[index]; // Fetch the correct option
                                    const questionDisplayLines = correctOption ? correctOption.displayLines : 1; // Get display lines or default to 1
                                    const imageScale = correctOption ? correctOption.imageScale : 1; // Get image scale or default to 1
                                    return (
                                        <div key={question.questionId} style={{ marginBottom: `${questionSpace * 2}px`, marginTop: `${questionSpace * 2}px` }}>
                                            <div className="two-column-container">
                                                <div className="left-column disableCopy" onContextMenu={blockContextMenu}>
                                                    <b>{(indexChange + index - 1) + 1}</b>. {question.preQuestionText}

                                                    {question.question_image_url && (
                                                        question.question_image_url.split(',').map((url) => {
                                                            return <><img src={url.trim()} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Question" height="100" /><br /></>
                                                        })
                                                    )}

                                                    {question.postQuestionText.map((line, i) => (
                                                        <p key={i}>{line}</p>
                                                    ))}
                                                </div>

                                                <div className="icon-wrapper hide-on-print">

                                                    <FaCog aria-describedby={id} type="button" onClick={(e) => handleClick(e, index)} style={{ cursor: 'pointer', fontSize: '1.5rem' }} className="hide-on-print" />
                                                    <Popover
                                                        id={id}
                                                        open={open && selectedIndex === index}
                                                        anchorEl={anchorEl}
                                                        onClose={handleClose}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <Typography sx={{ p: 2 }}> <div>
                                                            <h4>Options Per Line</h4>
                                                            <button
                                                                onClick={() => handleDisplayLinesChange({ target: { value: 1 } }, index)}
                                                                className={questionDisplayLines === 1 ? 'active' : ''}
                                                            >
                                                                1
                                                            </button>
                                                            <button
                                                                onClick={() => handleDisplayLinesChange({ target: { value: 2 } }, index)}
                                                                className={questionDisplayLines === 2 ? 'active' : ''}
                                                            >
                                                                2
                                                            </button>
                                                            <button
                                                                onClick={() => handleDisplayLinesChange({ target: { value: 4 } }, index)}
                                                                className={questionDisplayLines === 4 ? 'active' : ''}
                                                            >
                                                                4
                                                            </button>
                                                        </div>

                                                            <div>
                                                                <h4>Set Imagesize</h4>
                                                                <button onClick={() => handleImageScaleChange(index, -0.1)}>-10</button>
                                                                <button onClick={() => handleImageScaleChange(index, 0.1)}>+10</button>
                                                            </div>
                                                        </Typography>
                                                    </Popover>

                                                </div>

                                            </div>

                                            <div className="two-column-container">
                                                <div className="left-column disableCopy" onContextMenu={blockContextMenu}>
                                                    {/* Render options for printing */}
                                                    {/* Render options with gaps */}
                                                    {questionDisplayLines === 1 && (
                                                        <div>
                                                            {question.option1Text && <>{prefixes[0]} {question.option1Text}</>}
                                                            {question.option2Text && <><br />{prefixes[1]} {question.option2Text}</>}
                                                            {question.option3Text && <><br />{prefixes[2]} {question.option3Text}</>}
                                                            {question.option4Text && <><br />{prefixes[3]} {question.option4Text}</>}
                                                            {question.option1_image_url && <>{prefixes[0]} <img src={question.option1_image_url} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Option A" /><br /></>}
                                                            {question.option2_image_url && <>{prefixes[1]} <img src={question.option2_image_url} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Option B" /><br /></>}
                                                            {question.option3_image_url && <>{prefixes[2]} <img src={question.option3_image_url} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Option C" /><br /></>}
                                                            {question.option4_image_url && <>{prefixes[3]} <img src={question.option4_image_url} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Option D" /><br /></>}
                                                        </div>

                                                    )}
                                                    {questionDisplayLines === 2 && (
                                                        <div>
                                                            {question.option1Text && <>{prefixes[0]} {question.option1Text}</>}
                                                            {question.option2Text && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{prefixes[1]} {question.option2Text}<br /></>}
                                                            {question.option3Text && <>{prefixes[2]} {question.option3Text}</>}
                                                            {question.option4Text && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{prefixes[3]} {question.option4Text}<br /></>}
                                                            {question.option1_image_url && <>{prefixes[0]} <img src={question.option1_image_url} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Option A" /></>}
                                                            {question.option2_image_url && <>&nbsp;&nbsp;{prefixes[1]} <img src={question.option2_image_url} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Option B" /><br /></>}
                                                            {question.option3_image_url && <>{prefixes[2]} <img src={question.option3_image_url} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Option C" /></>}
                                                            {question.option4_image_url && <>&nbsp;&nbsp;{prefixes[3]} <img src={question.option4_image_url} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Option D" /><br /></>}
                                                        </div>
                                                    )}
                                                    {questionDisplayLines === 4 && (
                                                        <div>
                                                            {question.option1Text && <>{prefixes[0]} {question.option1Text}</>}
                                                            {question.option2Text && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{prefixes[1]} {question.option2Text}</>}
                                                            {question.option3Text && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{prefixes[2]} {question.option3Text}</>}
                                                            {question.option4Text && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{prefixes[3]} {question.option4Text}</>}
                                                            {question.option1_image_url && <>{prefixes[0]} <img src={question.option1_image_url} style={{ width: `${fontSize * 10}px`, height: `${fontSize * 6}px` }} alt="Option A" /></>}
                                                            {question.option2_image_url && <>&nbsp;&nbsp;{prefixes[1]} <img src={question.option2_image_url} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Option B" /></>}
                                                            {question.option3_image_url && <>&nbsp;&nbsp;{prefixes[2]} <img src={question.option3_image_url} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Option C" /></>}
                                                            {question.option4_image_url && <>&nbsp;&nbsp;{prefixes[3]} <img src={question.option4_image_url} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Option D" /></>}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="two-column-container">
                                                <div className="left-column disableCopy" onContextMenu={blockContextMenu}>
                                                    {/* {question.correctOption && <p className="math-equation" id='option1'><b>Answer: {question.correctOption}</b></p>} */}
                                                    {question.correctOption && <p className="math-equation" id='option1'><b>Answer: {correctOptions[index].correctOption}</b></p>}
                                                    <>
                                                        <b>Solution :</b>
                                                        {question.explanation_image_url ? (
                                                            <div className="two-column-container">
                                                                <div className="left-column">
                                                                    {question.preExplanationText && question.preExplanationText.includes("\\begin") ? (<>
                                                                        <LatexRender content={question.preExplanationText} />
                                                                    </>) : (<>
                                                                        {question.preExplanationText && question.preExplanationText.split('!').map((line, i) => (
                                                                            <p key={i}>{line}</p>
                                                                        ))}
                                                                    </>)}
                                                                    {question.postExplanationText && question.postExplanationText.includes("\\begin") ? (<>
                                                                        <LatexRender content={question.postExplanationText} />
                                                                    </>) : (<>
                                                                        {question.postExplanationText && question.postExplanationText.split('!').map((line, i) => (
                                                                            <p key={i}>{line}</p>
                                                                        ))}
                                                                    </>)}
                                                                </div>
                                                                <div className="right-column">
                                                                    {question.explanation_image_url && (
                                                                        <div className="image-container">
                                                                            {question.explanation_image_url.split(',').map((url) => {
                                                                                return <><img src={url.trim()} style={{ width: `${fontSize * 10 * imageScale}px`, height: `${fontSize * 6 * imageScale}px` }} alt="Question" height="100" /><br /></>
                                                                            })}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {question.preExplanationText && question.preExplanationText.includes("\\begin") ? (<>
                                                                    <LatexRender content={question.preExplanationText} />
                                                                </>) : (<>
                                                                    {question.preExplanationText && question.preExplanationText.split('!').map((line, i) => (
                                                                        <p key={i}>{line}</p>
                                                                    ))}
                                                                </>)}
                                                                {question.postExplanationText && question.postExplanationText.includes("\\begin") ? (<>
                                                                    <LatexRender content={question.postExplanationText} />
                                                                </>) : (<>
                                                                    {question.postExplanationText && question.postExplanationText.split('!').map((line, i) => (
                                                                        <p key={i}>{line}</p>
                                                                    ))}
                                                                </>)}
                                                            </>

                                                        )}
                                                        <hr />
                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <p>No questions in the print queue</p>
                            )}
                        </div>
                    </div>
                </>
            )}


        </div>
    )
}

export default PrintPreviewQuestionModal
