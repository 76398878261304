import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;

const Modal = ({ index, report, onClick }) => {

    const [user, setUser] = useState({});

    useEffect(() => {
        const fetchUser = async () => {
            try {
                if (!report || !report.user_id || report.user_id === 0) {
                    setUser({});
                    return;
                }
                const response = await axios.get(`${baseUrl}/getusertoreport/${report.user_id}`);
                setUser(response.data);
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };
        fetchUser();
    }, [report]);

    const handleClick = () => {
        onClick(report.id);
    };
    return (
        <div>
            <div className="test-modal" onClick={handleClick}>
                <Paper elevation={3} className="my-2 p-2" style={{ maxWidth: '640px', boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)' }}>
                    <div>
                        <Typography variant="body1">
                            {index + 1}. {report.report_issue}  |  <label style={{ backgroundColor: report.status === 'pending' ? 'red' : report.status === 'processing' ? 'orange' : 'green' }}> Status: {report.status} </label>
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="body2">
                            {report.report_subject}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="body2">
                            {report.question_id ? `Question ID : ${report.question_id}` : ''}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="body2">
                            Reported User Name : {user.name}
                        </Typography>
                    </div>
                </Paper>
            </div>
        </div>
    )
}

export default Modal
