import React, { useState, useEffect } from 'react';
import TestModal from './TestModal';
// import TestEditor from './TestEditor';
import { Navigate } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
// Define the base URL using environment variables
const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;

// Define the endpoint
const endpoint = '/api/test/unpublished';

const UnpublishedTest = () => {

  const navigate = useNavigate();

  const [user_id, setUserId] = useState(0);

  useEffect(() => {
    // Check if access token is present in local storage
    const accessToken = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");
    // console.log("User ID in user view dashboard", userId);
    setUserId(userId);

    if (!userId || !accessToken) {
      // Redirect to login page if userId or accessToken is missing
      navigate("/login");
    }
  }, [navigate]);


  const [tests, setTests] = useState([]);

  useEffect(() => {
    const url = `${baseUrl}${endpoint}?user_id=${user_id}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setTests(data);
      })
      .catch((error) => {
        console.error("Error fetching unpublished tests:", error);
      });
  }, [user_id, tests]); // useEffect will re-run whenever userId changes
  
  // console.log(tests);

  const [redirect, setRedirect] = useState(false);
  const [index, setIndex] = useState(0);

  const handleTestClick = (testId) => {
    setIndex(testId);
    setRedirect(true);
    // Handle navigation or other actions based on testId
  };

  return (
    <div>
      {redirect
        ? (
          <Navigate to="/edittest" state={index} />
          // <TestEditor id={index} test={tests}/>
        ) : (
          <>
            {
              tests.length > 0 ? (
                <>
                  {tests.map((test, index) => (
                    test.status === 'unpublished' && (
                      <TestModal key={index} index={index} test={test} onClick={handleTestClick} />
                    )
                  ))}

                </>
              ) : (
                <p>No unpublished tests found.</p>
              )
            }
          </>
        )}


    </div>
  );
};

export default UnpublishedTest;
