import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const Payment = () => {
  const navigate = useNavigate();
  const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;
  const userId = localStorage.getItem("userId");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totals, setTotals] = useState({ paid: 0, unpaid: 0 });

  useEffect(() => {
    if (!userId) {
      setError("User ID is not available in localStorage.");
      setLoading(false);
      return;
    }

    const fetchUserQuestionCount = async () => {
      try {
        const response = await axios.get(`${baseUrl}/getuserquestioncount`, {
          params: { userid: userId },
        });
        setData(response.data);
        calculateTotals(response.data);
      } catch (err) {
        setError("Error fetching data from the API.");
      } finally {
        setLoading(false);
      }
    };

    const calculateTotals = (data) => {
      let paidTotal = 0;
      let unpaidTotal = 0;

      data.forEach((item) => {
        const amount = parseFloat(item.amount);
        if (item.has_payment) {
          paidTotal += amount;
        } else {
          unpaidTotal += amount;
        }
      });

      setTotals({ paid: paidTotal, unpaid: unpaidTotal });
    };

    fetchUserQuestionCount();
  }, [baseUrl, userId]);

const handlePaymentRequest = async () => {
  try {
    const response = await axios.post(`${baseUrl}/addNotification`, {
      userid: userId,
    });

    if (response.status === 200) {
      alert("Payment request submitted successfully.");
    } else {
      alert("Failed to submit payment request.");
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      alert(`Error: ${error.response.data.message}`);
    } else {
      alert("Error submitting payment request.");
    }
    console.error("Error submitting payment request:", error);
  }
};


  if (loading) {
    return (
      <div className="text-center mt-5">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="alert alert-danger mt-5">{error}</div>;
  }

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-between mb-4">
        <h1>User Question Counts</h1>
        
      </div>
      <h2>Totals</h2>
      <p>
        <strong>Total Paid Amount:</strong> {totals.paid.toFixed(2)}
      </p>
      <p>
        <strong>Total Unpaid Amount:</strong> {totals.unpaid.toFixed(2)}
        <button
          className="btn btn-primary ml-3"
          onClick={handlePaymentRequest}
          disabled={totals.unpaid === 0}
        >
          Request Payment
        </button>
      </p>
      <ul className="list-group mb-4">
        {data.map((item, index) => (
          <li
            key={index}
            className={`list-group-item d-flex justify-content-between align-items-center ${
              item.has_payment
                ? "list-group-item-success"
                : "list-group-item-warning"
            }`}
          >
            <div>
              <strong>User ID:</strong> {item.user_id} <br />
              <strong>Date:</strong>{" "}
              {new Date(item.question_date).toLocaleDateString()} <br />
              <strong>Question Count:</strong> {item.question_count} <br />
              <strong>Amount:</strong> {item.amount}
            </div>
            <span
              className={`badge ${
                item.has_payment ? "badge-success" : "badge-warning"
              } badge-pill`}
            >
              {item.has_payment ? "Paid" : "Unpaid"}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Payment;
