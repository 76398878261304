import React from 'react'
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import './../CreateTest.css'

const TestModal = ({ index, test, onClick }) => {

  const getPerfectDateTime = (datetime) => {
    const testDateTime = new Date(datetime); // Convert to UTC format
    return testDateTime.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
  };
  const handleClick = () => {
    onClick(test.id);
  };


  return (
    <div>
      {test.status === 'unpublished' &&
        <div className="test-modal" onClick={handleClick}>
          <Paper elevation={3} className="my-5 p-5" style={{ maxWidth: '640px', boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)' }}>
            <div>
              <Typography variant="body1">
                {index + 1}.{test.report_name}
              </Typography>
            </div>
            <div>
              <Typography variant="body2">
                {getPerfectDateTime(test.test_date)} {test.session_name}
              </Typography>
            </div>
            <div>
              <Typography variant="body2">
                Total Questions: {test.total_questions} | Duration: {test.time_in_min} min. | Marks: {test.total_questions * test.correct_answer_marks}
              </Typography>
            </div>
            <div>
              <Typography variant="body2">
                Staff Created: "Lecturer Name" at {getPerfectDateTime(test.created_date)}
              </Typography>
            </div>
          </Paper>
        </div>
      }
      {test.status === 'published' &&
        <div className="test-modal" onClick={handleClick}>
          <Paper elevation={3} className="my-5 p-5" style={{ maxWidth: '640px', boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)' }}>
            <div>
              <Typography variant="body1">
                {index + 1}.{test.report_name}
              </Typography>
            </div>
            <div>
              <Typography variant="body2">
                {getPerfectDateTime(test.test_date)} {test.session_name}
              </Typography>
            </div>
            <div>
              <Typography variant="body2">
                Total Questions: {test.total_questions} | Duration: {test.time_in_min} min. | Marks: {test.total_questions * test.correct_answer_marks}
              </Typography>
            </div>
            <div>
              <Typography variant="body2">
                Staff Created: "Lecturer Name" at {getPerfectDateTime(test.created_date)}
              </Typography>
            </div>
          </Paper>
        </div>
      }
      

    </div >
  )
}

export default TestModal

