import React, { useState } from 'react'
// import { Link } from 'react-router-dom'; 
import './CreateTest.css'
import Home from './Home/home'
import NewTest from './CreateTest/NewTest';

import UnpublishedTest from './CreateTest/UnpublishedTest';
import PublishedTest from './CreateTest/PublishedTest';
import TestMerger from './CreateTest/TestMerger';

const CreateTest = () => {
    
    const [showNewTest, setNewTest] = useState(true);
    const [showUnpublishedTest, setUnpublishedTest] = useState(false);
    const [showPublishedTest, setPublishedTest] = useState(false);
    const [showTestMerger, setTestMerger] = useState(false);

    const handleShowNewTest = () => {
        setNewTest(true);
        setPublishedTest(false);
        setUnpublishedTest(false);
        setTestMerger(false);
    }

    const handleShowUnpublishedTest = () => {
        setUnpublishedTest(true);
        setNewTest(false);
        setPublishedTest(false);
        setTestMerger(false);
    }

    const handleShowPublishedTest = () => {
        setPublishedTest(true);
        setNewTest(false);
        setUnpublishedTest(false);
        setTestMerger(false);
    }


    const handleShowTestMerger = () => {
        setTestMerger(true);
        setNewTest(false);
        setUnpublishedTest(false);
        setPublishedTest(false);
    }

    return (
        <div className="app-container">
            <Home />
            <div className="center-content">
                <div className="navbar">
                    <button className="link-style" onClick={handleShowNewTest}>New Test</button>
                    <button className="link-style" onClick={handleShowUnpublishedTest}>Unpublished Test</button>
                    <button className="link-style" onClick={handleShowPublishedTest}>Published Test</button>
                    <button className="link-style" onClick={handleShowTestMerger}>Test Merger</button>
                </div>
                <div className="content">
                    {showNewTest && <NewTest />}
                    {showUnpublishedTest && <UnpublishedTest />}
                    {showPublishedTest && <PublishedTest />}
                    {showTestMerger && <TestMerger />}
                </div>
            </div>
        </div>
    )
}

export default CreateTest
