import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Home from '../Home/home';

const AddChapterForm = () => {
  const [year, setYear] = useState('');
  const [subject, setSubject] = useState('');
  const [chapterName, setChapterName] = useState('');
  const [years, setYears] = useState([]);
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    // Fetch years from the backend API
    const fetchYears = async () => {
      try {
        const protocol = process.env.REACT_APP_API_PROTOCOL;
        const host = process.env.REACT_APP_API_HOST;
        const port = process.env.REACT_APP_API_PORT;
        const baseUrl = `${protocol}://${host}:${port}`;
        
        const response = await axios.get(`${baseUrl}/years`);
        setYears(response.data);
      } catch (error) {
        console.error('Error fetching years:', error);
      }
    };

    fetchYears();
  }, []);

  useEffect(() => {
    // Fetch subjects based on the selected year
    const fetchSubjects = async () => {
      if (year) {
        try {
          const protocol = process.env.REACT_APP_API_PROTOCOL;
          const host = process.env.REACT_APP_API_HOST;
          const port = process.env.REACT_APP_API_PORT;
          const baseUrl = `${protocol}://${host}:${port}`;

          const response = await axios.get(`${baseUrl}/subjects?yearId=${year}`);
          setSubjects(response.data);
        } catch (error) {
          console.error('Error fetching subjects:', error);
        }
      }
    };

    fetchSubjects();
  }, [year]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const protocol = process.env.REACT_APP_API_PROTOCOL;
      const host = process.env.REACT_APP_API_HOST;
      const port = process.env.REACT_APP_API_PORT;
      const baseUrl = `${protocol}://${host}:${port}`;

      await axios.post(`${baseUrl}/add-chapter`, { chapterName, subjectId: subject });
      setChapterName('');
      alert('Chapter added successfully!');
    } catch (error) {
      console.error('Error adding chapter:', error);
      alert('Error adding chapter. Please try again.');
    }
  };

  return (
    <>
    <Home/>
    <div className="container mt-5">
      <h2>Add Chapter</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="yearSelect" className="form-label">Year:</label>
          <select
            id="yearSelect"
            className="form-select"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            required
          >
            <option value="">Select Year</option>
            {years.map((year) => (
              <option key={year.year_id} value={year.year_id}>
                {year.year_name}
              </option>
            ))}
          </select>
        </div>
        {year && (
          <div className="mb-3">
            <label htmlFor="subjectSelect" className="form-label">Subject:</label>
            <select
              id="subjectSelect"
              className="form-select"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            >
              <option value="">Select Subject</option>
              {subjects.map((subject) => (
                <option key={subject.subject_id} value={subject.subject_id}>
                  {subject.subject_name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="mb-3">
          <label htmlFor="chapterName" className="form-label">Chapter Name:</label>
          <input
            type="text"
            id="chapterName"
            className="form-control"
            value={chapterName}
            onChange={(e) => setChapterName(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Add Chapter</button>
      </form>
    </div>
    </>
  );
};

export default AddChapterForm;
