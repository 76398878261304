import React from 'react'
import CollegeHeader from './CollegeHeader'

const CollegeDashboardHome = () => {
  return (
    <div>
      <CollegeHeader />
    </div>
  )
}

export default CollegeDashboardHome
