import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import { useLocation } from 'react-router-dom';
// import { useParams } from 'react-router-dom';
import PrintPreviewQuestionModal from './PrintPreviewQuestionModal';
import './TestPrintPreview.css';

const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;

const TestPrintPreview = ({ test_id }) => {

    // console.log(test_id);
    // const { test_id } = useParams();

    const [questions, setQuestions] = useState([]);
    const [printQueue, setPrintQueue] = useState([]);
    const [printQueueSubject, setPrintQueueSubject] = useState([]);
    const [testId, setTestId] = useState([]);
    const [testSubjectValues, setTestSubjectValues] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (!test_id) return; // Ensure that id is provided before making the request

            try {
                const response = await axios.get(`${baseUrl}/api/test/test_templete/${test_id}`);
                setTestId(response.data[0]);
                // console.log(response.data); // Access the response data
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
        };

        fetchData();
    }, [test_id]); // id is included in the dependency array

    useEffect(() => {

        const fetchQueue = async () => {
            try {
                const response = await axios.get(`${baseUrl}/fetchprintqueue/${testId.id}`);
                // console.log("QUEUE", response.data);
                // Extracting only question_id values
                const queueIds = response.data.map(item => item.question_id);
                setPrintQueue(queueIds);
                // console.log(queueIds);
                setPrintQueueSubject(response.data);
            } catch (error) {
                console.error('Error fetching queue:', error);
            }
        }
        if (testId)
            fetchQueue();
    }, [testId]);

    //   console.log(printQueue);


    const formatQuestions = (data) => {
        return data.map(item => ({
            questionId: item.id,

            preQuestionText: item.pre_question_text,
            postQuestionText: item.post_question_text.split('!'),

            question_image_url: item.question_image_url,

            option1Text: item.option1_text,
            option2Text: item.option2_text,
            option3Text: item.option3_text,
            option4Text: item.option4_text,

            correctOption: item.correct_option,

            option1_image_url: item.option1_image_url,
            option2_image_url: item.option2_image_url,
            option3_image_url: item.option3_image_url,
            option4_image_url: item.option4_image_url,

            preExplanationText: item.pre_explanation_text,
            postExplanationText: item.post_explanation_text,

            explanation_image_url: item.explanation_image_url
        }));
    };

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await axios.get(`${baseUrl}/printquestions/${testId.id}`);
                const formattedQuestions = formatQuestions(response.data);
                // console.log(formattedQuestions);
                setQuestions(formattedQuestions);
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
        };

        if (testId) {
            fetchQuestions();
        }
    }, [testId]);

    useEffect(() => {
        const fetchData = async () => {
            if (!testId) return; // Ensure that id is provided before making the request
            try {
                const response = await axios.get(`${baseUrl}/test_templete_questions/${testId.id}`);
                setTestSubjectValues(response.data.results);
                // console.log(response.data.results); // Access the response data
            } catch (error) {
                console.error('Error fetching Templete:', error);
            }
        };
        if (testId)
            fetchData();
    }, [testId]);


    const [selectedOption, setSelectedOption] = useState('question');
    const [showAnswer, setShowAnswer] = useState(false);
    const [displayLines, setDisplayLines] = useState(1);
    const [prefixes, setPrefixes] = useState(["(a)", "(b)", "(c)", "(d)"]);
    const [optionPrefix, setOptionPrefix] = useState("a");
    const [fontSize, setFontSize] = useState(12);
    const [displayColumns, setDisplayColumns] = useState(1);
    const [fontFamily, setFontFamily] = useState('Arial, sans-serif');
    const [questionSpace, setQuestionSpace] = useState('1');
    const [indexChange, setIndexChange] = useState(1);

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
        setShowAnswer(e.target.value === 'questionoptionWithanswer');
    };

    const handleDisplayLinesChange = (e) => {
        setDisplayLines(parseInt(e.target.value));
    };

    const handleOptionPrefixChange = (e) => {
        setOptionPrefix(e.target.value);
    };

    const handleFontSizeChange = (e) => {
        setFontSize(parseInt(e.target.value, 10));
    };

    const handleDisplayColumnsChange = (numColumns) => {
        setDisplayColumns(numColumns);
    };

    const handleFontChange = (e) => {
        setFontFamily(e.target.value);
    };

    const handleQuestionSpace = (e) => {
        setQuestionSpace(e.target.value);
    };

    const handleIndexChange = (e) => {
        const newValue = parseInt(e.target.value, 10);
        setIndexChange(isNaN(newValue) ? '' : newValue);
    };

    useEffect(() => {
        // Update prefixes based on optionPrefix
        switch (optionPrefix) {
            case "a":
                setPrefixes(["(a).", "(b).", "(c).", "(d)."]);
                break;
            case "A":
                setPrefixes(["(A).", "(B).", "(C).", "(D)."]);
                break;
            case "1":
                setPrefixes(["(1).", "(2).", "(3).", "(4)."]);
                break;
            case "i":
                setPrefixes(["(i).", "(ii).", "(iii).", "(iv)."]);
                break;
            default:
                break;
        }
    }, [optionPrefix]);

    const [printing, setPrinting] = useState(false);

    const handlePrint = () => {
        setPrinting(true);
        setTimeout(() => {
            window.print();
            setPrinting(false);
        }, 500); // Delay printing to allow rendering changes
    };

    const [subjectName, setSubjectName] = useState([]);

    useEffect(() => {
        const fetchSubjectName = async () => {
            try {
                const response = await axios.get(`${baseUrl}/testsubjectname/${test_id}`);
                // console.log("NAME", response.data.results);
                setSubjectName(response.data.results); // Adjust according to your response structure

            } catch (error) {
                // console.error('Error fetching subject name:', error);
            }
        }
        fetchSubjectName();
    }, [test_id]);

    return (
        <div>
            <div className="print-preview-container">

                <div className="column-left hide-on-print">
                    <select value={selectedOption} onChange={handleOptionChange}>
                        <option value="question">Questions</option>
                        <option value="questionoptionWithanswer">Questions with Answer</option>
                        <option value="questionoptionsanswerexplanation">Question, Answer and Solution</option>
                        <option value="onlyanswerandexplanation">Only Answer and Solution</option>
                        <option value="onlyanswer">Only Answer</option>
                        <option value="onlysolution">Only Solution</option>
                        <option value="onlyquestion">Only Question, No Answer</option>
                    </select>

                    <select value={displayLines} onChange={handleDisplayLinesChange}>
                        <option value={1}>Display in 1 Line</option>
                        <option value={2}>Display in 2 Lines</option>
                        <option value={4}>Display in 4 Lines</option>
                    </select>

                    <select value={optionPrefix} onChange={handleOptionPrefixChange}>
                        <option value="a">Option Prefix: (a)</option>
                        <option value="A">Option Prefix: (A)</option>
                        <option value="1">Option Prefix: (1)</option>
                        <option value="i">Option Prefix: (i)</option>
                    </select>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <select value={fontFamily} onChange={handleFontChange}>
                            <option value="Arial, sans-serif">Arial</option>
                            <option value="Times New Roman, serif">Times New Roman</option>
                            <option value="Verdana, sans-serif">Verdana</option>
                            <option value="Courier New, monospace">Courier New</option>
                        </select>
                        <select value={fontSize} onChange={handleFontSizeChange}>
                            {[8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((size) => (
                                <option key={size} value={size}>{size}px</option>
                            ))}
                        </select>
                    </div>

                    <select value={displayColumns} onChange={(e) => handleDisplayColumnsChange(parseInt(e.target.value))}>
                        <option value={1}>Single Column</option>
                        <option value={2}>Two Columns</option>
                    </select>

                    <select value={questionSpace} onChange={handleQuestionSpace}>
                        <option value="1">Default</option>
                        <option value="5">5px</option>
                        <option value="10">10px</option>
                        <option value="15">15px</option>
                    </select>

                    <input type="text" value={indexChange} onChange={handleIndexChange} placeholder="Index Start" />

                    <button onClick={handlePrint}>Print</button>
                    {printing && <div className="printing-overlay">Printing...</div>}
                </div>

                <div className="column-right" style={{ maxWidth: "210mm" }}>
                    <div style={{ backgroundColor: "white", paddingTop: "5px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "5px" }}>
                        <div style={{ fontSize: `${fontSize}px`, fontFamily: fontFamily }}>
                            <div>
                                <h1 align="center">NAME OF THE COLLEGE</h1>
                                <p align="center"><b>{testId.session_name}</b></p>
                                <p align="center">{subjectName.map((s, i) => <>{s.subject_name}{i < subjectName.length - 1 && ', '} </>)}</p>
                                <div class="flex-container">
                                    <div><b>Time:{testId.time_in_min}</b></div>
                                    <div><b>Total Questions: {printQueue.length}</b></div>
                                    {/* <div><b>NegetiveMarks:{testId.wrong_answer_marks}</b></div> */}
                                    <div><b>Marks:{printQueue.length * testId.correct_answer_marks}</b></div>
                                </div>
                                <div><hr /></div>
                            </div>
                            <div>
                                <PrintPreviewQuestionModal
                                    indexChange={indexChange}
                                    displayColumns={displayColumns}
                                    questionSpace={questionSpace}
                                    printQueue={printQueue}
                                    questions={questions}
                                    optionPrefix={optionPrefix}
                                    prefixes={prefixes}
                                    fontSize={fontSize}
                                    displayLines={displayLines}
                                    selectedOption={selectedOption}
                                    showAnswer={showAnswer}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestPrintPreview
