import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ViewModel from "../ViewModel";
import { useNavigate } from "react-router-dom";

import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ToastContainer, Slide } from 'react-toastify';
import ReportBtn from '../Buttons/ReportBtn';


const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;

const ViewQuestions = () => {
  const navigate = useNavigate();

  const [user_id, setUserId] = useState(0);
  // eslint-disable-next-line
 const [userType, setUserType] = useState(""); 
 
  useEffect(() => {
    // Check if access token is present in local storage
    const accessToken = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");
    const storedUserId = localStorage.getItem("userId");
    const storedUserType = localStorage.getItem("userType");
    console.log("User ID in user view dashboard", userId);
    setUserId(userId);

    if (!storedUserId || !accessToken) {
      // Redirect to login page if userId or accessToken is missing
      navigate("/");
      console.log("User not logged in");
    } else {
      setUserId(storedUserId);
      setUserType(storedUserType);

      if (storedUserType !== "user") {
        navigate("/");
      }
    }
  }, [navigate]);

  const [delayedIndex, setDelayedIndex] = useState(0);

  // useEffect(() => {
  //     // Add event listener to disable context menu
  //     document.addEventListener('contextmenu', blockContextMenu);

  //     return () => {
  //         // Clean up the event listener when component unmounts
  //         document.removeEventListener('contextmenu', blockContextMenu);
  //     };
  // }, []);

  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const userId = localStorage.getItem("userId");
      console.log(userId)
      try {
        const response = await axios.get(
          `${baseUrl}/userquestions?userId=${userId}`
        );
        const formattedQuestions = formatQuestions(response.data);
        setQuestions(formattedQuestions);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchData();
  }, []);

  const formatQuestions = (data) => {
    return data.map(item => ({
      questionId: item.id,

      preQuestionText: item.pre_question_text,
      postQuestionText: item.post_question_text.split('!'),

      option1Text: item.option1_text,
      option2Text: item.option2_text,
      option3Text: item.option3_text,
      option4Text: item.option4_text,

      correctOption: item.correct_option,

      preExplanationText: item.pre_explanation_text,
      postExplanationText: item.post_explanation_text,

      question_image_url: item.question_image_url,
      option1_image_url: item.option1_image_url,
      option2_image_url: item.option2_image_url,
      option3_image_url: item.option3_image_url,
      option4_image_url: item.option4_image_url,
      explanation_image_url: item.explanation_image_url
    }));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (delayedIndex < questions.length) {
        setDelayedIndex(delayedIndex + 1);
      }
    }, 200); // Delay of 1 second (1000 milliseconds)

    return () => clearTimeout(timer); // Clear the timer when component unmounts or index changes
  }, [delayedIndex, questions]);

  // const blockContextMenu = (event) => {
  //     event.preventDefault(); // Prevent default context menu behavior
  // }

  return (
    <>
      <div className='cardAdditional'>
        <div>
          {questions.map((question, index) => (
            delayedIndex >= index && (
              <div key={question.questionId} className="">
                  <div>
                            <div>
                              <div>
                                <ReportBtn question={question} user_id={user_id} />
                              </div>
                            </div>
                        <ViewModel question={question} index={index} />
                  </div>
              </div>
            ) // delay the rendering of the question until the index is greater than or equal to the delayedIndex
          )
          )}
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Slide}
      />
    </>
  );
};

export default ViewQuestions;
