import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Navigate, useParams } from "react-router-dom";
import { Form } from 'react-bootstrap';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { ToastContainer, toast, Slide } from 'react-toastify'

import UnpublishedHome from './Unpublished/UnpublishedHome';
import PublishedHome from './Published/PublishedHome';

const animatedComponents = makeAnimated();
const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;
const endpoint = "/testupdate";
const endpointyear = "/years";
const testendpoint = '/api/test/';

const TestSetting = () => {
  // eslint-disable-next-line
  const { user_id, test_id } = useParams(); // Destructure the params

  const navigate = useNavigate();

  const [testId, setTestId] = useState([]);
  const [testSubject, setTestSubject] = useState([]);
  // Define state variables
  const [testType, setTestType] = useState("1");
  const [testMode, setTestMode] = useState("2");
  const [sessionName, setSessionName] = useState("");
  const [pdfReportName, setPdfReportName] = useState("");
  const [sessionDesc, setSessionDesc] = useState("");

  const [testPattern, setTestPattern] = useState("1");
  const [correctAnswerMarks, setCorrectAnswerMarks] = useState(1);
  const [wrongAnswerMarks, setWrongAnswerMarks] = useState(0);

  const [testDate, setTestDate] = useState("");
  const [timeInMin, setTimeInMin] = useState("");
  const [totalQuestions, setTotalQuestions] = useState("");

  const [testStatus, setTestStatus] = useState("");

  const [year, setYear] = useState("");
  const [subject, setSubject] = useState([]);

  const [years, setYears] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const [prevSubjects, setPrevSubjects] = useState([]);

  const [isMultiSelect, setIsMultiSelect] = useState(false);
  const [fillMarks, setFillMarks] = useState(false);

  const [routeTo, setRouteTo] = useState("");


  useEffect(() => {
    const fetchData = async () => {
      if (!test_id) return; // Ensure that id is provided before making the request

      try {
        const response = await axios.get(`${baseUrl}${testendpoint}test_templete/${test_id}`);
        setTestId(response.data[0]);
        // console.log(response.data); // Access the response data
      } catch (error) {
        console.error('Error fetching test_templete data:', error);
      }
    };

    fetchData();
  }, [test_id]); // id is included in the dependency array

  useEffect(() => {
    const fetchData = async () => {
      if (!testId) return; // Ensure that id is provided before making the request

      try {
        const response = await axios.get(`${baseUrl}${testendpoint}test_templete_subjects/${testId.id}`);
        setTestSubject(response.data);
        // console.log(response.data); // Access the response data
      } catch (error) {
        console.error('Error fetching test_templete data:', error);
      }
    };

    fetchData();

  }, [testId]);

  useEffect(() => {
    const fetchYears = async () => {
      try {
        const response = await axios.get(`${baseUrl}${endpointyear}`);
        setYears(response.data);
      } catch (error) {
        console.error("Error fetching years:", error);
      }
    };

    fetchYears();
  }, []);

  const endpoint1 = "/subjects";

  const fetchSubjects = async (yearId) => {
    try {
      const url = `${baseUrl}${endpoint1}?yearId=${yearId}`;
      const response = await axios.get(url);
      // console.log(response.data);
      setSubjects(response.data);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  // Convert date and time to local date-time string for input[type="datetime-local"]
  const formatDateTimeForInput = (dateStr) => {
    const date = new Date(dateStr);
    if (isNaN(date)) return "";
    const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    return localDate.toISOString().slice(0, 16);
  };

  const subjectsOptions = subjects.map(subject => ({
    value: subject.subject_id,
    label: subject.subject_name,
    totalQuestions: ''
  }));


  // Set initial values based on testId when it changes
  useEffect(() => {
    if (testId) {
      // console.log(testId);
      setSubject(subjectsOptions || [])
      setTestType(testId.test_type || "1");
      setTestMode(testId.test_mode || "2");
      setSessionName(testId.session_name || "");
      setPdfReportName(testId.report_name || "");
      setSessionDesc(testId.session_desc || "");

      setTestPattern(testId.test_pattern || "1");
      setCorrectAnswerMarks(testId.correct_answer_marks || 0);
      setWrongAnswerMarks(testId.wrong_answer_marks || 0);

      setTestDate(formatDateTimeForInput(testId.test_date));
      setTimeInMin(testId.time_in_min || "");
      setTotalQuestions(testId.total_questions || "");

      setTestStatus(testId.status || "");
      setYear(testId.year_id || "");
    }
    // console.log(testSubject);
    if (testSubject.length > 1) {
      fetchSubjects(testSubject[0].year_id);
      setIsMultiSelect(true);

      const areTotalQuestionsSame = testSubject.every(sub => sub.total_questions === testSubject[0].total_questions);
      setFillMarks(areTotalQuestionsSame);

      setSubject(prevSelectedSubjects =>
        prevSelectedSubjects.map(subject => {
          const matchingTestSubject = testSubject.find(testSub => testSub.subject_id === subject.value);
          if (matchingTestSubject) {
            return { ...subject, totalQuestions: parseInt(matchingTestSubject.total_questions) };
          }
          return subject;
        })
      );

      setPrevSubjects(subject);
    }

    // eslint-disable-next-line
  }, [year, testId, testSubject]);

  const handleYearChange = (e) => {
    const selectedYear = e.target.value;
    setYear(selectedYear);
    setSubject([]);
    setIsMultiSelect(false)
    fetchSubjects(selectedYear);
  };

  const handleSingleSubjectSelection = (subject) => {
    setSubject([subject]); // Update state with the selected subject
  };

  const handleMultiSubjectSelection = (subject) => {
    setSubject(subject);
  };

  const handleTotalQuestionsChange = (subjectId, totalQuestions) => {
    setSubject(prevSelectedSubjects =>
      prevSelectedSubjects.map(subject =>
        subject.value === subjectId
          ? { ...subject, totalQuestions: totalQuestions !== '' ? parseInt(totalQuestions) : '' }
          : subject
      )
    );
  };


  const toggleSelectionMode = () => {
    setIsMultiSelect(prevMode => !prevMode);
    setSubject([]); // Reset subject selection when toggling mode

  };

  const handleFillMarks = () => {
    if (!subject || subject.length === 0) {
      return;
    }

    const firstSubjectTotalQuestions = subject[0].totalQuestions;

    const updatedSubjects = subject.map(subject => ({
      ...subject,
      totalQuestions: firstSubjectTotalQuestions
    }));

    setSubject(updatedSubjects);
  };

  const toggleFillMarks = () => {
    setFillMarks(prevMode => !prevMode);
    if (!fillMarks) {
      handleFillMarks();
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${baseUrl}${endpoint}`, {
        testId: testId.id,
        testType,
        testMode,
        sessionName,
        pdfReportName,
        sessionDesc,
        testPattern,
        correctAnswerMarks,
        wrongAnswerMarks,
        testDate,
        timeInMin,
        totalQuestions,
        year,
        subject,
        prevSubjects,
        subjectsOptions,
        userId: testId.user_id,
      });

      if (response.status === 201) { // Assuming a successful update returns status 200
        toast.success(response.data.message);
        // console.log("RESPONSE:", response);
        // window.location.reload();
      } else {
        toast.error("Error updating test session");
        console.error("Error response:", response);
      }
    } catch (error) {
      toast.error("Error submitting form");
      console.error("Submission error:", error);
    }

  };

  const handleTestDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this test session?")) {
      // `${baseUrl}/test/testdelte/${id}`
      try {
        const response = await axios.post(`${baseUrl}/test/testdelete/${id}`);

        // Check if response code is 200
        if (response.status === 200) {
          // Display success alert
          toast.success(response.data.message);
          setTimeout(() => {
            setRouteTo("deleted");
          }, 2000); // 2-second delay
        } else {
          // Display error alert
          toast.warn('Error submitting test from server');
        }
      } catch (error) {
        console.error('Error submitting test:', error);
      }
    }
  }

  const handleUnpublishTest = async (id) => {
    if (window.confirm("Are you sure you want to Unpublish this test session?")) {
      //  `${baseUrl}$/test/testunpublish/${id}`
      try {
        const response = await axios.post(`${baseUrl}/test/testunpublish/${id}`);
        // console.log(response.data);

        // Check if response code is 200
        if (response.status === 200) {
          // Display success alert
          toast.success(response.data.message);
          setTimeout(() => {
            setRouteTo("unpublish");
          }, 2000); // 2-second delay
        } else {
          // Display error alert
          toast.error('Error unpublishing test from server');
        }
      } catch (error) {
        console.error('Error unpublishing test:', error);
      }
    }
  }
  // if (routeTo === "deleted") {
  //   // return <Navigate to="/collegedashboard" replace={true} />;
  //   navigate('/collegedashboard');
  // }

  // if (routeTo === "unpublish") {
  //   return <Navigate to={`/unpublishedcollegetest/${user_id}`} state={{ user_id }} replace={true} />;
  // }

  useEffect(() => {
    if (routeTo === "deleted") {
      navigate('/collegedashboard');
    }
  }, [routeTo, navigate, user_id]);

  return (
    <div>
      {routeTo === "unpublish" && (
        <Navigate to={`/unpublishedcollegetest/${user_id}`} state={user_id} replace={true}/>
      )}
      {testId.status === 'unpublished' && <UnpublishedHome />}
      {testId.status === 'published' && <PublishedHome />}
      <div>
        Settings {testId.session_name}
        <div>
          <form>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="testtype">
                    Select Test Session type:<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    id="testtype"
                    value={testType}
                    onChange={(e) => setTestType(e.target.value)}
                    required
                    disabled
                  >
                    <option value="1">1. Standard Test</option>
                    {/* <option value="2">2. Previous Year & Model Papers</option> */}
                    <option value="2">2. Hybrid Test</option>
                  </select>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="testmode">
                    Select Mode of Test:<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    id="testmode"
                    value={testMode}
                    onChange={(e) => setTestMode(e.target.value)}
                    required
                    disabled
                  >
                    <option value="1">1. Online</option>
                    <option value="2">2. Offline</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="sessionname">
                    Session Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="sessionname"
                    placeholder="Enter session name"
                    value={sessionName}
                    onChange={(e) => setSessionName(e.target.value)}
                    required
                    disabled={testStatus === "published"}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="filename">
                    PDF Report Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="filename"
                    placeholder="Enter PDF report name"
                    value={pdfReportName}
                    onChange={(e) => setPdfReportName(e.target.value)}
                    required
                    disabled={testStatus === "published"}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="sessiondesc">
                    Session Description<span style={{ color: "red" }}>*</span>
                  </label>
                  <textarea
                    className="form-control"
                    id="sessiondesc"
                    placeholder="Enter session description"
                    rows={2}
                    maxLength={300}
                    value={sessionDesc}
                    onChange={(e) => setSessionDesc(e.target.value)}
                    required
                    disabled={testStatus === "published"}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="testtype">
                    Marks Pattern<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    id="testpattern"
                    value={testPattern}
                    onChange={(e) => setTestPattern(e.target.value)}
                    style={{ width: '100%' }}
                    required
                    disabled
                  // disabled={testType === '1'}
                  >
                    <option value="1">1. Custom Create</option>
                    <option value="2">2. Previous Year & Model Papers</option>
                    <option value="3">3. Assignments/Home works</option>
                  </select>
                </div>
              </div>

              {/* <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="totalquestions">
                  Total Questions<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="totalquestions"
                  placeholder="Enter total questions"
                  value={totalQuestions}
                  onChange={(e) => setTotalQuestions(e.target.value)}
                  required
                  disabled={testStatus === "published"}
                />
              </div>
            </div> */}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="totalquestions">
                    Marks for correct answer<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="questioncorrentmarks"
                    placeholder="1 or 2 or 4...."
                    value={correctAnswerMarks}
                    onChange={(e) => setCorrectAnswerMarks(e.target.value)}
                    required
                    disabled={testStatus === "published"}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="testtype">
                    Marks for Wrong answer<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    id="wronganswermarks"
                    value={wrongAnswerMarks}
                    onChange={(e) => setWrongAnswerMarks(e.target.value)}
                    required
                    disabled={testStatus === "published"}
                  >
                    <option value="0">No Negative Marking</option>
                    <option value="-1">-1</option>
                    <option value="-2">-2</option>
                    <option value="-3">-3</option>
                    <option value="-4">-4</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="timeinmin">
                    Time in Minutes<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="timeinmin"
                    placeholder="Enter time in minutes"
                    value={timeInMin}
                    onChange={(e) => setTimeInMin(e.target.value)}
                    disabled={testStatus === "published"}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="testdate">
                    Test Date<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    id="testdate"
                    value={testDate}
                    onChange={(e) => setTestDate(e.target.value)}
                    disabled={testStatus === "published"}
                    required
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="year">
                      Select Year:<span style={{ color: 'red' }}>*</span>
                    </label>
                    <select
                      className="form-select full-width-select"
                      id="year"
                      value={year}
                      onChange={handleYearChange}
                      style={{ width: '100%' }}
                      required
                      disabled={testStatus === "published" || testStatus === "unpublished"}
                    >
                      <option value="">Select Year</option>
                      {years.map((year) => (
                        <option key={year.year_id} value={year.year_id}>
                          {year.year_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div>
                <Form.Check
                  type="switch"
                  label={isMultiSelect ? 'Multiple Subject Selection' : 'Single Subject Selection'}
                  checked={isMultiSelect}
                  onChange={toggleSelectionMode}
                  className="link-style"
                  disabled={testStatus === "published" || testStatus === "unpublished"}
                />
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="subject">
                      Select Subject:<span style={{ color: 'red' }}>*</span>
                    </label>
                    <Select
                      options={subjectsOptions}
                      value={subject}
                      onChange={isMultiSelect ? handleMultiSubjectSelection : handleSingleSubjectSelection}
                      closeMenuOnSelect={!isMultiSelect} // Close the menu on select if it's not multi-select
                      components={animatedComponents}
                      isMulti={isMultiSelect}
                      isDisabled={!year || testStatus === "published"}
                      style={{ width: '100%' }}
                    />
                  </div>
                </div>
              </div>
              {(subject.length > 0) && (
                <div className="col">
                  <div>
                    <Form.Check
                      type="switch"
                      label={fillMarks ? 'Same Marks' : 'Different Marks'}
                      checked={fillMarks}
                      onChange={toggleFillMarks}
                      className="link-style"
                      style={{ display: isMultiSelect ? 'block' : 'none' }}
                      disabled={testStatus === "published"}
                    />

                  </div>
                  {subject.map(sub => (
                    <div key={sub.value} className="col-md-12">
                      <div className="form-group">
                        <label htmlFor={`totalQuestions_${sub.value}`}>
                          Total Questions for {sub.label}:
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id={`totalQuestions_${sub.value}`}
                          value={sub.totalQuestions}
                          onChange={(e) => handleTotalQuestionsChange(sub.value, e.target.value)}
                          placeholder="Enter Total No. Questions"
                          disabled={testStatus === "published"}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}

            </div>

            {testStatus !== 'published' && (
              <>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={handleSubmit}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() => handleTestDelete(testId.id)}
                      >
                        Delete Test
                      </button>
                    </div>
                  </div>
                </div>
              </>

            )}

            {(testStatus === 'published') && (
              <>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() => handleUnpublishTest(testId.id)}
                      >
                        Unpublish Test
                      </button>
                    </div>
                  </div>
                </div>

              </>
            )}

          </form>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Slide}
      />
    </div>
  )
}

export default TestSetting
