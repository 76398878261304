// Import necessary libraries
import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Define your API base URL
const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;

// Define your component
const QuestionCounts = ({ userId }) => {
  // State to hold question counts
  const [counts, setCounts] = useState({});



  // Fetch question counts on component mount
  useEffect(() => {
    if (userId) {
      // Only fetch question counts if userId is present
      // Function to fetch question counts
      const fetchQuestionCounts = async () => {
        try {
          console.log("userId", userId);
          const response = await axios.post(`${baseUrl}/question-counts`, {
            user_id: userId,
          });
          console.log(response.data);
          setCounts(response.data);
        } catch (error) {
          console.error("Error fetching question counts:", error);
          // Handle error
        }
      };

      fetchQuestionCounts();
    }
  }, [userId, baseUrl]);
  return (
    <div>
      <h2>Question Counts</h2>
      <ul>
        <li>Today: {counts.today_question_count}</li>
        <li>Yesterday: {counts.yesterday_question_count}</li>
        <li>Last 7 Days: {counts.last_7_days_question_count}</li>
        <li>This Month: {counts.this_month_question_count}</li>
        <li>Last 30 Days: {counts.last_30_days_question_count}</li>
        <li>Previous Month: {counts.previous_month_question_count}</li>
        <li>Pending: {counts.pending_question_count}</li>
        <li>Reviewed: {counts.reviewed_question_count}</li>
        <li>Incorrect: {counts.incorrect_question_count}</li>
      </ul>
    </div>
  );
};

export default QuestionCounts;
