import NoteAddIcon from '@mui/icons-material/NoteAdd';
import store from '../store';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import GradingIcon from '@mui/icons-material/Grading';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import TopicIcon from '@mui/icons-material/Topic';
import MenuBookIcon from '@mui/icons-material/MenuBook';

export const reviewUserNavBar = () =>{
    return [ 
        {
            name : 'Review Exam Related Questions',
            link : `/reviewexamrelatedquestions/${getUserId()}`,
            id:'Review Exam Related Questions',
            class : getSelectedNav() =="Review Exam Related Questions"? 'navSelected': 'navs',
            icon : <MenuBookIcon/>
        },
        {
            name : 'Review Dificulty Questions',
            link : `/reviewdificultyquestions/${getUserId()}`,
            id:'Review Dificulty Questions',
            class : getSelectedNav() =="Review Dificulty Questions"? 'navSelected': 'navs',
            icon : <NoteAddIcon/>
        },
        {
            name : 'Review Topic Related Questions',
            link : `/reviewtopicrelatedquestions/${getUserId()}`,
            id:'Review Topic Related Questions',
            class : getSelectedNav() =="Review Topic Related Questions"? 'navSelected': 'navs',
            icon : <TopicIcon/>
        },
        {
            name : 'Review Questions',
            link : `/reviewquestions/${getUserId()}`,
            id:'Review Questions',
            class : getSelectedNav() =="Review Questions"? 'navSelected': 'navs',
            icon : <RemoveRedEyeIcon/>
        },

        {
            name : 'Review User Questions',
            link : `/reviewuserquestions/${getUserId()}`,
            id:'Review User Questions',
            class : getSelectedNav() =="Review User Questions"? 'navSelected': 'navs',
            icon : <GradingIcon/>
        },
        {
            name : 'User Questions Counts',
            link : '/viewusersquestioncount',
            id:'User Questions Counts',
            class : getSelectedNav() =="User Questions Counts"? 'navSelected': 'navs',
            icon : <HelpOutlineIcon/>
        },
        
    ]
     
    
  }

  export const getStateData = () =>{
    return store.getState()
  }

  export const getSelectedNav = () =>{
    return getStateData()?.overlookSlice?.selectedNav
  }

  export const getUserType = () =>{
    return getStateData()?.overlookSlice?.userData?.userType
  }

  export const getUserId = () =>{
    return getStateData()?.overlookSlice?.userData?.userId
  }

