import React, { useEffect, useState } from 'react'
import "./sideNavbar.css";
import { useNavigate, Link } from 'react-router-dom';
import { getStateData, getUserType, reviewUserNavBar } from './util';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MenuIcon from '@mui/icons-material/Menu';
import MessageIcon from '@mui/icons-material/Message';
import Person4Icon from '@mui/icons-material/Person4';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import SchoolIcon from '@mui/icons-material/School';
import { useDispatch, useSelector } from 'react-redux';
import { selectNav } from '../slices/overlookSlice';

function SideNavBar() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [mobileVew, setMobileView] = useState(false)
    const selectedNav = useSelector(state => state.overlookSlice.selectedNav)
    const userData = useSelector(state => state.overlookSlice.userData)
    const [selected, setSelected] = useState(selectedNav)
    const [usertype,setSelectedUserType] =useState(undefined)

    useEffect(()=>{
        setSelected(selectedNav)
    },[selectedNav])

    useEffect(()=>{
      setSelectedUserType(userData?.userType)
    },[userData])

    useEffect(()=>{
      if(usertype == "reviewer"){
        dispatch(selectNav('Review Exam Related Questions'))
      }
    },[usertype])

    console.log("selectedNav",selectedNav)
    console.log("getdata123", getStateData())
    const call =()=>{
        navigate('/upload')
    }

    const handleLogout = () => {
      localStorage.removeItem("userId");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userType");
      window.location.href = "/"
  };

  console.log("reviewUserNavBar",reviewUserNavBar(),reviewUserNavBar()?.length)
  return (
    <>
   <div>
     <div className="mobileNav">
       <span onClick={() => setMobileView(!mobileVew)}><MenuIcon/></span> 
    </div>
    <div className={mobileVew ? 'mobileSideBar' : 'sidenavbar'}>
        <div className="navlogo">
            <p className='logoHeading'><img className='navbar-logo' src="/logo.png" alt="logo" /> <div>Exam Tech</div></p>
        </div>
      <div className="pagesText">Pages</div>

       {getUserType() == "user" &&<div>
        <div onClick={()=>{dispatch(selectNav('dashboard'));navigate('/UserDashBoard')}}  className={selected == "dashboard"? 'navSelected': 'navs'}>
         <span className='navIcons'><DashboardCustomizeIcon/></span> DashBoard
        </div>
        <div onClick={()=>{dispatch(selectNav('upload')); navigate('/upload')}}  className={selected == "upload"? 'navSelected': 'navs'}>
          <span className='navIcons'><CloudUploadIcon/></span>  Upload
        </div>
        <div onClick={()=>{dispatch(selectNav('view')); navigate('/view')}}  className={selected == "view"? 'navSelected': 'navs'}>
          <span className='navIcons'><RemoveRedEyeIcon/></span> View
        </div>

        <div onClick={()=>{dispatch(selectNav('Messages'));navigate("/UserPayment");}} className={selected == "Messages"? 'navSelected': 'navs'}>
          <span className='navIcons'><MessageIcon/></span>  Payout
        </div>

        <div onClick={()=>{dispatch(selectNav('Notifications'))}} className={selected == "Notifications"? 'navSelected': 'navs'}>
          <span className='navIcons'><NotificationsActiveIcon/></span> Notifications (0)
        </div>

        <div onClick={()=>{handleLogout()}} className={"logoutMobile"}>
          <span className='navIcons'><NotificationsActiveIcon/></span> Logout
        </div>
        </div>}

        </div>
        </div>
    

       {getUserType()== 'reviewer' && reviewUserNavBar() && reviewUserNavBar()?.length>0 && reviewUserNavBar()?.map((nav)=>{
        return <div onClick={()=>{dispatch(selectNav(nav.id));navigate(nav?.link)}} className={nav.class}>
        <span className='navIcons'>{nav.icon}</span>{nav?.name}
      </div>

       })}
    
   
    </>

  )
}

export default SideNavBar