import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
import "../Component.css"

const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;

const ReportBtn = ({ question, user_id }) => {
    const [textareaValues, setTextareaValues] = useState({});
    const [subjectValues, setSubjectValues] = useState({});
    const [showModal, setShowModal] = useState(false);

    const handleTextareaChange = (questionId, value) => {
        setTextareaValues((prevValues) => ({
            ...prevValues,
            [questionId]: value,
        }));
    };

    const handleSubjectChange = (questionId, value) => {
        setSubjectValues((prevValues) => ({
            ...prevValues,
            [questionId]: value,
        }));
    };

    const handleSubmit = async (questionId) => {
        const textareaValue = textareaValues[questionId] || '';
        const subjectValue = subjectValues[questionId] || '';
        if (subjectValue === '') {
            toast.warn('Please enter a subject');
            return;
        }
        if (textareaValue === '') {
            toast.warn('Please enter an issue');
            return;
        }
        try {
            const response = await axios.post(`${baseUrl}/report`, {
                questionId: questionId,
                userId: user_id,
                subject: subjectValue,
                issue: textareaValue,
            });

            if (response.status === 201) {
                toast.success('Report submitted successfully');
                setShowModal(false); // Close the modal
            } else {
                toast.error('Error Reporting');
            }
        } catch (error) {
            toast.error('Error Submitting');
        }
    };

    return (
        <div>
            <Button
                type="button"
                onClick={() => setShowModal(true)}
                className='reportButton'
            >
                <i className="bi bi-exclamation-triangle-fill" style={{ marginRight: '5px', fontSize: '16px' }}></i>
                Report
            </Button>
            <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Report Issue</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div >
                        Subject:
                        <input
                            type="text"
                            placeholder='Subject'
                            value={subjectValues[question.questionId] || ''}
                            onChange={(e) => handleSubjectChange(question.questionId, e.target.value)}
                        />
                    </div>
                    Issue:
                    <div>
                        <textarea
                            placeholder="Explain the Issue"
                            value={textareaValues[question.questionId] || ''}
                            onChange={(e) => handleTextareaChange(question.questionId, e.target.value)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => handleSubmit(question.questionId)}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ReportBtn;
