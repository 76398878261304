import React from 'react';
import AdminHeader from './AdminHeader';

const AdminDashBoard = () => {
  return (
    <div className="d-flex flex-column align-items-center">
      <AdminHeader />
    </div>
  )
}

export default AdminDashBoard
