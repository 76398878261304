import React, { useState, useEffect} from 'react';
import CollegeHeader from '../CollegeHeader';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import TestModal from './TestModal'

// Define the base URL using environment variables
const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;

// Define the endpoint
const endpoint = '/api/test/unpublished';

const UnpublishedCollegeTest = () => {
  const { user_id } = useParams();

  const navigate = useNavigate();
  // eslint-disable-next-line
  const [userid, setUserId] = useState(0);

  useEffect(() => {
    // Check if access token is present in local storage
    const accessToken = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");
    // console.log("User ID in user view dashboard", userId);
    setUserId(userId);

    if (!userId || !accessToken) {
      // Redirect to login page if userId or accessToken is missing
      navigate("/login");
    }
  }, [navigate]);

  const [tests, setTests] = useState([]);
  
  useEffect(() => {
    const url = `${baseUrl}${endpoint}?user_id=${user_id}`;
    fetch(url)
    .then((response) => response.json())
    .then((data) => {
      setTests(data);
    })
    .catch((error) => {
      console.error("Error fetching unpublished tests:", error);
    });
  }, [user_id]); // useEffect will re-run whenever userId changes
  
  const [route, setRoute] = useState('');

  const [test_id, setTest_id] = useState(0);

  const handleTestClick = (testId) => {
    // console.log(testId);
    setTest_id(testId)
    setRoute('EditCollegeTest')
  };

  return (
    <div>
      {route === 'EditCollegeTest' && <Navigate to={`/editunpublishedtest/${user_id}/${test_id}`} state={{ user_id, test_id }} />}
      <CollegeHeader />
      <div className="d-flex flex-column align-items-center">
        {
          tests.length > 0 ? (
            <>
              {tests.map((test, index) => (
                test.status === 'unpublished' && (
                  <TestModal key={index} index={index} test={test} onClick={handleTestClick} />
                )
              ))}

            </>
          ) : (
            <p>No unpublished tests found.</p>
          )
        }
      </div>
    </div>
  )
}

export default UnpublishedCollegeTest
