import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Home from '../Home/home';

const SubjectData = () => {
  const location = useLocation();

  return (
    <>
    <Home />
      <nav className="nav justify-content-center">
        <Link className={`nav-link ${location.pathname === '/add-year' ? 'active' : ''}`} to="/add-year">Add Year</Link>
        <Link className={`nav-link ${location.pathname === '/add-subject' ? 'active' : ''}`} to="/add-subject">Add Subject</Link>
        <Link className={`nav-link ${location.pathname === '/add-chapter' ? 'active' : ''}`} to="/add-chapter">Add Chapter</Link>
        <Link className={`nav-link ${location.pathname === '/add-topic' ? 'active' : ''}`} to="/add-topic">Add Topic</Link>
        <Link className={`nav-link ${location.pathname === '/add-exam' ? 'active' : ''}`} to="/add-exam">Add Exam</Link>
        <Link className={`nav-link ${location.pathname === '/add-subtopic' ? 'active' : ''}`} to="/add-subtopic">Add Subtopic</Link>
      </nav>
      </>
  );
};

export default SubjectData;
