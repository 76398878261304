import React, { useEffect, useState } from 'react';
import './App.css';
import './styles/App.css'
import './styles/sideNavBar.css'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { setUser } from './slices/overlookSlice';
import UploadQuestions from './Components/UploadQuestions';
import { isLoggedIn } from './util/util';
// import ViewQuestions from './Components/ViewQuestions';
import ViewQuestions from './Components/userdashboard/ViewQuestions';
import LogoutIcon from '@mui/icons-material/Logout';
import Print from './Components/Print';
import SubjectData from './Components/Subject/SubjectData';
import AddYearForm from './Components/Subject/AddYearForm';
import AddSubjectForm from './Components/Subject/AddSubjectForm';
import AddChapterForm from './Components/Subject/AddChapterForm';
import AddTopicForm from './Components/Subject/AddTopicForm';
import AddExamForm from './Components/Subject/AddExamForm';
import AddSubtopicForm from './Components/Subject/AddSubtopicForm';
import PrinPreview from './Components/PrintPreview';
import Home from './Components/Home/home';
import ViewUserQuestionCount from './Components/ReviewerDashBoard/ViewUserQuestionCount';
import pageNotFound from './Components/PageNotFound/pageNotFound';
import Login from './Components/Login';
import UserDashboard from './Components/userdashboard/UserDashboard';
import SideNavBar from './Components/SideNavBar';

import CreateTest from './Components/CreateTest';
import NewTest from './Components/CreateTest/NewTest';
import UnpublishedTest from './Components/CreateTest/UnpublishedTest';
import PublishedTest from './Components/CreateTest/PublishedTest';
import TestMerger from './Components/CreateTest/TestMerger';
import TestEditor from './Components/CreateTest/TestEditor';
import ImageCrop from './Components/ImageCrop';

import TestPrintPreview from './Components/CreateTest/Published/TestPrintPreview';

import TestPublished from './Components/CreateTest/TestPublished';

import ReviewerHome from './Components/ReviewerDashBoard/ReviewerHome';
import ReviewQuestions from './Components/ReviewerDashBoard/ReviewQuestions';
import ReviewDificultyQuestions from './Components/ReviewerDashBoard/ReviewDificultyQuestions';
import ReviewUserQuestions from './Components/ReviewerDashBoard/ReviewUserQuestions';
import ReviewTopicRelatedQues from './Components/ReviewerDashBoard/ReviewTopicRelatedQues';
import ReviewExamQuestions from './Components/ReviewerDashBoard/ReviewExamQuestions';

import CollegeDashboardHome from './Components/CollegeDashboard/CollegeDashboardHome';
import Addlecturer from './Components/CollegeDashboard/Addlecturer';

import CreateCollegeTest from './Components/CollegeDashboard/Test/CreateCollegeTest';
import UnpublishedCollegeTest from './Components/CollegeDashboard/Test/UnpublishedCollegeTest';
import UnpublishedHome from './Components/CollegeDashboard/Test/Unpublished/UnpublishedHome';
import AddTestQuestions from './Components/CollegeDashboard/Test/Unpublished/AddTestQuestions';
import ReviewTestQuestions from './Components/CollegeDashboard/Test/Unpublished/ReviewTestQuestions';

import TestSetting from './Components/CollegeDashboard/Test/TestSetting';

import PublishedCollegeTest from './Components/CollegeDashboard/Test/PublishedCollegeTest';
import PublishedHome from './Components/CollegeDashboard/Test/Published/PublishedHome';
import PublishedTestPreview from './Components/CollegeDashboard/Test/Published/PublishedTestPreview';

import LecturerHome from './Components/LecturerDashBoard/LecturerHome';
import LecturerUnpublished from './Components/LecturerDashBoard/LecturerUnpublished/LecturerUnpublished';
import LecturerTestEditHome from './Components/LecturerDashBoard/LecturerUnpublished/LecturerTestEditHome';
import LecturerAddquestions from './Components/LecturerDashBoard/LecturerUnpublished/LecturerAddquestions';
import LecturerReviewTestQuestions from './Components/LecturerDashBoard/LecturerUnpublished/LecturerReviewTestQuestions';

import AdminDashBoard from './Components/AdminDashBoard/AdminDashBoard';
import AddCollege from './Components/AdminDashBoard/AddCollege';
import Reports from './Components/AdminDashBoard/Reports/Reports';
import ViewReportedQuestions from './Components/AdminDashBoard/Reports/ViewReportedQuestions';
import Payment from './Components/userdashboard/Payment';

import SuperUserDashboard from './Components/SuperuserDashboard/SuperUserDashboard';
import HomeIcon from "@mui/icons-material/Home";
import Popover from '@mui/material/Popover';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import SecurityIcon from '@mui/icons-material/Security';
import PageNotFound from './Components/PageNotFound/pageNotFound';
import { useDispatch, useSelector } from 'react-redux';

const App = () => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [user, setUserData] = useState(undefined)
  const selectedNav = useSelector(state => state.overlookSlice.selectedNav)


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  useEffect(()=>{
    const userType = localStorage.getItem("userType");
    const userId = localStorage.getItem("userId");
    const userData = {
      userType,
      userId
    }
    if(user == undefined)
    setUserData(userData)
    dispatch(setUser(userData))

console.log("abc12345vrrrr")
  },[user])

  console.log("abc12345vvvv")
  const handleLogout = () => {
    handleForceLogout()
    localStorage.removeItem("userId");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userType");
    window.location.href = "/" 
};
const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;

const handleForceLogout = () => {
    const userId = localStorage.getItem("userId");
  fetch(`${baseUrl}/logout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userId: userId }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Logout request failed");
      }
    })
    .catch((error) => {
      console.error("Error logging out:", error);
      // Handle error scenario here
    });
};


  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Router>
        <div className="AppContainer">
          {window.location.pathname != "/" && isLoggedIn() && (
            <div className="leftApp">
              <SideNavBar />
            </div>
          )}
          {window.location.pathname != "/" && isLoggedIn() && (
            <div className="rightApp">
              <div className="appHeader">
                <div className="appHeaderTopSection">
                  <div className="homeNavBar">
                    <div className="homeNavItems">
                      <span className="homeIconn">
                        {" "}
                        <HomeIcon />{" "}
                      </span>
                      / {selectedNav}
                    </div>
                    <div className="homeNavHeaderr"> {selectedNav}</div>
                  </div>
                  <div className="homeNavbarTopSectionRight">
                    <div onClick={handleClick} className="profile">
                      <AccountCircleIcon fontSize="large" /> Profile
                    </div>

                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose1}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <div className="profilePopOver">
                        <div className="profileOptions">
                          {" "}
                          <SettingsIcon /> Settings
                        </div>
                        <div className="profileOptions">
                          {" "}
                          <SecurityIcon /> Privacy
                        </div>
                        <div
                          onClick={handleLogout}
                          className="profileOptions logout"
                        >
                          <LogoutIcon /> Logout
                        </div>
                      </div>
                    </Popover>
                  </div>
                </div>
                <p className="headerText">Hello Examtech Family!</p>
                <p className="headerHelpText">
                  "You are not just doing WORK, you are revolutionizing the Indian Education System"
                </p>
              </div>

              <div className="contentwrappers">
                <Routes>
                  <Route path="/home" element={<Home />} />
                  <Route path="/upload" element={<UploadQuestions />} />
                  <Route path="/test" element={<CreateTest />} />
                  <Route path="/view" element={<ViewQuestions />} />
                  <Route path="/print" element={<Print />} />
                  <Route path="/printPreview" element={<PrinPreview />} />
                  <Route path="/subject" element={<SubjectData />} />
                  <Route path="/add-year" element={<AddYearForm />} />
                  <Route path="/add-subject" element={<AddSubjectForm />} />
                  <Route path="/add-chapter" element={<AddChapterForm />} />
                  <Route path="/add-topic" element={<AddTopicForm />} />
                  <Route path="/add-exam" element={<AddExamForm />} />
                  <Route path="/add-subtopic" element={<AddSubtopicForm />} />
                  <Route
                    exact
                    path="/userDashBoard"
                    element={<UserDashboard />}
                  />
                  <Route path="/newTest" element={<NewTest />} />
                  <Route
                    path="/unpublishedtest"
                    element={<UnpublishedTest />}
                  />
                  <Route path="/publishedtest" element={<PublishedTest />} />
                  <Route path="/testmerger" element={<TestMerger />} />
                  <Route path="/edittest" element={<TestEditor />} />
                  <Route
                    path="/testpublished/:test_id"
                    element={<TestPublished />}
                  />
                  <Route
                    path="/testprint/:test_id"
                    element={<TestPrintPreview />}
                  />

                  {/* below Routes are Live Route Added the Live routes here only */}
                  <Route path="/imagecrop" element={<ImageCrop />} />

                  <Route path="/reviewer" element={<ReviewerHome />} />
                  <Route
                    path="/reviewquestions/:user_id"
                    element={<ReviewQuestions />}
                  />
                  <Route
                    path="/reviewdificultyquestions/:user_id"
                    element={<ReviewDificultyQuestions />}
                  />
                  <Route
                    path="/reviewuserquestions/:user_id"
                    element={<ReviewUserQuestions />}
                  />
                  <Route
                    path="/viewusersquestioncount"
                    element={<ViewUserQuestionCount />}
                  />
                  <Route
                    path="/reviewtopicrelatedquestions/:user_id"
                    element={<ReviewTopicRelatedQues />}
                  />
                  <Route
                    path="/reviewexamrelatedquestions/:user_id"
                    element={<ReviewExamQuestions />}
                  />

                  <Route
                    path="/collegedashboard"
                    element={<CollegeDashboardHome />}
                  />
                  <Route
                    path="/addlecturer/:user_id"
                    element={<Addlecturer />}
                  />

                  <Route
                    path="/createtest/:user_id"
                    element={<CreateCollegeTest />}
                  />

                  <Route
                    path="/unpublishedcollegetest/:user_id"
                    element={<UnpublishedCollegeTest />}
                  />
                  <Route
                    path="/editunpublishedtest/:user_id/:test_id"
                    element={<UnpublishedHome />}
                  />
                  <Route
                    path="/addtestquestions/:user_id/:test_id"
                    element={<AddTestQuestions />}
                  />
                  <Route
                    path="/reviewtestquestions/:user_id/:test_id"
                    element={<ReviewTestQuestions />}
                  />

                  <Route
                    path="/testsettings/:user_id/:test_id"
                    element={<TestSetting />}
                  />

                  <Route
                    path="/publishedcollegetest/:user_id"
                    element={<PublishedCollegeTest />}
                  />
                  <Route
                    path="/publishedtestpreview/:user_id/:test_id"
                    element={<PublishedHome />}
                  />
                  <Route
                    path="/testprintpreview/:user_id/:test_id"
                    element={<PublishedTestPreview />}
                  />

                  <Route path="/lecturer" element={<LecturerHome />} />
                  <Route
                    path="/lecturerunpublishedtest/:user_id"
                    element={<LecturerUnpublished />}
                  />
                  <Route
                    path="/lecturereviewtest/:user_id/:lecture_id/:test_id"
                    element={<LecturerTestEditHome />}
                  />
                  <Route
                    path="/lectureraddtestquestions/:user_id/:lecture_id/:test_id"
                    element={<LecturerAddquestions />}
                  />
                  <Route
                    path="/lecturereviewtestquestions/:user_id/:lecture_id/:test_id"
                    element={<LecturerReviewTestQuestions />}
                  />

                  <Route path="/admindashboard" element={<AdminDashBoard />} />
                  <Route path="/addcollege" element={<AddCollege />} />
                  <Route path="/reports" element={<Reports />} />
                  <Route
                    path="/viewdetailedreport/:reportId"
                    element={<ViewReportedQuestions />}
                  />

                  <Route path="/superuser" element={<SuperUserDashboard />} />
                  <Route path="/pageNotFound" element={<pageNotFound />} />
                  <Route path="*" element={<PageNotFound />} />
                  <Route path="/UserPayment" element={<Payment />} />
                </Routes>
              </div>
            </div>
          )}

          {(window.location.pathname == "/" || !isLoggedIn()) && (
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          )}
        </div>
      </Router>
    </div>
  );
};

export default App;
