import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import CollegeHeader from '../../CollegeHeader';

const PublishedHome = () => {
  const { user_id, test_id } = useParams(); // Destructure the params

  const navigate = useNavigate();
  // eslint-disable-next-line
  const [userid, setUserId] = useState(0);

  useEffect(() => {
    // Check if access token is present in local storage
    const accessToken = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");
    // console.log("User ID in user view dashboard", userId);
    setUserId(userId);

    if (!userId || !accessToken) {
      // Redirect to login page if userId or accessToken is missing
      navigate("/login");
    }
  }, [navigate]);

  const [navigatePages, setNavigatePages] = useState([]);
  const [navigates, setNavigates] = useState('');
  const [routeTo, setRouteTo] = useState('');

  useEffect(() => {
    setNavigatePages(['Print Test', 'Test Setting']);
  }, []);

  useEffect(() => {
    if (navigates === 'Print Test') {
      setRouteTo("Print Test")
    }
    if (navigates === 'Test Setting') {
      setRouteTo("Test Setting")
    }
  }, [navigates, navigate, user_id]);

  return (
    <div className="d-flex flex-column align-items-center">
      {routeTo === "Print Test" && (
        <Navigate to={`/testprintpreview/${user_id}/${test_id}`} state={{ user_id, test_id }} />
      )}
      {routeTo === "Test Setting" && (
        <Navigate to={`/testsettings/${user_id}/${test_id}`} state={{ user_id, test_id }} />
      )}
      <CollegeHeader />
      <Tabs
        defaultActiveKey=''
        id="fill-tab-example"
        className="mb-3"
        onSelect={(k) => setNavigates(k)}
        fill
      >
        {navigatePages.map((page, index) => (
          <Tab
            key={index}
            eventKey={page}
            title={page}
          />
        ))}
      </Tabs>
    </div>
  )
}

export default PublishedHome
