import React, { useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useNavigate } from "react-router-dom";
import { Navigate } from 'react-router-dom';

const CollegeHeader = () => {
  const [user_id, setUserId] = useState(0);
  const navigate = useNavigate();
  const [navigatePages, setNavigatePages] = useState([]);
  const [navigates, setNavigates] = useState('');
  const [routeTo, setRouteTo] = useState('');

  useEffect(() => {
    // Check if access token is present in local storage
    const accessToken = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");
    // console.log("REVIEWER USER_ID", userId);
    setUserId(userId);

    if (!userId || !accessToken) {
      // Redirect to login page if userId or accessToken is missing
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    setNavigatePages(['Add Lecturer','Create Test', 'Unpublished Tests', 'Published Tests']);
  }, []);

  useEffect(() => {
    if (navigates === 'Add Lecturer') {
      setRouteTo("Add Lecturer")
    }
    if (navigates === 'Create Test') {
      setRouteTo("Create Test")
    }
    if (navigates === 'Unpublished Tests') {
      setRouteTo("Unpublished Tests")
    }
    if (navigates === 'Published Tests') {
      setRouteTo("Published Tests")
    }
  }, [navigates, navigate, user_id]);

  const handleLogout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userType");

    navigate("/login");
  };
  return (
    <div className="d-flex flex-column align-items-center">
      {routeTo === "Add Lecturer" && (
        <Navigate to={`/addlecturer/${user_id}`} state={user_id} />
      )}
      {routeTo === "Create Test" && (
        <Navigate to={`/createtest/${user_id}`} state={user_id} />
      )}
      {routeTo === "Unpublished Tests" && (
        <Navigate to={`/unpublishedcollegetest/${user_id}`} state={user_id} />
      )}
      {routeTo === "Published Tests" && (
        <Navigate to={`/publishedcollegetest/${user_id}`} state={user_id} />
      )}
      <button onClick={handleLogout}>Logout</button>
      <Tabs
        defaultActiveKey=''
        id="fill-tab-example"
        className="mb-3"
        onSelect={(k) => setNavigates(k)}
        fill
      >
        {navigatePages.map((page, index) => (
          <Tab
            key={index}
            eventKey={page}
            title={page}
          />
        ))}
      </Tabs>
      <div style={{ display: routeTo === 'Create Test' ? 'block' : 'none' }}>CREATE NEW TEST</div>

    </div>
  )
}

export default CollegeHeader
