import React, { useEffect, useState } from 'react'
import axios from 'axios';
import ReviewHearder from './ReviewHearder'
import { useParams } from 'react-router-dom';
import ReviewQuestionSelectionPage from './ReviewQuestionSelectionPage.';
import ViewModel from './ViewModel';


import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReportBtn from '../Buttons/ReportBtn';
import './Review.css';

import { ToastContainer, toast, Slide } from 'react-toastify';

const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;

const ReviewDificultyQuestions = () => {
    const { user_id } = useParams();

    const [questions, setQuestions] = useState([]);

    const [selectedYearId, setSelectedYearId] = useState('');
    const [selectedSubjectId, setSelectedSubjectId] = useState('');
    const [selectedChapterId, setSelectedChapterId] = useState('');
    const [selectedTopicId, setSelectedTopicId] = useState('');

    const [triggerToReload, setTriggerToRelod] = useState(false);

    const formatQuestions = (data) => {
        return data.map(item => ({
            questionId: item.id,

            preQuestionText: item.pre_question_text,
            postQuestionText: item.post_question_text.split('!'),

            option1Text: item.option1_text,
            option2Text: item.option2_text,
            option3Text: item.option3_text,
            option4Text: item.option4_text,

            correctOption: item.correct_option,

            preExplanationText: item.pre_explanation_text,
            postExplanationText: item.post_explanation_text,

            question_image_url: item.question_image_url,
            option1_image_url: item.option1_image_url,
            option2_image_url: item.option2_image_url,
            option3_image_url: item.option3_image_url,
            option4_image_url: item.option4_image_url,
            explanation_image_url: item.explanation_image_url,

            conceptualError: item.conceptual_error,
            alignmentError: item.alignment_error,
            deletedSyllabus: item.deleted_syllabus,
            difficultyLevel: item.difficulty_level,

            selectedExamId: item.selectedExamId

        }));
    };

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                // Create params object conditionally
                const params = {};
                if (selectedYearId) params.year_id = selectedYearId;
                if (selectedSubjectId) params.subject_id = selectedSubjectId;
                if (selectedChapterId) params.chapter_id = selectedChapterId;
                if (selectedTopicId) params.topic_id = selectedTopicId;

                const response = await axios.get(`${baseUrl}/questionstoreviewdificulty`, { params });

                const formattedQuestions = formatQuestions(response.data);
                setQuestions(formattedQuestions);
            } catch (err) {
                console.error('ERROR', err);
            }
        };
        fetchQuestions();
    }, [selectedYearId, selectedSubjectId, selectedChapterId, selectedTopicId, triggerToReload]);

    const [difficultyLevels, setDifficultyLevels] = useState([]);

    useEffect(() => {
        setDifficultyLevels(questions.map(question => question.difficultyLevel));
    }, [questions]);

    useEffect(() => {
        const updateQuestionsInDatabase = async () => {
            try {
                const updatedQuestions = questions.map((question, index) => {
                    const difficultyLevel = difficultyLevels[index];
                    if (difficultyLevel !== '' && difficultyLevel !== undefined) {
                        return {
                            ...question,
                            difficultyLevel,
                        };
                    }
                    return question;
                }).filter(question => question.difficultyLevel !== '' && question.difficultyLevel !== undefined);

                if (updatedQuestions.length > 0) {
                    const response = await axios.post(`${baseUrl}/updatereviewdificultyquestions`, { updatedQuestions });
                    if (response.status === 201) {
                        toast.success('Questions updated successfully');
                    } else {
                        toast.error('Error updating questions');
                    }
                } else {
                    // toast.error('No valid difficulty levels to update');
                }
            } catch (error) {
                console.error('Error updating questions:', error);
                toast.error('Failed to update questions. Please try again later.');
            }
        };

        if (difficultyLevels.length > 0 && questions.length > 0) {
            updateQuestionsInDatabase();
        }
    }, [difficultyLevels, questions]);

    const handleSkipQuestion = async (question_id) => {
        try {
            const response = await axios.post(`${baseUrl}/updatereviewskipdificultyquestion`, { question_id });
            if (response.status === 201) {
                toast.success('Question skipped successfully');
                setTriggerToRelod(prev => !prev);
            } else {
                toast.error('Error skipping question');
            }
        } catch (error) {
            toast.error('Error skipping question');
        }
    };


    const handleDifficultyChange = (index, value) => {
        const updatedDifficultyLevels = [...difficultyLevels];
        updatedDifficultyLevels[index] = value;
        setDifficultyLevels(value);
    };

    return (
        <div>
            <div>
                <ReviewHearder />
                    <ReviewQuestionSelectionPage
                        setSelectedYearId={setSelectedYearId}
                        setSelectedSubjectId={setSelectedSubjectId}
                        setSelectedChapterId={setSelectedChapterId}
                        setSelectedTopicId={setSelectedTopicId}
                    />
                {questions.map((question, index) => (
                    <div key={question.questionId}>
                            <div>
                                                <div>
                                                    <div>
                                                        <div>
                                                            <ReportBtn question={question} user_id={user_id} />
                                                            <div className="" >
                                                                <div className="">
                                                                    <div>
                                                                        <span>Difficulty Level</span>
                                                                        <div className="deficultyLevel">
                                                                            <div className="deficultOptions">
                                                                                <input
                                                                                    type="radio"
                                                                                    name={`difficulty-${index}`}
                                                                                    value="low"
                                                                                    checked={difficultyLevels[index] === 'l'}
                                                                                    onChange={() => handleDifficultyChange(index, 'low')}
                                                                                />
                                                                                Low
                                                                            </div>
                                                                            <div className="deficultOptions">
                                                                                <input
                                                                                    type="radio"
                                                                                    name={`difficulty-${index}`}
                                                                                    value="medium"
                                                                                    checked={difficultyLevels[index] === 'm'}
                                                                                    onChange={() => handleDifficultyChange(index, 'medium')}
                                                                                />
                                                                                Medium
                                                                            </div>
                                                                            <div className="deficultOptions">
                                                                                <input
                                                                                    type="radio"
                                                                                    name={`difficulty-${index}`}
                                                                                    value="hard"
                                                                                    checked={difficultyLevels[index] === 'h'}
                                                                                    onChange={() => handleDifficultyChange(index, 'hard')}
                                                                                />
                                                                                Hard
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="button-group">
                                                                    <button className="skipButton" onClick={() => handleSkipQuestion(question.questionId)}>SKIP</button> </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                        <ViewModel question={question} index={index} />
                            </div>
                    </div>
                ))}

            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </div>
    )
}

export default ReviewDificultyQuestions
