// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LatexRender from './LatexRender'; // Import the LatexRender component
import './Component.css';
// import { Form } from 'react-bootstrap';


// import { grey } from '@mui/material/colors';

// const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;


function ViewModel({ question, index }) {
    // console.log(question);
    const {
        preQuestionText,
        question_image_url,
        postQuestionText,
        option1Text,
        option2Text,
        option3Text,
        option4Text,
        option1_image_url,
        option2_image_url,
        option3_image_url,
        option4_image_url,
        preExplanationText,
        postExplanationText,
        explanation_image_url,
        correctOption
    } = question;
    // const [showAnswer, setShowAnswer] = useState(true);
    // const [showSolution, setShowSolution] = useState(true);



    // const blockContextMenu = (event) => {
    //     event.preventDefault(); // Prevent default context menu behavior
    // }

    // const toggleAnswer = () => {
    // setShowAnswer(!showAnswer);
    // };

    // const togglesolution = () => {
    //     setShowSolution(!showSolution);
    // };

    const getImageUrl = (url) => {
        const imageUrl = process.env.REACT_APP_IMAGE_URL;
        // console.log('REACT_APP_IMAGE_URL:', imageUrl); // Verify the environment variable
        if (!imageUrl) {
            console.error('REACT_APP_IMAGE_URL is not defined');
            return '';
        }
        const imgUrl = `${imageUrl}${url}`;
        // console.log(imgUrl);
        return imgUrl;
    };

    // const [printQueue, setPrintQueue] = useState([]);

    // const addToPrintQueue = (id) => {
    //     if (!printQueue.includes(id)) {
    //         // Add id to print queue array
    //         setPrintQueue([...printQueue, id]);
    //     } else {
    //         // Remove id from print queue array
    //         setPrintQueue(printQueue.filter(item => item !== id));
    //     }
    // };

    // const isInQueue = printQueue.includes(question.questionId);
    // const buttonColor = isInQueue ? 'red' : 'green';
    // const buttonText = isInQueue ? 'Remove' : 'Add';
    // var totalQuestions = 10;

    const correctOptionText = question.correctOption;

    // const [solutionVisibility, setSolutionVisibility] = useState({});
    // const toggleSolution = (id) => {
    //     setSolutionVisibility(prevState => ({
    //         ...prevState,
    //         [id]: !prevState[id]
    //     }));
    // };


    useEffect(() => {
        // Initialize MathJax rendering when component mounts
        if (typeof window !== 'undefined' && typeof window.MathJax !== 'undefined') {
            window.MathJax.typesetPromise();
        }
    }, []);

    return (
        <>
                {/* <Row style={{ backgroundColor: 'lightyellow' }}> */}
                {/* <Col> */}
                {/* <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}> */}
                {/* <div>
                                <button style={{ backgroundColor: 'orange', color: 'white', border: 'none', padding: '5px 10px', borderRadius: '5px', display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                    <i className="bi bi-exclamation-triangle-fill" style={{ marginRight: '5px', fontSize: '16px' }}></i>
                                    Report
                                </button>
                            </div>

                            <div style={{ marginLeft: 'auto' }}>

                                {printQueue.length}/{totalQuestions}

                                <button
                                    onClick={() => addToPrintQueue(question.questionId)}
                                    style={{
                                        padding: '5px 10px',
                                        fontSize: '14px',
                                        borderRadius: '5px',
                                        border: '1px solid black',
                                        backgroundColor: buttonColor,
                                        color: 'white',
                                        marginLeft: '10px'
                                    }}
                                >
                                    {buttonText}
                                </button>

                            </div> */}
                {/* Add more buttons or elements here in the future */}
                {/* </div> */}
                {/* </Col> */}
                {/* </Row> */}

                        {/* <div className="disableCopy" onContextMenu={blockContextMenu}> */}
                        <div>
                            <div>
                                {question_image_url ? (
                                    <div className="row">
                                        <div className="questions">
                                            {/* Q{index+1}. <LatexRender content={preQuestionText} /> */}
                                            Q{index + 1}. {preQuestionText}
                                        </div>
                                        <div className="col-md-6">
                                            {question_image_url.split(',').map((url, i) => (
                                                <React.Fragment key={i}>
                                                    <img src={getImageUrl(url.trim())} alt="Question" className="img-fluid mb-2" style={{ height: '20%', width: '20%' }}/>
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <>

                                        {/* Q{index+1}. <LatexRender content={preQuestionText} /> */}
                                       <div className="questions"> Q{index + 1}. {preQuestionText}</div>
                                    </>
                                )}
                                {postQuestionText.map((line, i) => (
                                    <p key={i} className="questions">{line}</p>
                                ))}
                                <div className="mt-3">
                                    <span><b>Options :</b></span>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {option1Text &&
                                                <div className={correctOptionText === 'A' ?'rightAnswer': 'wrongAnswer'} id='option1' style={{ display: 'flex' }}>
                                                    <div  >(A)</div>
                                                    {option1Text}
                                                </div>
                                            }
                                            {option2Text &&
                                                <div className={correctOptionText === 'B' ?'rightAnswer': 'wrongAnswer'} id='option2' style={{ display: 'flex' }}>
                                                    <div >(B)</div>
                                                    {option2Text}
                                                </div>
                                            }
                                            {option3Text &&
                                                <div className={correctOptionText === 'C' ?'rightAnswer': 'wrongAnswer'} id='option3' style={{ display: 'flex' }}>
                                                    <div  >(C)</div>
                                                    {option3Text}
                                                </div>
                                            }
                                            {option4Text &&
                                                <div className={correctOptionText === 'D' ?'rightAnswer': 'wrongAnswer'} id='option4' style={{ display: 'flex' }}>
                                                    <div className={correctOptionText === 'D' ?'rightAnswer': 'wrongAnswer'} >(D)</div>
                                                    {option4Text}
                                                </div>
                                            }
                                            {option1_image_url &&
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ backgroundColor: correctOptionText === 'A' ? 'green' : 'transparent', marginRight: '5px' }}>(A)</div>
                                                    <img src={getImageUrl(option1_image_url)} alt="Option A" className="img-fluid" style={{ height: '20%', width: '20%' }}/>
                                                </div>
                                            }
                                            {option2_image_url &&
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ backgroundColor: correctOptionText === 'C' ? 'green' : 'transparent', marginRight: '5px' }}>(C)</div>
                                                    <img src={getImageUrl(option2_image_url)} alt="Option B" className="img-fluid" style={{ height: '20%', width: '20%' }}/>
                                                </div>
                                            }
                                            {option3_image_url &&
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ backgroundColor: correctOptionText === 'B' ? 'green' : 'transparent', marginRight: '5px' }}>(B)</div>
                                                    <img src={getImageUrl(option3_image_url)} alt="Option C" className="img-fluid" style={{ height: '20%', width: '20%' }}/>
                                                </div>
                                            }
                                            {option4_image_url &&
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ backgroundColor: correctOptionText === 'D' ? 'green' : 'transparent', marginRight: '5px' }}>(D)</div>
                                                    <img src={getImageUrl(option4_image_url)} alt="Option D" className="img-fluid" style={{ height: '20%', width: '20%' }}/>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                                
                                <p className="mt-3"><b>Answer: {correctOption}</b></p>
                                {/* {showAnswer && <p className="mt-3"><b>Answer: {correctOption}</b></p>} */}
                            </div>
                        </div>
                        {/* <div className="mt-3">
                            <Form.Check
                                type="switch"
                                id={`show-solution-switch-${question.questionId}`}
                                label={solutionVisibility[question.questionId] ? 'Hide Solution' : 'Show Solution'}
                                checked={!!solutionVisibility[question.questionId]}
                                onChange={() => toggleSolution(question.questionId)}
                                className="link-style"
                            />
                        </div> */}
                        {/* {solutionVisibility[question.questionId] && ( */}
                            <>
                            <div className="explaination">
                                    <h5>Explaination</h5>
                                </div>
                                <div className="explaination-text">

                                {explanation_image_url ? (
                                    // <div className="two-column-container disableCopy" onContextMenu={blockContextMenu}>
                                    <div className=''>
                                        <div className="col-md-6">
                                            {preExplanationText && preExplanationText.includes("\\begin") ? (
                                                <LatexRender content={preExplanationText} />
                                            ) : (
                                                preExplanationText && preExplanationText.split('!').map((line, i) => (
                                                    <p key={i}>{line}</p>
                                                ))
                                            )}
                                            {postExplanationText && postExplanationText.includes("\\begin") ? (
                                                <LatexRender content={postExplanationText} />
                                            ) : (
                                                postExplanationText && postExplanationText.split('!').map((line, i) => (
                                                    <p key={i}>{line}</p>
                                                ))
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            {explanation_image_url.split(',').map((url, i) => (
                                                <React.Fragment key={i}>
                                                    <img src={getImageUrl(url.trim())} alt="Explanation" className="img-fluid mb-2" style={{ height: '20%', width: '20%' }}/>
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {preExplanationText && preExplanationText.includes("\\begin") ? (
                                            // <div style={{display:'none'}}>
                                            <LatexRender content={preExplanationText} />
                                            // </div>
                                        ) : (
                                            preExplanationText && preExplanationText.split('!').map((line, i) => (
                                                <p key={i}>{line}</p>
                                            ))
                                        )}
                                        {postExplanationText && postExplanationText.includes("\\begin") ? (
                                            <LatexRender content={postExplanationText} />
                                        ) : (
                                            postExplanationText && postExplanationText.split('!').map((line, i) => (
                                                <p key={i}>{line}</p>
                                            ))
                                        )}
                                    </>

                                )}
                                </div>
                            </>
                        {/* )} */}
                    



        </>
    );
}

export default ViewModel;
