import React from 'react'

const TestMerger = () => {
  return (
    <div>
      Test Merger
    </div>
  )
}

export default TestMerger
