export const isLoggedIn = () => {
    const accessToken = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");
    const userType = localStorage.getItem("userType");
    if (!userId || !accessToken) {
      return false;
    }else{
        return true;
    }
}

