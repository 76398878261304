import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LatexRender from './LatexRender'; // Import the LatexRender component
import './Component.css';

const Print = ({ setIsHeaderVisible, setIsNavVisible }) => {

  const [selectedOption, setSelectedOption] = useState('questionAndOption'); // Default selected option
  const [showExplanation, setShowExplanation] = useState(false); // Flag to control explanation visibility

  useEffect(() => {
    setIsHeaderVisible(false);
    setIsNavVisible(false);
    return () => {
      setIsHeaderVisible(true);
      setIsNavVisible(true);
    };
  }, [setIsHeaderVisible, setIsNavVisible]);

  const location = useLocation();
  const { objects, printQueue } = location.state || { objects: {}, printQueue: [] };
  const questions = objects ? objects.questions : [];

  useEffect(() => {
    // Initialize MathJax rendering when component mounts
    if (typeof window !== 'undefined' && typeof window.MathJax !== 'undefined') {
      window.MathJax.typesetPromise();
    }
  }, []);

  const handlePrint = () => {
    window.print();

  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setShowExplanation(e.target.value === 'questionOptionsAnswerExplanation');
  };

  const blockContextMenu = (event) => {
    event.preventDefault(); // Prevent default context menu behavior
  };

  return (
    <div className="button-content">
      <button onClick={handlePrint} className="hide-on-print button-content">
        Print
      </button>

      <select value={selectedOption} onChange={handleOptionChange} className="hide-on-print">
        <option value="questionAndOption">Question and Option</option>
        <option value="questionOptionWithAnswer">Question Option with Answer</option>
        <option value="questionOptionsAnswerExplanation">Question Options Answer and Explanation</option>
      </select>
      <div className={"show-on-print"}>
        <h1>NAME OF COLLEGE</h1>
        <hr />
      </div>

      <div className="disableCopy" onContextMenu={blockContextMenu}>
        {printQueue.length > 0 ? (
          printQueue.map((questionId, index) => {
            const question = questions.find((q) => q.questionId === questionId);
            if (!question) return null; // Skip rendering if question not found
            return (
              <div key={question.questionId}>
                <p className="hide-on-print"><b>Question ID {question.questionId}</b></p>
                <div className="two-column-container">

                  <div className="left-column">
                    {index + 1}. {question.preQuestionText}
                  </div>
                  <div className="right-column">
                    {question.question_image_url && (
                      question.question_image_url.split(',').map((url) => {
                        return <><img src={url.trim()} alt="Question" height="100" /><br /></>
                      })
                    )}
                  </div>
                </div>
                {question.postQuestionText.map((line, i) => (
                  <p key={i}>{line}</p>
                ))}
                <b>Options :</b>
                {/* Render based on selected option */}
                <div className="two-column-container">
                  <div className="left-column">
                    {/* Render options for printing */}
                    {question.option1Text && <p className="math-equation" id='option1'>(A) {question.option1Text}</p>}
                    {question.option2Text && <p className="math-equation" id='option1'>(B) {question.option2Text}</p>}
                    {question.option3Text && <p className="math-equation" id='option1'>(C) {question.option3Text}</p>}
                    {question.option4Text && <p className="math-equation" id='option1'>(D) {question.option4Text}</p>}
                  </div>
                  <div className="right-column">
                    {question.option1_image_url && <>(A) <img src={question.option1_image_url} alt="Option A" /></>}<br />
                    {question.option2_image_url && <>(B) <img src={question.option2_image_url} alt="Option B" /></>}<br />
                    {question.option3_image_url && <>(C) <img src={question.option3_image_url} alt="Option C" /></>}<br />
                    {question.option4_image_url && <>(D) <img src={question.option4_image_url} alt="Option D" /></>}<br />
                  </div>
                </div>
                {(selectedOption === 'questionOptionWithAnswer' || selectedOption === 'questionOptionsAnswerExplanation') && (
                  <div className="two-column-container">
                    <div className="left-column">
                      {question.correctOption && <p className="math-equation" id='option1'><b>Answer: {question.correctOption}</b></p>}
                    </div>
                  </div>
                )}
                {showExplanation && (
                  <>
                    <b>Explanation :</b>
                    {question.explanation_image_url ? (
                      <div className="two-column-container">
                        <div className="left-column">
                          {question.preExplanationText && question.preExplanationText.includes("\\begin") ? (<>
                            <LatexRender content={question.preExplanationText} />
                          </>) : (<>
                            {question.preExplanationText && question.preExplanationText.split('!').map((line, i) => (
                              <p key={i}>{line}</p>
                            ))}
                          </>)}
                          {question.postExplanationText && question.postExplanationText.includes("\\begin") ? (<>
                            <LatexRender content={question.postExplanationText} />
                          </>) : (<>
                            {question.postExplanationText && question.postExplanationText.split('!').map((line, i) => (
                              <p key={i}>{line}</p>
                            ))}
                          </>)}
                        </div>
                        <div className="right-column">
                          {question.explanation_image_url && (
                            <div className="image-container">
                              {question.explanation_image_url.split(',').map((url) => {
                                return <><img src={url.trim()} alt="Question" height="100" /><br /></>
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <>
                        {question.preExplanationText && question.preExplanationText.includes("\\begin") ? (<>
                          <LatexRender content={question.preExplanationText} />
                        </>) : (<>
                          {question.preExplanationText && question.preExplanationText.split('!').map((line, i) => (
                            <p key={i}>{line}</p>
                          ))}
                        </>)}
                        {question.postExplanationText && question.postExplanationText.includes("\\begin") ? (<>
                          <LatexRender content={question.postExplanationText} />
                        </>) : (<>
                          {question.postExplanationText && question.postExplanationText.split('!').map((line, i) => (
                            <p key={i}>{line}</p>
                          ))}
                        </>)}
                      </>

                    )}
                    <hr />
                  </>
                )}
              </div>
            );
          })
        ) : (
          <p>No questions in the print queue</p>
        )}
      </div>
    </div>
  );
};

export default Print;
