import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { Form } from 'react-bootstrap';

import Select from 'react-select'
import makeAnimated from 'react-select/animated';

import { ToastContainer, toast, Slide } from 'react-toastify';

const animatedComponents = makeAnimated();

const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;

const endpointyear = "/years";

const NewTest = () => {

  const navigate = useNavigate();

  const [user_id, setUserId] = useState(0);

  useEffect(() => {
    // Check if access token is present in local storage
    const accessToken = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");
    // console.log("User ID in user view dashboard", userId);
    setUserId(userId);

    if (!userId || !accessToken) {
      // Redirect to login page if userId or accessToken is missing
      navigate("/login");
    }
  }, [navigate]);

  // Define state variables to store input data
  const [testType, setTestType] = useState("1");
  const [testMode, setTestMode] = useState("2");
  const [sessionName, setSessionName] = useState("");
  const [pdfReportName, setPdfReportName] = useState("");
  const [sessionDesc, setSessionDesc] = useState("");

  const [testPattern, setTestPattern] = useState("1");
  const [correctAnswerMarks, setCorrectAnswerMarks] = useState(1);
  const [wrongAnswerMarks, setWrongAnswerMarks] = useState(0);

  const [testDate, setTestDate] = useState("");
  const [timeInMin, setTimeInMin] = useState("");
  // const [totalQuestions, setTotalQuestions] = useState("");

  const [year, setYear] = useState("");
  const [subject, setSubject] = useState([]);
  // const [selectedSubjects, setSelectedSubjects] = useState([]);

  const [years, setYears] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const [isMultiSelect, setIsMultiSelect] = useState(false);
  const [fillMarks, setFillMarks] = useState(false);


  useEffect(() => {
    const fetchYears = async () => {
      try {
        const response = await axios.get(`${baseUrl}${endpointyear}`);
        setYears(response.data);
      } catch (error) {
        console.error("Error fetching years:", error);
      }
    };

    fetchYears();
  }, []);

  const endpoint1 = "/subjects";

  const fetchSubjects = async (yearId) => {
    try {
      const url = `${baseUrl}${endpoint1}?yearId=${yearId}`;
      const response = await axios.get(url);
      setSubjects(response.data);
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  const subjectsOptions = subjects.map(subject => ({
    value: subject.subject_id,
    label: subject.subject_name,
    totalQuestions: ''
  }));

  const handleYearChange = (e) => {
    const selectedYear = e.target.value;
    setYear(selectedYear);
    setSubject([]);
    setIsMultiSelect(false)
    fetchSubjects(selectedYear);
  };

  const handleSingleSubjectSelection = (subject) => {
    setSubject([subject]); // Update state with the selected subject
  };

  const handleMultiSubjectSelection = (subject) => {
    setSubject(subject);
  };

  const handleTotalQuestionsChange = (subjectId, totalQuestions) => {
    setSubject(prevSelectedSubjects =>
      prevSelectedSubjects.map(subject =>
        subject.value === subjectId
          ? { ...subject, totalQuestions: totalQuestions !== '' ? parseInt(totalQuestions) : '' }
          : subject
      )
    );
  };


  const toggleSelectionMode = () => {
    setIsMultiSelect(prevMode => !prevMode);
    setSubject([]); // Reset subject selection when toggling mode

  };

  const handleFillMarks = () => {
    if (!subject || subject.length === 0) {
      return;
    }

    const firstSubjectTotalQuestions = subject[0].totalQuestions;

    const updatedSubjects = subject.map(subject => ({
      ...subject,
      totalQuestions: firstSubjectTotalQuestions
    }));

    setSubject(updatedSubjects);
  };

  const toggleFillMarks = () => {
    setFillMarks(prevMode => !prevMode);
    if (!fillMarks) {
      handleFillMarks();
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("INSIDE HANDLE SUBMIT");
    try {
      const endpoint = "/api/test";
      const response = await axios.post(`${baseUrl}${endpoint}`, {
        testType,
        testMode,
        sessionName,
        pdfReportName,
        sessionDesc,
        testPattern,
        correctAnswerMarks,
        wrongAnswerMarks,
        testDate,
        timeInMin,
        year,
        subject,
        user_id
      });

      // console.log("RESPONSE : ", response);

      if (response.status === 201) {
        toast.success("Test Session created successfully");
        resetField();
        // console.log("RESPONSE : ", response);
      } else {
        toast.error("Failed to Created Test");
        // console.log("ERROR");
      }
    } catch (error) {
      toast.error("Error");
      console.log("ERROR : ", error);
    }
  };

  const resetField = () => {
    setTestType("1");
    setTestMode("2");
    setSessionName("");
    setPdfReportName("");
    setSessionDesc("");
    setTestPattern("1");
    setCorrectAnswerMarks(1);
    setWrongAnswerMarks(0);
    setTestDate("");
    setTimeInMin("");
    // setTotalQuestions("");
    setYear("");
    setSubject("");
    setIsMultiSelect(false);
    setFillMarks(false);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h4>New Test Session</h4>
        </div>
      </div>

      <form>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="testtype">
                Select Test Session type:<span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="form-select"
                id="testtype"
                value={testType}
                onChange={(e) => setTestType(e.target.value)}
                required
                disabled
              >
                <option value="1">1. Standard Test</option>
                {/* <option value="2">2. Previous Year & Model Papers</option> */}
                {/* <option value="2">2. Hybrid Test</option> */}
              </select>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="testmode">
                Select Mode of Test:<span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="form-select"
                id="testmode"
                value={testMode}
                onChange={(e) => setTestMode(e.target.value)}
                required
                disabled
              >
                <option value="1">1. Online</option>
                <option value="2">2. Offline</option>
              </select>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="sessionname">
                Session Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="sessionname"
                placeholder="Enter session name"
                value={sessionName}
                onChange={(e) => setSessionName(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="filename">
                PDF Report Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="filename"
                placeholder="Enter PDF report name"
                value={pdfReportName}
                onChange={(e) => setPdfReportName(e.target.value)}
                required
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="sessiondesc">
                Session Description<span style={{ color: "red" }}>*</span>
              </label>
              <textarea
                className="form-control"
                id="sessiondesc"
                placeholder="Enter session description"
                rows={2}
                maxLength={300}
                value={sessionDesc}
                onChange={(e) => setSessionDesc(e.target.value)}
                required
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="testtype">
                Marks Pattern<span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="form-select"
                id="testpattern"
                value={testPattern}
                onChange={(e) => setTestPattern(e.target.value)}
                style={{ width: '100%' }}
                required
                disabled
              // disabled={testType === '1'}
              >
                <option value="1">1. Custom Create</option>
                <option value="2">2. Previous Year & Model Papers</option>
                <option value="3">3. Assignments/Home works</option>
              </select>
            </div>
          </div>
          {/* 
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="totalquestions">
                Total Questions<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="totalquestions"
                placeholder="Enter total questions"
                value={totalQuestions}
                onChange={(e) => setTotalQuestions(e.target.value)}
                required
                disabled
              />
            </div>
          </div> */}
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="totalquestions">
                Marks for correct answer<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="questioncorrentmarks"
                placeholder="1 or 2 or 4...."
                value={correctAnswerMarks}
                onChange={(e) => setCorrectAnswerMarks(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="testtype">
                Marks for Wrong answer<span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="form-select"
                id="wronganswermarks"
                value={wrongAnswerMarks}
                onChange={(e) => setWrongAnswerMarks(e.target.value)}
                required
              >
                <option value="0">No Negative Marking</option>
                <option value="-1">-1</option>
                <option value="-2">-2</option>
                <option value="-3">-3</option>
                <option value="-4">-4</option>
              </select>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="timeinmin">
                Time in Minutes<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="timeinmin"
                placeholder="Enter time in minutes"
                value={timeInMin}
                onChange={(e) => setTimeInMin(e.target.value)}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="testdate">
                Test Date<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="datetime-local"
                className="form-control"
                id="testdate"
                value={testDate}
                onChange={(e) => setTestDate(e.target.value)}
                required
              />
            </div>
          </div>
        </div>

        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="year">
                  Select Year:<span style={{ color: 'red' }}>*</span>
                </label>
                <select
                  className="form-select full-width-select"
                  id="year"
                  value={year}
                  onChange={handleYearChange}
                  style={{ width: '100%' }}
                  required
                >
                  <option value="">Select Year</option>
                  {years.map((year) => (
                    <option key={year.year_id} value={year.year_id}>
                      {year.year_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div>
            <Form.Check
              type="switch"
              label={isMultiSelect ? 'Multiple Subject Selection' : 'Single Subject Selection'}
              checked={isMultiSelect}
              onChange={toggleSelectionMode}
              className="link-style"
            />
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="subject">
                  Select Subject:<span style={{ color: 'red' }}>*</span>
                </label>
                <Select
                  options={subjectsOptions}
                  value={subject}
                  onChange={isMultiSelect ? handleMultiSubjectSelection : handleSingleSubjectSelection}
                  closeMenuOnSelect={!isMultiSelect} // Close the menu on select if it's not multi-select
                  components={animatedComponents}
                  isMulti={isMultiSelect}
                  isDisabled={!year}
                  style={{ width: '100%' }}
                />
              </div>
            </div>
          </div>
          {(subject.length > 0) && (
            <div className="col">
              <div>
                <Form.Check
                  type="switch"
                  label={fillMarks ? 'Same Marks' : 'Different Marks'}
                  checked={fillMarks}
                  onChange={toggleFillMarks}
                  className="link-style"
                  style={{ display: isMultiSelect ? 'block' : 'none' }}
                />

              </div>
              {subject.map(subject => (
                <div key={subject.value} className="col-md-12">
                  <div className="form-group">
                    <label htmlFor={`totalQuestions_${subject.value}`}>
                      Total Questions for {subject.label}:
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id={`totalQuestions_${subject.value}`}
                      value={subject.totalQuestions}
                      onChange={(e) => handleTotalQuestionsChange(subject.value, e.target.value)}
                      placeholder="Enter Total No. Questions"
                    />
                  </div>
                </div>
              ))}
            </div>
          )}

        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Slide}
      />
    </div>
  );
};

export default NewTest;
