import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Home from './Home/home';
// import Header from './Home/header';
import PrintPreviewQuestionModal from './PrintPreviewQuestionModal';
import './PrintPreview.css';

const PrintPreview = () => {
    const location = useLocation();
    const { objects, printQueue } = location.state || { objects: {}, printQueue: [] };
    const questions = useMemo(() => objects && objects.questions ? objects.questions : [], [objects]);

    const [selectedOption, setSelectedOption] = useState('question');
    const [showAnswer, setShowAnswer] = useState(false);
    const [displayLines, setDisplayLines] = useState(1);
    const [prefixes, setPrefixes] = useState(["(a)", "(b)", "(c)", "(d)"]);
    const [optionPrefix, setOptionPrefix] = useState("a");
    const [fontSize, setFontSize] = useState(12);
    const [displayColumns, setDisplayColumns] = useState(1);
    const [fontFamily, setFontFamily] = useState('Arial, sans-serif');
    const [questionSpace, setQuestionSpace] = useState('1');
    const [indexChange, setIndexChange] = useState(1);

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
        setShowAnswer(e.target.value === 'questionoptionWithanswer');
    };

    const handleDisplayLinesChange = (e) => {
        setDisplayLines(parseInt(e.target.value));
    };

    const handleOptionPrefixChange = (e) => {
        setOptionPrefix(e.target.value);
    };

    const handleFontSizeChange = (e) => {
        setFontSize(parseInt(e.target.value, 10));
    };

    const handleDisplayColumnsChange = (numColumns) => {
        setDisplayColumns(numColumns);
    };

    const handleFontChange = (e) => {
        setFontFamily(e.target.value);
    };

    const handleQuestionSpace = (e) => {
        setQuestionSpace(e.target.value);
    };

    const handleIndexChange = (e) => {
        const newValue = parseInt(e.target.value, 10);
        setIndexChange(isNaN(newValue) ? '' : newValue);
    };


    useEffect(() => {
        // Update prefixes based on optionPrefix
        switch (optionPrefix) {
            case "a":
                setPrefixes(["(a).", "(b).", "(c).", "(d)."]);
                break;
            case "A":
                setPrefixes(["(A).", "(B).", "(C).", "(D)."]);
                break;
            case "1":
                setPrefixes(["(1).", "(2).", "(3).", "(4)."]);
                break;
            case "i":
                setPrefixes(["(i).", "(ii).", "(iii).", "(iv)."]);
                break;
            default:
                break;
        }
    }, [optionPrefix]);

    const [printing, setPrinting] = useState(false);

    const handlePrint = () => {
        setPrinting(true);
        setTimeout(() => {
            window.print();
            setPrinting(false);
        }, 500); // Delay printing to allow rendering changes
    };



    return (
        <>
            <Home />
            <div className="print-preview-container">

                <div className="column-left hide-on-print">
                    <select value={selectedOption} onChange={handleOptionChange}>
                        <option value="question">Questions</option>
                        <option value="questionoptionWithanswer">Questions with Answer</option>
                        <option value="questionoptionsanswerexplanation">Question, Answer and Solution</option>
                        <option value="onlyanswerandexplanation">Only Answer and Solution</option>
                        <option value="onlyanswer">Only Answer</option>
                        <option value="onlysolution">Only Solution</option>
                        <option value="onlyquestion">Only Question, No Answer</option>
                    </select>

                    <select value={displayLines} onChange={handleDisplayLinesChange}>
                        <option value={1}>Display in 1 Line</option>
                        <option value={2}>Display in 2 Lines</option>
                        <option value={4}>Display in 4 Lines</option>
                    </select>

                    <select value={optionPrefix} onChange={handleOptionPrefixChange}>
                        <option value="a">Option Prefix: (a)</option>
                        <option value="A">Option Prefix: (A)</option>
                        <option value="1">Option Prefix: (1)</option>
                        <option value="i">Option Prefix: (i)</option>
                    </select>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <select value={fontFamily} onChange={handleFontChange}>
                            <option value="Arial, sans-serif">Arial</option>
                            <option value="Times New Roman, serif">Times New Roman</option>
                            <option value="Verdana, sans-serif">Verdana</option>
                            <option value="Courier New, monospace">Courier New</option>
                        </select>
                        <select value={fontSize} onChange={handleFontSizeChange}>
                            {[8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((size) => (
                                <option key={size} value={size}>{size}px</option>
                            ))}
                        </select>
                    </div>

                    <select value={displayColumns} onChange={(e) => handleDisplayColumnsChange(parseInt(e.target.value))}>
                        <option value={1}>Single Column</option>
                        <option value={2}>Two Columns</option>
                    </select>

                    <select value={questionSpace} onChange={handleQuestionSpace}>
                        <option value="1">Default</option>
                        <option value="5">5px</option>
                        <option value="10">10px</option>
                        <option value="15">15px</option>
                    </select>

                    <input type="text" value={indexChange} onChange={handleIndexChange} placeholder="Index Start" />

                    <button onClick={handlePrint}>Print</button>
                    {printing && <div className="printing-overlay">Printing...</div>}
                </div>

                <div className="column-right">
                    <div>
                        <div style={{ fontSize: `${fontSize}px`, fontFamily: fontFamily }}>
                            <div>
                                <h1 align="center">NAME OF THE COLLEGE</h1>
                                <p align="center"><b>TEST NAME</b></p>
                                <p align="center">Subject</p>
                                <div class="flex-container">
                                    <div><b>Time:</b></div>
                                    <div><b>Total Questions: {printQueue.length}</b></div>
                                    <div><b>Marks:{printQueue.length * 4}</b></div>
                                </div>
                                <div><hr /></div>
                            </div>
                            <div>
                                <PrintPreviewQuestionModal
                                    indexChange={indexChange}
                                    displayColumns={displayColumns}
                                    questionSpace={questionSpace}
                                    printQueue={printQueue}
                                    questions={questions}
                                    optionPrefix={optionPrefix}
                                    prefixes={prefixes}
                                    fontSize={fontSize}
                                    displayLines={displayLines}
                                    selectedOption={selectedOption}
                                    showAnswer={showAnswer}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrintPreview;
