import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AdminHeader from '../AdminHeader';
import Modal from './Modal';
import { Navigate } from 'react-router-dom';

const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;

const Reports = () => {
    const [reports, setReports] = useState([]);
    const [route, setRoute] = useState('');
    const [reportId, setRid] = useState(0);
    useEffect(() => {
        axios.get(`${baseUrl}/reports`)
            .then(response => {
                setReports(response.data);
            })
            .catch(error => console.error(error));
    }, []);

    const handleReportClick = (rid) => {
        setRid(rid)
        setRoute('ViewReport')
    };

    return (
        <div>
            {route === 'ViewReport' && <Navigate to={`/viewdetailedreport/${reportId}`} state={{ reportId }} />}
            <AdminHeader />
            {reports.length > 0 ? (
                <div className="d-flex flex-column align-items-center">
                    {reports.map((report, index) => (
                        <Modal index={index} report={report} onClick={handleReportClick} />
                    ))}
                </div>
            ) : (
                <p>No reports available.</p>
            )}

        </div>
    )
}

export default Reports
