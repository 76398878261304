import React, { useEffect, useState } from 'react'
import axios from 'axios';
import ReviewHearder from './ReviewHearder'
import { useParams } from 'react-router-dom';
import ReviewQuestionSelectionPage from './ReviewQuestionSelectionPage.';
import ViewModel from './ViewModel';

import ReportBtn from '../Buttons/ReportBtn';

import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './Review.css';

import { ToastContainer, toast, Slide } from 'react-toastify';

const baseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;

const ReviewExamQuestions = () => {
    const { user_id } = useParams();

    const [questions, setQuestions] = useState([]);

    const [selectedYearId, setSelectedYearId] = useState('');
    const [selectedSubjectId, setSelectedSubjectId] = useState('');
    const [selectedChapterId, setSelectedChapterId] = useState('');
    const [selectedTopicId, setSelectedTopicId] = useState('');
    // eslint-disable-next-line
    const [triggerToReload, setTriggerToRelod] = useState(false);

    const [exams, setExams] = useState([]);
    const [exam_id, setExamId] = useState([]);

    // Fetch exam names
    useEffect(() => {
        const fetchExams = async () => {
            try {
                const response = await axios.get(`${baseUrl}/exams`);
                setExams(response.data);
            } catch (error) {
                console.error('Error fetching exams:', error);
            }
        };
        fetchExams();
    }, []);

    const formatQuestions = (data) => {
        return data.map(item => ({
            questionId: item.id,

            preQuestionText: item.pre_question_text,
            postQuestionText: item.post_question_text.split('!'),

            option1Text: item.option1_text,
            option2Text: item.option2_text,
            option3Text: item.option3_text,
            option4Text: item.option4_text,

            correctOption: item.correct_option,

            preExplanationText: item.pre_explanation_text,
            postExplanationText: item.post_explanation_text,

            question_image_url: item.question_image_url,
            option1_image_url: item.option1_image_url,
            option2_image_url: item.option2_image_url,
            option3_image_url: item.option3_image_url,
            option4_image_url: item.option4_image_url,
            explanation_image_url: item.explanation_image_url,

            conceptualError: item.conceptual_error,
            alignmentError: item.alignment_error,
            deletedSyllabus: item.deleted_syllabus,
            difficultyLevel: item.difficulty_level,

            selectedExamId: item.selectedExamId

        }));
    };

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                // Create params object conditionally
                const params = {};
                if (selectedYearId) params.year_id = selectedYearId;
                if (selectedSubjectId) params.subject_id = selectedSubjectId;
                if (selectedChapterId) params.chapter_id = selectedChapterId;
                if (selectedTopicId) params.topic_id = selectedTopicId;

                const response = await axios.get(`${baseUrl}/questionsexamrelatedreview`, { params });

                const formattedQuestions = formatQuestions(response.data);
                setQuestions(formattedQuestions);
            } catch (err) {
                console.error('ERROR', err);
            }
        };
        fetchQuestions();
    }, [selectedYearId, selectedSubjectId, selectedChapterId, selectedTopicId, exam_id, triggerToReload]);

    useEffect(() => {
        setExamId(questions.map(question => question.selectedExamId));
    }, [questions]);

    useEffect(() => {
        const updateQuestionsInDatabase = async () => {
            try {
                const updatedQuestions = questions.map((question, index) => ({
                    ...question,
                    selectedExamId: exam_id[index]
                }));
                // Example of how you might send updatedQuestions to your backend
                // eslint-disable-next-line
                const response = await axios.post(`${baseUrl}/updateexamquestions`, { updatedQuestions });
                if (response.status === 201) {
                    toast.success('Questions updated successfully');
                    // setTriggerToRelod(prev => !prev);
                }
            } catch (error) {
                toast.error('Failed to update questions. Please try again later.');
            }
        };
        // console.log(exam_id[0]);
        if (exam_id?.[0] !== null && exam_id?.[0] !== undefined) {
            updateQuestionsInDatabase();
        }
    }, [exam_id, questions]); // Include all dependencies here

    const handleExamId = (index, value) => {
        const updatedExamIds = [...exam_id];
        updatedExamIds[index] = value;
        setExamId(updatedExamIds);
    }

    return (
        <div>
            <div>
                <ReviewHearder />
                    <ReviewQuestionSelectionPage
                        setSelectedYearId={setSelectedYearId}
                        setSelectedSubjectId={setSelectedSubjectId}
                        setSelectedChapterId={setSelectedChapterId}
                        setSelectedTopicId={setSelectedTopicId}
                    />
                {questions.map((question, index) => (
                    <div key={question.questionId}>
                            <div>
                                                <div >
                                                    <div>
                                                        <div>
                                                            <ReportBtn question={question} user_id={user_id} />
                                                            <div className='mb'>
                                                                <div className='subHeading'>Select Exam:</div>
                                                                <select className='questionselect' id="userSelect" value={exam_id?.[0] || ''} onChange={(e) => handleExamId(index, e.target.value)}>
                                                                    <option value=''>Select a Exam</option>
                                                                    {exams.map(user => (
                                                                        <option key={user.exam_id} value={user.exam_id}>
                                                                            {user.exam_name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <div >If you Don't know the Exam Select<b> None </b> in Option</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        <ViewModel question={question} index={index} />
                            </div>
                    </div>
                ))}
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </div>
    )
}

export default ReviewExamQuestions
