import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import LecturerHome from '../LecturerHome';

const LecturerTestEditHome = () => {
    const { user_id, lecture_id, test_id } = useParams(); // Destructure the params

    const navigate = useNavigate();
    // eslint-disable-next-line
    const [userid, setUserId] = useState(0);
  
    useEffect(() => {
      // Check if access token is present in local storage
      const accessToken = localStorage.getItem("accessToken");
      const userId = localStorage.getItem("userId");
      // console.log("User ID in user view dashboard", userId);
      setUserId(userId);
  
      if (!userId || !accessToken) {
        // Redirect to login page if userId or accessToken is missing
        navigate("/login");
      }
    }, [navigate]);
  
    const [navigatePages, setNavigatePages] = useState([]);
    const [navigates, setNavigates] = useState('');
    const [routeTo, setRouteTo] = useState('');
  
    useEffect(() => {
      setNavigatePages(['Add Questions', 'Review Questions']);
    }, []);
  
    useEffect(() => {
      if (navigates === 'Add Questions') {
        setRouteTo("Add Questions")
      }
      if (navigates === 'Review Questions') {
        setRouteTo("Review Questions")
      }
    }, [navigates, navigate, user_id]);
  return (
    <div className="d-flex flex-column align-items-center">
    {routeTo === "Add Questions" && (
      <Navigate to={`/lectureraddtestquestions/${user_id}/${lecture_id}/${test_id}`} state={{ user_id, lecture_id, userid }} />
    )}
    {routeTo === "Review Questions" && (
      <Navigate to={`/lecturereviewtestquestions/${user_id}/${lecture_id}/${test_id}`} state={{ user_id, lecture_id, userid }} />
    )}
    <LecturerHome />
    <Tabs
      defaultActiveKey=''
      id="fill-tab-example"
      className="mb-3"
      onSelect={(k) => setNavigates(k)}
      fill
    >
      {navigatePages.map((page, index) => (
        <Tab
          key={index}
          eventKey={page}
          title={page}
        />
      ))}
    </Tabs>
  </div>
  )
}

export default LecturerTestEditHome
